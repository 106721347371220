/**
 * @file stringformatter.tsx
 * @description This file contains the logic to format a string to title case.
 * @description It also contains the logic to ignore certain words from being formatted to title case.
 */
const ignoreWords = ['RFID', 'AMR']

export function toTitleCase(str: string | undefined) {
    if (!str) return ''
    if (ignoreWords.includes(str)) return str
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
    })
}
