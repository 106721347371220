import ApiMixin from '../ApiMixin'
import { ID } from 'buddy-api/types'

export type NetSuiteObject = {
    name: string
    value: number
}

export type INsOppFormMapping = {
    pk: ID
    data_type: string
    data: NetSuiteObject[]
}
export default class CallStatusAPI extends ApiMixin<INsOppFormMapping> {}
