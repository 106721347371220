/**
 * @file RecommendationReason.tsx
 * @description This file contains the logic to render the recommended reason.
 */
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Collapse,
    IconButton,
} from '@mui/material'
import React, { useState } from 'react'
import { RecommendedReasonsProps } from 'types/CallSessionPage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useCallSession } from 'context/CallSessionProvider'

export const RecommendationReason: React.FC<RecommendedReasonsProps> = ({
    reason,
}) => {
    const [expanded, setExpanded] = useState<boolean>(true)
    const { loading } = useCallSession()

    return (
        <Card
            elevation={0}
            sx={{
                maxHeight: '352px',
                height: '100%',
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            }}
        >
            <CardHeader
                titleTypographyProps={{
                    sx: {
                        fontFamily: 'Inter',
                        color: '#000021',
                        fontWeight: 500,
                        fontSize: 16,
                    },
                }}
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'background.paper',
                }}
                title="Recommended Reason"
                action={
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon
                            sx={{
                                transform: expanded
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)',
                            }}
                        />
                    </IconButton>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent
                    sx={{
                        paddingTop: 0,
                    }}
                >
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '300px',
                            }}
                        >
                            <CircularProgress size={25} />
                        </Box>
                    ) : (
                        <List disablePadding={true}>
                            {reason?.split('<BREAK>').map(
                                (item, index) =>
                                    item.length > 0 && (
                                        <ListItem
                                            key={'RR' + index}
                                            disablePadding={true}
                                            sx={{
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 'auto',
                                                    marginRight: '8px',
                                                    marginTop: '8px',
                                                }}
                                            >
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        fontSize: '10px',
                                                        color: 'rgba(0, 0, 33, 1)',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item}
                                                primaryTypographyProps={{
                                                    component: 'span',
                                                    fontWeight: 400,
                                                    fontFamily: 'Inter',
                                                    fontSize: 13,
                                                }}
                                            />
                                        </ListItem>
                                    )
                            )}
                        </List>
                    )}
                </CardContent>
            </Collapse>
        </Card>
    )
}
