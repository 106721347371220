import ApiRequest from 'buddy-api/ApiRequest'
import ApiMixin from '../ApiMixin'
import { ID } from 'buddy-api/types'

type PreferredVendorsApiProps = {
    account_id: number
    item_id: number
    item_manufacturer: string
    count: number
    item__name: string
}

type getPreferredVendorsParams = {
    account_id: ID | undefined
    item_id?: ID
    item_ids: string
}

export default class PreferredVendorsAPI extends ApiMixin<
    PreferredVendorsApiProps[]
> {
    public getPreferredVendors = async (
        { account_id, item_ids }: getPreferredVendorsParams,
        signal?: AbortSignal | null
    ) => {
        const url = '/transaction-history/vendors'
        const params = { account_id, item_ids }
        const result = await ApiRequest.get<PreferredVendorsApiProps[]>(
            url,
            signal,
            params
        )
        return result.body
    }
}
