/**
 * @file AuthenticatedRoute.tsx
 * @description This file contains the logic to redirect the user to the login page if the user is not authenticated.
 */
import { useAuthenticatedUser } from 'context/UserProvider'
import React from 'react'
import { Navigate, useLocation } from 'react-router'
import { loginPageURL } from 'routes/routes'

const AuthenticatedRoute: React.VFC<{
    children: React.ReactElement<any, any>
}> = ({ children }) => {
    const location = useLocation()
    const { user } = useAuthenticatedUser()

    if (user === undefined) {
        // user fetch API is still loading user
        return null
    }

    if (!user) {
        // removeCookie()
        let url = ''
        if (location.pathname.indexOf('logout') === -1) {
            url = `${loginPageURL()}?next=${location.pathname}`
        } else {
            url = `${loginPageURL()}`
        }
        return Navigate({ to: url })
    }

    return children
}

export default AuthenticatedRoute
