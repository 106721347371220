/**
 * @file CallSessionDatePicker.tsx
 * @description This file contains the logic to render the date picker for the call session page.
 */
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import 'react-datepicker/dist/react-datepicker.css'
import { DatePickerProps } from 'types/CallSessionPage'
import { ClickAwayListener, Popper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { AccountStatus, useCallSession } from 'context/CallSessionProvider'
import { DateCalendar } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'

export const CallSessionDatePicker: React.FC<DatePickerProps> = ({ sx }) => {
    const {
        account,
        followUpDate,
        setFollowUpDate,
        setFollowUpDateCallback,
        activeContact,
    } = useCallSession()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const id = open ? 'date-picker-popper' : undefined

    const handleClickAway = () => {
        setFollowUpDate(null)
        setAnchorEl(null)
    }

    return (
        <>
            <Button
                disabled={
                    (activeContact?.status == AccountStatus.OPPORTUNITY &&
                        account?.status == AccountStatus.OPPORTUNITY) ||
                    (activeContact?.status == AccountStatus.REJECTED &&
                        account?.status == AccountStatus.REJECTED)
                }
                startIcon={<AccessAlarmIcon fontSize="small" />}
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    color: '#774DFC',
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: 12,
                    ...sx,
                }}
                aria-describedby={id}
                onClick={(event) =>
                    setAnchorEl(anchorEl ? null : event.currentTarget)
                }
            >
                Set Follow Up
            </Button>
            <Popper
                sx={{
                    zIndex: 1500,
                    fontFamily: 'Inter !important',
                    padding: 1,
                    paddingTop: 3,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #e3e3e3',
                    borderRadius: '4px',
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom"
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                        <Typography
                            fontFamily={'Inter'}
                            fontSize={14}
                            fontWeight={500}
                            textAlign={'center'}
                        >
                            Select a Date for Follow Up
                        </Typography>{' '}
                        <DateCalendar
                            sx={{
                                '.MuiCalendarPicker-root': {
                                    padding: '8px',
                                    paddingBottom: '0px',
                                },
                            }}
                            value={followUpDate}
                            onChange={(newValue) => setFollowUpDate(newValue)}
                            minDate={DateTime.now()}
                        />
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                        >
                            <Button
                                sx={{
                                    borderRadius: '100px',
                                    '&:hover': {
                                        bgcolor: 'rgba(119,77,252,0.2)',
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#774DFC',
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: 12,
                                    ...sx,
                                }}
                                aria-describedby={id}
                                onClick={(event) => {
                                    setFollowUpDate(null)
                                    setAnchorEl(
                                        anchorEl ? null : event.currentTarget
                                    )
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{
                                    borderRadius: '100px',
                                    border: `1px solid #774DFC`,
                                    '&:hover': {
                                        border: `1px solid #774DFC`,
                                        bgcolor: 'transparent',
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: `#774DFC`,
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: 12,
                                }}
                                aria-describedby={id}
                                onClick={(event) => {
                                    setFollowUpDateCallback()
                                    setAnchorEl(
                                        anchorEl ? null : event.currentTarget
                                    )
                                }}
                            >
                                Set Follow Up
                            </Button>
                        </Stack>
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
