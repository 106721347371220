/**
 * @file TalkTrackSectionRadioGroup.tsx
 * @description This file contains the logic to display the radio group in the talk track section of the call session page.
 */
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Stack, SxProps } from '@mui/system'
import React, { useEffect } from 'react'
import { Option } from 'types/CallSessionPage'

type TalkTrackSectionRadioGroupProps = {
    sx?: SxProps
    radioButtons: Option[]
    setselectedRadioButton: (value: string) => void
    selectedRadioButton: string
}

export const TalkTrackSectionRadioGroup: React.FC<
    TalkTrackSectionRadioGroupProps
> = ({ sx, radioButtons, selectedRadioButton, setselectedRadioButton }) => {
    const handleClick = (e: any, item: Option) => {
        setselectedRadioButton(item.name)
    }
    useEffect(() => {
        if (radioButtons.length > 0) {
            setselectedRadioButton(radioButtons[1].name)
        }
    }, [radioButtons])
    return (
        <Stack
            direction="row"
            spacing={1}
            gap={1}
            padding={2}
            minWidth={'max-content'}
            maxWidth={'250px'}
            width={'100%'}
        >
            <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedRadioButton}
            >
                {radioButtons &&
                    radioButtons?.map((button: Option, index: any) => {
                        return (
                            <FormControlLabel
                                key={'TTRB' + index}
                                value={button.name}
                                control={
                                    <Radio
                                        id={`${button.subtitle}::${button.name}`}
                                        onClick={(e) => {
                                            handleClick(e, button)
                                        }}
                                        sx={{
                                            color: '#00002199',
                                            '&.Mui-checked': {
                                                color: '#774DFC',
                                                fontFamily: 'Inter',
                                                fontWeight: 700,
                                                fontSize: 23,
                                            },
                                        }}
                                    />
                                }
                                label={button.name}
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontFamily: 'Inter',
                                        fontWeight:
                                            selectedRadioButton == button.name
                                                ? 700
                                                : 400,
                                        fontSize: 13,
                                    },
                                }}
                            />
                        )
                    })}
            </RadioGroup>
        </Stack>
    )
}
