/**
 * @file CustomerSessionPanel.tsx
 * @description This file contains the implementation of the CustomerSessionPanel component.
 * @description It provides a panel for the customer session.
 */

import { SxProps } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { CallSessionSection1 } from 'components/callsession/CallSessionSection1'
import { CallSessionSection2 } from 'components/callsession/CallSessionSection2'
import { CallSessionSection3 } from 'components/callsession/CallSessionSection3'
import { AccountStatus, useCallSession } from 'context/CallSessionProvider'

type CustomerSessionPanelProps = {
    sx?: SxProps
}

/**
 * Represents the CustomerSessionPanel component.
 * This component displays a panel for the customer session.
 *
 * @component
 * @example
 * return (
 *   <CustomerSessionPanel sx={styles} />
 * )
 */
export const CustomerSessionPanel: React.FC<CustomerSessionPanelProps> = ({
    sx,
}) => {
    const { account, activeContact } = useCallSession()
    return (
        <Stack
            spacing={1}
            direction={'column'}
            width={'100%'}
            minWidth={'976px'}
            sx={{
                '.required-star': {
                    color: 'red',
                },
            }}
        >
            {account &&
                account?.status !== AccountStatus.REJECTED &&
                (account?.status !== AccountStatus.OPPORTUNITY ||
                    activeContact?.status !== AccountStatus.OPPORTUNITY) && (
                    <CallSessionSection1></CallSessionSection1>
                )}
            <CallSessionSection2></CallSessionSection2>
            <CallSessionSection3></CallSessionSection3>
        </Stack>
    )
}
