/**
 * @file TalkTrackDataRadio.tsx
 * @description This file contains the logic to render the radio buttons for the talk track data.
 */
import React, { useState, useEffect } from 'react'
import { Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { RenderTalkTrackComponent } from './RenderTalkTrackComponent'

type OptionValue = {
    title: string
    value: string[] | { [key: string]: string }[]
}

type TalkTrackDataRadioProps = {
    sx?: SxProps
    data: OptionValue[]
}

export const TalkTrackDataRadio: React.FC<TalkTrackDataRadioProps> = ({
    data,
}) => {
    const [selectedSecondRadio, setSelectedSecondRadio] = useState<string>('')

    useEffect(() => {
        if (data.length > 0) {
            setSelectedSecondRadio(data[0].title)
        }
    }, [data])

    return (
        <RadioGroup
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={selectedSecondRadio}
        >
            {data.map((button, index) => {
                const isSelected = selectedSecondRadio === button.title
                return (
                    <React.Fragment key={'TTRB' + index}>
                        <FormControlLabel
                            value={button.title}
                            control={
                                <Radio
                                    id={`${index}::${button.title}`}
                                    onClick={() =>
                                        setSelectedSecondRadio(button.title)
                                    }
                                    sx={{
                                        color: '#00002199',
                                        '&.Mui-checked': {
                                            color: '#774DFC',
                                        },
                                    }}
                                />
                            }
                            label={button.title}
                            sx={{
                                paddingY: 0.5,
                                '& .MuiFormControlLabel-label': {
                                    fontFamily: 'Inter',
                                    fontWeight: isSelected ? 700 : 400,
                                    fontSize: 13,
                                },
                            }}
                        />
                        {isSelected &&
                            button.value.map((item, idx) => {
                                if (
                                    typeof item === 'object' &&
                                    'type' in item
                                ) {
                                    return (
                                        <RenderTalkTrackComponent
                                            type={item.type}
                                            data={item}
                                        />
                                    )
                                } else if (typeof item === 'string') {
                                    const lines = item.split('\n')
                                    return lines.map((line, lineIdx) => (
                                        <Typography
                                            key={'detail' + idx + '-' + lineIdx}
                                            fontFamily={'Inter'}
                                            fontSize={13}
                                            fontWeight={400}
                                            sx={{
                                                display: 'block',
                                                textIndent: line.startsWith(
                                                    '\u2022'
                                                )
                                                    ? '-1em'
                                                    : '0',
                                                paddingLeft: line.startsWith(
                                                    '\u2022'
                                                )
                                                    ? '2em'
                                                    : '0',
                                                marginTop: '2px',
                                                marginBottom: '2px',
                                            }}
                                        >
                                            {line}
                                        </Typography>
                                    ))
                                } else {
                                    return null
                                }
                            })}
                    </React.Fragment>
                )
            })}
        </RadioGroup>
    )
}
