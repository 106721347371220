/**
 * @file TalkTrackSection.tsx
 * @description This file contains the logic to display the talk track section of the call session page.
 */
import {
    Box,
    CircularProgress,
    Divider,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    SxProps,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { TalkTracks, Option } from 'types/CallSessionPage'
import { useCallSession } from 'context/CallSessionProvider'
import { TalkTrackButtonSection } from './TalkTrackButtonSection'
import { TalkTrackSectionRadioGroup } from './TalkTrackSectionRadioGroup'
import { TalkTrackData } from './TalkTrackData'
import InfoIcon from '@mui/icons-material/Info'
import { toTitleCase } from 'utils/stringformatter'

type TalkTrackSectionProps = {
    sx?: SxProps
}

const transformValueToArrayOfStrings = (hooks: any) => {
    return {
        ...hooks,
        value: hooks.value.map((item: any) => ({
            ...item,
            value: [item.value],
        })),
    }
}

export const TalkTrackSection: React.FC<TalkTrackSectionProps> = () => {
    const {
        callSessionV2,
        account,
        reportingCategories,
        fetchTalkTrack,
        loading,
    } = useCallSession()
    const [talkTrackData, setTalkTrackData] = useState<TalkTracks | undefined>(
        undefined
    )
    const [talkTrackLoading, setTalkTrackLoading] = useState<boolean>(false)
    const [leadName, setLeadName] = useState<string | undefined>('')
    const [campaignType, setCampaignType] = useState<string>('')
    const [selectedButton, setSelectedButton] = useState<string>('')
    const [selectedRadioButton, setselectedRadioButton] = useState<string>('')

    const [reportingCategory, setReportingCategory] = React.useState('')

    const handleChange = (event: SelectChangeEvent) => {
        setReportingCategory(event.target.value)
    }
    useEffect(() => {
        const reportingCategory =
            account?.filtered_leads[0]?.item?.item_id.toString() ?? ''
        const campaign = account?.filtered_leads[0]?.campaign_type ?? ''
        setReportingCategory(reportingCategory)
        setCampaignType(campaign)
    }, [account])

    useEffect(() => {
        if (reportingCategory.length <= 0) return
        setSelectedButton('')
        setselectedRadioButton('')
        setTalkTrackLoading(true)
        fetchTalkTrack(reportingCategory)
            .then(async (res) => {
                if (res) {
                    res.talk_tracks.tracks['inbound'] =
                        res.talk_tracks.tracks['retention']
                    res.talk_tracks.tracks['acquisition'] =
                        res.talk_tracks.tracks['acquisition']
                    setLeadName(res.name)
                    let hooks = res.hooks.data.facility.find((item) =>
                        item.facility_type
                            ?.toLowerCase()
                            .includes(
                                account?.building_type?.toLowerCase() ?? ''
                            )
                    )
                    hooks = transformValueToArrayOfStrings(hooks)
                    if (hooks) {
                        const campaignType =
                            account?.filtered_leads[0].campaign_type.toLowerCase() ??
                            ''
                        const requiredObject = res.talk_tracks.tracks[
                            campaignType
                        ].find(
                            (item) =>
                                item.name.toLowerCase() ===
                                'discover'.toLowerCase()
                        )
                        if (requiredObject) {
                            requiredObject.options.unshift(hooks)
                            res.talk_tracks.tracks[campaignType][1] =
                                requiredObject
                            setTalkTrackData(res.talk_tracks ?? undefined)
                        } else {
                            setTalkTrackData(res.talk_tracks ?? undefined)
                        }
                    } else {
                        setTalkTrackData(res.talk_tracks ?? undefined)
                    }
                    setTalkTrackLoading(false)
                }
            })
            .catch((err) => {
                setTalkTrackData(undefined)
                setTalkTrackLoading(false)
            })
    }, [reportingCategory, account])

    useEffect(() => {
        const obj = account?.filtered_leads.find(
            (item) => item.lead_id == callSessionV2.lead
        )
        setLeadName(obj?.item?.name)
        setReportingCategory(obj?.item?.item_id.toString() ?? '')
    }, [callSessionV2.lead])

    return (
        <>
            <Stack
                direction={'column'}
                minWidth={'673px'}
                flexGrow={1}
                sx={{
                    background:
                        'linear-gradient(0deg, rgba(24, 152, 255, 0.05) 0%, rgba(120, 77, 255, 0.05) 69.91%)',
                    padding: 2,
                    paddingY: 1,
                }}
            >
                <Stack
                    direction={'row'}
                    spacing={1}
                    paddingY={1}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Typography
                            fontSize={16}
                            fontWeight={500}
                            fontFamily={'Inter'}
                        >
                            Talk Track
                        </Typography>
                        <Divider orientation="vertical" flexItem></Divider>
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            fontFamily={'Inter'}
                            sx={{
                                background:
                                    'linear-gradient(0deg, #1898FF 0%, #784DFF 69.91%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                display: 'inline',
                            }}
                        >
                            {leadName ? toTitleCase(leadName) : 'N/A'}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Typography
                            fontSize={12}
                            fontWeight={500}
                            fontFamily={'Inter'}
                            color={'rgba(114, 116, 146, 1)'}
                            display={'flex'}
                            alignItems={'center'}
                            gap={1}
                        >
                            Reporting Category
                            <InfoIcon fontSize="small"></InfoIcon>
                        </Typography>
                        <FormControl sx={{ minWidth: 180 }} size="small">
                            <Select
                                id="demo-select-small"
                                value={reportingCategory}
                                onChange={handleChange}
                                sx={{
                                    '&:hover .MuiOutlinedInput-notchedOutline':
                                        {
                                            borderColor:
                                                'rgba(119, 77, 252, 1)',
                                        },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                        {
                                            borderColor:
                                                'rgba(119, 77, 252, 1)',
                                        },

                                    '& .Mui-selected': {
                                        background:
                                            'rgba(119, 77, 252, 0.2) !important',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(119, 77, 252, 1)',
                                    },
                                }}
                            >
                                {reportingCategories.map((item, index) => {
                                    return (
                                        <MenuItem
                                            key={'RC' + index}
                                            value={item.item_id}
                                        >
                                            {toTitleCase(item.name)}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
                {loading || talkTrackLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress size={25} />
                    </Box>
                ) : talkTrackData ? (
                    <>
                        <TalkTrackButtonSection
                            selectedButton={selectedButton ?? ''}
                            setSelectedButton={setSelectedButton}
                            button={
                                talkTrackData?.tracks[
                                    campaignType?.toLowerCase()
                                ] ?? []
                            }
                        ></TalkTrackButtonSection>
                        <Stack
                            direction={'row'}
                            bgcolor={'rgba(255, 255, 255, 1)'}
                            height={'100%'}
                            padding={2}
                        >
                            <TalkTrackSectionRadioGroup
                                selectedRadioButton={selectedRadioButton ?? ''}
                                setselectedRadioButton={setselectedRadioButton}
                                radioButtons={
                                    talkTrackData?.tracks[
                                        campaignType?.toLowerCase()
                                    ]?.find(
                                        (item) => item.name == selectedButton
                                    )?.options ?? []
                                }
                            ></TalkTrackSectionRadioGroup>
                            <TalkTrackData
                                selectedRadioButton={selectedRadioButton ?? ''}
                                data={
                                    talkTrackData?.tracks[
                                        campaignType?.toLowerCase()
                                    ]?.find(
                                        (item) => item.name == selectedButton
                                    )?.options ?? []
                                }
                            ></TalkTrackData>
                        </Stack>
                    </>
                ) : (
                    <Typography
                        fontFamily="Inter"
                        fontSize="15px"
                        fontWeight="500"
                        textAlign="center"
                    >
                        Talk Track Not Found
                    </Typography>
                )}
            </Stack>
        </>
    )
}
