/**
 * @file RootRoute.tsx
 * @description This file contains the root route of the application.
 * @description The root route is the first route that is rendered when the application is loaded.
 */

import { useAuthenticatedUser } from 'context/UserProvider'
import { isNull, isUndefined } from 'lodash'
import React from 'react'
import { Navigate } from 'react-router'
import { loginPageURL, proactivesPageURL } from 'routes/routes'

const RootRoute: React.FC = () => {
    const { user } = useAuthenticatedUser()
    if (isUndefined(user)) {
        return null
    } else if (isNull(user)) {
        return Navigate({ to: loginPageURL() })
    }
    return Navigate({ to: proactivesPageURL() })
}

export default RootRoute
