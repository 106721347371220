import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

type ProactivesIconProps = SvgIconProps & {
    active?: boolean
}

const ProactivesIcon: React.FC<ProactivesIconProps> = ({
    active = false,
    ...rest
}) => {
    const fill_color = '#FFFFFF'
    return (
        <SvgIcon viewBox="0 0 18 19" fill="#FFFFFF" {...rest}>
            <path
                d="M9 10.6875H6.6C4.9425 10.6875 3.6 12.016 3.6 13.6562C3.6 13.9828 3.87 14.25 4.2 14.25H11.4C11.7314 14.25 12 13.9841 12 13.6562C12 12.016 10.6575 10.6875 9 10.6875ZM7.8 9.5C9.12563 9.5 10.2 8.43682 10.2 7.125C10.2 5.81318 9.12563 4.75 7.8 4.75C6.47475 4.75 5.4 5.81318 5.4 7.125C5.4 8.43682 6.47625 9.5 7.8 9.5ZM17.4 11.875H16.8V15.4375H17.4C17.7313 15.4375 18 15.1716 18 14.8438V12.4688C18 12.1422 17.73 11.875 17.4 11.875ZM17.4 2.375H16.8V5.9375H17.4C17.73 5.9375 18 5.67031 18 5.34375V2.96875C18 2.6407 17.73 2.375 17.4 2.375ZM17.4 7.125H16.8V10.6875H17.4C17.73 10.6875 18 10.4203 18 10.0938V7.71875C18 7.39219 17.73 7.125 17.4 7.125ZM13.2 0H2.4C1.07438 0 0 1.06318 0 2.375V16.625C0 17.9368 1.07438 19 2.4 19H13.2C14.5256 19 15.6 17.9368 15.6 16.625V2.375C15.6 1.06318 14.5238 0 13.2 0ZM13.8 16.625C13.8 16.9529 13.5314 17.2188 13.2 17.2188H2.4C2.06865 17.2188 1.8 16.9529 1.8 16.625V2.375C1.8 2.04703 2.06865 1.78125 2.4 1.78125H13.2C13.5314 1.78125 13.8 2.04703 13.8 2.375V16.625Z"
                fill={fill_color}
            />
        </SvgIcon>
    )
}

export default ProactivesIcon
