/**
 * @file ActionSection.tsx
 * @description This file contains the logic to display the action section of the call session page.
 */

import { Button, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect } from 'react'
import { ActionSectionProps } from 'types/CallSessionPage'
import phoneCall from '../../assets/images/phoneCall.svg'
import subwayCall from '../../assets/images/subwayCall.svg'
import reject from '../../assets/images/reject.svg'
import clock from '../../assets/images/clock-tabler.svg'
import { useCallSession } from 'context/CallSessionProvider'
import CloseIcon from '@mui/icons-material/Close'
import { ICallStatus } from 'buddy-api/types/callstatus'
const callStatusActionButtons: Record<string, string> = {
    'Seller Rejected': subwayCall,
    'Contact Attempt': clock,
    'Contact Successful': phoneCall,
    'Reject Account': reject,
}

export const ActionSection: React.FC<ActionSectionProps> = () => {
    const { callStatus, callSessionV2, updateSelections } = useCallSession()
    const [reorderedCallStatus, setReorderedCallStatus] = React.useState<
        ICallStatus[]
    >([])
    const handleClick = (e: any, button: ICallStatus) => {
        if (callSessionV2.action?.id == button.id) {
            updateSelections({
                action: {
                    $set: {
                        id: '',
                        name: '',
                    },
                },
                sessionOutcome: {
                    $set: null,
                },
                reason: {
                    $set: null,
                },
            })
            return
        }
        updateSelections({
            action: {
                $set: {
                    id: button.id,
                    name: button.name,
                },
            },
            sessionOutcome: {
                $set: null,
            },
            reason: {
                $set: null,
            },
        })
    }

    useEffect(() => {
        if (callStatus) {
            const reOrdered = [
                callStatus.find((button) => button.name === 'Contact Attempt'),
                callStatus.find(
                    (button) => button.name === 'Contact Successful'
                ),
                callStatus.find((button) => button.name === 'Seller Rejected'),
                callStatus.find((button) => button.name === 'Reject Account'),
            ].filter((button): button is ICallStatus => Boolean(button))
            setReorderedCallStatus(reOrdered)
        }
    }, [callStatus])

    return (
        <Stack direction="row" alignItems="center" spacing={1} gap={1}>
            <Typography fontFamily="Inter" fontSize={15} fontWeight={700}>
                ACTION:<span className="required-star">*</span>
            </Typography>
            <Stack direction={'row'} gap={1} flexWrap={'wrap'}>
                {reorderedCallStatus.map((button, index) => {
                    return (
                        <Button
                            onClick={(e) => {
                                handleClick(e, button)
                            }}
                            id={`${button.id}::${button.name}`}
                            key={'ACK' + index}
                            variant="outlined"
                            startIcon={
                                button.name in callStatusActionButtons && (
                                    <img
                                        src={
                                            callStatusActionButtons[button.name]
                                        }
                                        alt={button.name}
                                    />
                                )
                            }
                            endIcon={
                                callSessionV2.action?.id == button.id ? (
                                    <CloseIcon fontSize="small"></CloseIcon>
                                ) : (
                                    <></>
                                )
                            }
                            sx={{
                                background:
                                    callSessionV2.action?.id == button.id
                                        ? button.name != 'Reject Account'
                                            ? 'rgba(119, 77, 252, 0.2)'
                                            : 'rgba(252, 77, 77, 0.04)'
                                        : '',
                                borderRadius: '100px',
                                border:
                                    button.name != 'Reject Account'
                                        ? `1px solid #774DFC`
                                        : '1px solid #FF0000',
                                '&:hover': {
                                    border:
                                        button.name != 'Reject Account'
                                            ? `1px solid #774DFC`
                                            : '1px solid #FF0000',
                                    bgcolor: 'transparent',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                color:
                                    button.name != 'Reject Account'
                                        ? `#774DFC`
                                        : '#DB0000',
                                fontFamily: 'Inter',
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                        >
                            {button.name}
                        </Button>
                    )
                })}
            </Stack>
        </Stack>
    )
}
