import { ID } from 'buddy-api/types'
import ApiMixin from '../ApiMixin'
import ApiRequest from 'buddy-api/ApiRequest'

export type FollowUpDateApiProps = {
    leadID: ID
    followUpDate: {
        follow_up_date: string | null
    }
}

export default class FollowUpDateAPI extends ApiMixin<FollowUpDateApiProps> {
    public async UpdateDate(
        id: ID,
        followUpDate: FollowUpDateApiProps['followUpDate'],
        signal?: AbortSignal | null
    ) {
        const url = `${this.endpoint}/${id}/follow_up_date`
        const result = await ApiRequest.patch(url, signal, followUpDate)
        return result.response.status
    }
}
