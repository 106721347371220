/**
 * @file TalkTrackData.tsx
 * @description This file contains the logic to render the talk track data.
 */
import { Typography } from '@mui/material'
import { Stack, SxProps } from '@mui/system'
import React from 'react'
import { CategoryQuestion, OptionValue } from 'types/CallSessionPage'
import { Option } from 'types/CallSessionPage'
import { TalkTrackDataList } from './TalkTrackDataList'
import { TalkTrackDataRadio } from './TalkTrackDataRadio'
import { TalkTrackDataTable } from './TalkTrackDataTable'

type TalkTrackDataProps = {
    sx?: SxProps
    data: Option[]
    selectedRadioButton: string
}

export const TalkTrackData: React.FC<TalkTrackDataProps> = ({
    sx,
    data,
    selectedRadioButton,
}) => {
    return (
        <Stack
            direction="column"
            padding={2}
            spacing={1}
            width={'100%'}
            border={'1px solid rgba(119, 77, 252, 0.4)'}
            sx={{
                boxShadow: 1,
            }}
        >
            {/* Show short summary if available */}
            {data.find((item) => item.name == selectedRadioButton)
                ?.short_summary?.length ?? 0 > 0 ? (
                <>
                    <Typography
                        fontSize={13}
                        fontWeight={700}
                        fontFamily={'Inter'}
                        color={'rgba(0, 0, 33, 0.6)'}
                    >
                        Short Summary
                    </Typography>
                    <TalkTrackDataList
                        data={
                            data.find(
                                (item) => item.name == selectedRadioButton
                            )?.short_summary ?? []
                        }
                    ></TalkTrackDataList>
                    <Typography
                        fontSize={13}
                        fontWeight={700}
                        fontFamily={'Inter'}
                        color={'rgba(0, 0, 33, 0.6)'}
                    >
                        {
                            data.find(
                                (item) => item.name == selectedRadioButton
                            )?.title
                        }
                    </Typography>
                </>
            ) : (
                <Typography
                    fontSize={13}
                    fontWeight={700}
                    fontFamily={'Inter'}
                    color={'rgba(0, 0, 33, 0.6)'}
                >
                    {
                        data.find((item) => item.name == selectedRadioButton)
                            ?.title
                    }
                </Typography>
            )}

            {(() => {
                const subtitle = data.find(
                    (item) => item.name === selectedRadioButton
                )?.subtitle
                if (!subtitle) {
                    return null
                }

                const subtitleLines = subtitle.split('\n')
                return subtitleLines.map((line, index) => (
                    <Typography
                        key={'subtitle' + index}
                        fontSize={13}
                        fontWeight={400}
                        fontFamily="Inter"
                        color="rgba(0, 0, 33, 1)"
                        sx={{
                            display: 'block',
                            textIndent: line.startsWith('\u2022')
                                ? '-0.5em'
                                : '0',
                            paddingLeft: line.startsWith('\u2022')
                                ? '2em'
                                : '0',
                            marginTop: '2px',
                            marginBottom: '2px',
                        }}
                    >
                        {line}
                    </Typography>
                ))
            })()}

            {data.find((item) => item.name == selectedRadioButton)?.type ==
                'list' && (
                <TalkTrackDataList
                    data={
                        (data.find((item) => item.name == selectedRadioButton)
                            ?.value as string[]) ?? []
                    }
                ></TalkTrackDataList>
            )}
            {data.find((item) => item.name == selectedRadioButton)?.type ==
                'radio' && (
                <TalkTrackDataRadio
                    data={
                        (data.find(
                            (item: any) => item.name == selectedRadioButton
                        )?.value as OptionValue[]) ?? []
                    }
                ></TalkTrackDataRadio>
            )}
            {data.find((item) => item.name == selectedRadioButton)?.type ==
                'table' && (
                <TalkTrackDataTable
                    columnNames={
                        (data.find(
                            (item: any) => item.name == selectedRadioButton
                        )?.columns as string[]) ?? []
                    }
                    data={
                        (data.find(
                            (item: any) => item.name == selectedRadioButton
                        )?.value as CategoryQuestion[]) ?? []
                    }
                ></TalkTrackDataTable>
            )}
        </Stack>
    )
}
