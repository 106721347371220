/**
 * @file CallSessionRadioGroup.tsx
 * @description This file contains the logic to render the CallSessionRadioGroup component.
 */
import {
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import { ICallOption } from 'buddy-api/types/callstatus'
import { useCallSession } from 'context/CallSessionProvider'
import { isEmpty } from 'lodash'
import React from 'react'
import { CallSessionRadioGroupProps } from 'types/CallSessionPage'

export const CallSessionRadioGroup: React.FC<
    CallSessionRadioGroupProps
> = () => {
    const { reasonsV2, callSessionV2, updateSelections } = useCallSession()

    const handleClick = (e: any, button: ICallOption) => {
        if (callSessionV2.reason?.id == button.id) {
            updateSelections({
                reason: {
                    $set: null,
                },
            })
            return
        }
        updateSelections({
            reason: {
                $set: {
                    id: button.id,
                    name: button.name,
                },
            },
        })
    }

    if (isEmpty(reasonsV2)) return null

    return (
        <Grid paddingY={1} item xs={12} sx={{}}>
            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                gap={1}
                flexWrap={'wrap'}
            >
                <Typography
                    fontFamily="Inter"
                    fontSize={12}
                    fontWeight={500}
                    pr={4}
                >
                    Select a reason:<span className="required-star">*</span>
                </Typography>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={callSessionV2.reason?.id}
                >
                    {reasonsV2.map((button, index) => {
                        return (
                            <FormControlLabel
                                key={'RK' + index}
                                value={button.id}
                                control={
                                    <Radio
                                        id={`${button.id}::${button.name}`}
                                        onClick={(e) => {
                                            handleClick(e, button)
                                        }}
                                        sx={{
                                            color: '#00002199',
                                            '&.Mui-checked': {
                                                color: '#774DFC',
                                            },
                                        }}
                                    />
                                }
                                label={button.name}
                            />
                        )
                    })}
                </RadioGroup>
            </Stack>
        </Grid>
    )
}
