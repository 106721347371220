/**
 * @file TalkTrackCustomerMetrics.tsx
 * @description This file contains the logic to render the customer metrics for the talk track.
 */
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Collapse,
    IconButton,
    SxProps,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useCallSession } from 'context/CallSessionProvider'
import formatters from 'utils/formatters'

type TalkTrackCustomerMetricsProps = {
    sx?: SxProps
}
export const TalkTrackCustomerMetrics: React.FC<
    TalkTrackCustomerMetricsProps
> = () => {
    const [expanded, setExpanded] = useState<boolean>(true)
    const { account, loading } = useCallSession()
    return (
        <Card
            elevation={0}
            sx={{
                width: '100%',
                minWidth: '260px',
                flexShrink: '1',
                maxHeight: '352px',
                height: '100%',
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            }}
        >
            <CardHeader
                titleTypographyProps={{
                    sx: {
                        fontFamily: 'Inter',
                        color: '#000021',
                        fontWeight: 500,
                        fontSize: 16,
                    },
                }}
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'background.paper',
                }}
                title="Customer Metrics"
                action={
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon
                            sx={{
                                transform: expanded
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)',
                            }}
                        />
                    </IconButton>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent
                    sx={{
                        paddingTop: 0,
                        '& .cm-bold': {
                            color: 'rgba(0, 0, 33, 1)',
                            fontWeight: 600,
                        },
                    }}
                >
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <CircularProgress size={25} />
                        </Box>
                    ) : (
                        <>
                            <Typography
                                paddingY={0.5}
                                fontFamily={'Inter'}
                                fontWeight={400}
                                fontSize={12}
                            >
                                Last purchase date:{' '}
                                <span className="cm-bold">
                                    {formatters.formatDate(
                                        account?.last_purchase_date ?? '',
                                        'utc',
                                        'America/Chicago'
                                    ).localDateTextForm ?? 'N/A'}
                                </span>
                            </Typography>
                            <Typography
                                paddingY={0.5}
                                fontFamily={'Inter'}
                                fontWeight={400}
                                fontSize={12}
                            >
                                First purchase date:{' '}
                                <span className="cm-bold">
                                    {formatters.formatDate(
                                        account?.first_purchase_date ?? '',
                                        'utc',
                                        'America/Chicago'
                                    ).localDateTextForm ?? 'N/A'}
                                </span>
                            </Typography>
                            <Typography
                                paddingY={0.5}
                                fontFamily={'Inter'}
                                fontWeight={400}
                                fontSize={13}
                            >
                                Number of purchased categories:{' '}
                                <span className="cm-bold">
                                    {account?.num_purchased_categories ?? 'N/A'}
                                </span>
                            </Typography>
                            <Typography
                                paddingY={0.5}
                                fontFamily={'Inter'}
                                fontWeight={400}
                                fontSize={13}
                            >
                                Months since last purchase:{' '}
                                <span className="cm-bold">
                                    {account?.last_purchase_date
                                        ? formatters.monthsSinceLastPurchase(
                                              account?.last_purchase_date
                                          )
                                        : 'N/A'}
                                </span>
                            </Typography>
                        </>
                    )}
                </CardContent>
            </Collapse>
        </Card>
    )
}
