/**
 * @file CallSessionFilter.tsx
 * @description This file contains the logic to render the call session filter.
 */
import React, { useState, useCallback, useEffect } from 'react'
import {
    SxProps,
    Stack,
    TextField,
    FormGroup,
    FormControlLabel,
    IconButton,
    Divider,
    Tooltip,
    InputAdornment,
    Checkbox,
} from '@mui/material'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import SearchIcon from '@mui/icons-material/Search'
import { makeStyles } from '@mui/styles'
import {
    THistoryFilterValues,
    useCallSession,
} from 'context/CallSessionProvider'
import { toTitleCase } from 'utils/stringformatter'
type CallSessionFilterProps = {
    sx?: SxProps
    columnField: string
}

const useStyles = makeStyles({
    formControlLabel: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 12,
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export const CallSessionFilter: React.FC<CallSessionFilterProps> = ({
    columnField,
}) => {
    const classes = useStyles()
    const { filterOptions, filterState, handleFilterStateChangeCallback } =
        useCallSession()
    const [filteredOptions, setFilteredOptions] = useState<(string | null)[]>(
        filterOptions[columnField]
    )

    const handleCheckboxChange = async (option: string) => {
        const currentFilterState = filterState[columnField] || []

        if (option === 'Select All') {
            const allOptions = filteredOptions.filter(
                (opt: any) => opt !== null
            ) as string[]
            const newFilterState =
                currentFilterState.length === allOptions.length
                    ? []
                    : allOptions
            handleFilterStateChangeCallback(
                columnField as keyof THistoryFilterValues,
                newFilterState
            )
        } else {
            const newFilterState = currentFilterState.includes(option)
                ? currentFilterState.filter((item) => item !== option)
                : [...currentFilterState, option]

            handleFilterStateChangeCallback(
                columnField as keyof THistoryFilterValues,
                newFilterState
            )
        }
    }

    const handleTextInput = useCallback(
        (e) => {
            const filterValue = e.target.value.toLowerCase()
            setFilteredOptions(
                filterOptions[columnField].filter((option) =>
                    option.toLowerCase().includes(filterValue)
                )
            )
        },
        [columnField, filterOptions]
    )
    const handleFilterClear = useCallback(() => {
        handleFilterStateChangeCallback(
            columnField as keyof THistoryFilterValues,
            []
        )
    }, [])

    return (
        <Stack direction={'column'} gap={1} maxWidth={'230px'}>
            <Stack
                direction={'row-reverse'}
                justifyContent={'space-between'}
                alignItems={'center'}
                spacing={1}
            >
                <Tooltip title="Clear Filter">
                    <IconButton color="primary" onClick={handleFilterClear}>
                        <FilterListOffIcon
                            style={{
                                fontSize: '16px',
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <TextField
                    autoComplete="false"
                    autoCorrect="false"
                    id="input_filter"
                    variant="outlined"
                    onChange={handleTextInput}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderWidth: '1px',
                            },
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                            </InputAdornment>
                        ),
                        sx: {
                            paddingLeft: '5px',
                        },
                    }}
                    size="small"
                />
            </Stack>
            <Stack
                sx={{
                    maxHeight: 300,
                    overflow: 'auto',
                }}
            >
                <FormGroup>
                    {filteredOptions.filter((opt: any) => opt !== null).length >
                        0 && (
                        <FormControlLabel
                            classes={{
                                root: classes.formControlLabel,
                                label: classes.label,
                            }}
                            control={
                                <Checkbox
                                    checked={filteredOptions
                                        .filter((opt: any) => opt !== null)
                                        .every((option) =>
                                            filterState[columnField]?.includes(
                                                option as string
                                            )
                                        )}
                                    onChange={() =>
                                        handleCheckboxChange('Select All')
                                    }
                                    size="small"
                                    sx={{
                                        color: '#774DFC',
                                        '&.Mui-checked': {
                                            color: '#774DFC',
                                        },
                                    }}
                                />
                            }
                            label={'Select All'}
                        />
                    )}
                    <Divider />
                    {filteredOptions.map(
                        (option: string | null, index: number) => {
                            if (option === null) {
                                return (
                                    <FormControlLabel
                                        key={index + 'CSFc'}
                                        classes={{
                                            root: classes.formControlLabel,
                                            label: classes.label,
                                        }}
                                        control={
                                            <Checkbox
                                                key={index + 'CSFC'}
                                                checked={
                                                    filterState[
                                                        columnField
                                                    ]?.includes('NA') || false
                                                }
                                                onChange={() =>
                                                    handleCheckboxChange('NA')
                                                }
                                                size="small"
                                                sx={{
                                                    color: '#774DFC',
                                                    '&.Mui-checked': {
                                                        color: '#774DFC',
                                                    },
                                                }}
                                            />
                                        }
                                        label={'N/A'}
                                    />
                                )
                            } else {
                                return (
                                    <FormControlLabel
                                        key={index + 'CSF'}
                                        classes={{
                                            root: classes.formControlLabel,
                                            label: classes.label,
                                        }}
                                        control={
                                            <Checkbox
                                                key={index + 'CSFC'}
                                                checked={
                                                    filterState[
                                                        columnField
                                                    ]?.includes(option) || false
                                                }
                                                onChange={() =>
                                                    handleCheckboxChange(option)
                                                }
                                                size="small"
                                                sx={{
                                                    color: '#774DFC',
                                                    '&.Mui-checked': {
                                                        color: '#774DFC',
                                                    },
                                                }}
                                            />
                                        }
                                        label={option}
                                    />
                                )
                            }
                        }
                    )}
                </FormGroup>
            </Stack>
        </Stack>
    )
}

export default CallSessionFilter
