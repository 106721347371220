/**
 * @file FieldFormatter.tsx
 * @description This file contains the logic to format the fields for sorting and filtering.
 * @description It also contains the logic to omit the fields that are undefined or null.
 * @description It also contains the logic to get the sorting column key and column key.
 * @description It also contains the logic to check if the value should be omitted.
 * @description It also contains the logic to get the order by.
 */
import { GridSortModel } from '@mui/x-data-grid-pro'

const FIELD_REPLACEMENT: Record<string, string> = {
    added_by: 'agent_id',
    reason_code: 'status',

    // transaction history
    item: 'item_category',
    manufacturer: 'item_manufacturer',
    sku: 'item_name',
    doc_id: 'document_number',
    contact_name: 'contact_billing',

    // for sorting
    order_date: 'date',
    qty: 'quantity',
    unit_price: 'cost_unit',
    total: 'cost_total',
}

export const toOrderBy = (sort: GridSortModel) => {
    if (sort.length === 0) return ''
    const item = sort[0]
    return item.sort === 'desc' ? `-${item.field}` : item.field
}

export const GetSortingColumnKey = (columnField: string) => {
    const updatedColumnField = FIELD_REPLACEMENT[columnField] || columnField
    return updatedColumnField
}

export const GetColumnKey = (
    columnField: string,
    operatorValue: string | number | undefined
) => {
    const updatedColumnField = FIELD_REPLACEMENT[columnField] || columnField
    return updatedColumnField + operatorValue
}

export const shouldOmit = (value: any, key: string): boolean => {
    if (value === undefined || value === null) return true
    if (typeof value === 'string' && value.trim() === '') return true
    return false
}
