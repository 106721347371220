/**
 * @file Sidebar.tsx
 * @description This file contains the sidebar component of the application.
 * @description The sidebar component is used to display the navigation menu of the application.
 */

import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import { logoutPageURL } from 'routes/routes'

import {
    Box,
    Collapse,
    Link,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SxProps,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import config from 'config'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { NavList, NavTooltip } from 'components/SideNav'
import React from 'react'
import { Link as RouterLink, useMatch } from 'react-router-dom'
import LevataLogo from '../assets/images/Levata-Logo.svg'

export type SidebarItemProps = {
    title: string
    to?: string
    active?: boolean
    onClick?: () => void
    url?: string
    tooltip: string
    visible?: boolean
    icon?: JSX.Element
    open?: boolean
    childElement?: Array<SidebarItemProps>
    menuExpaned?: boolean
}

const useStyles = makeStyles({
    sidebar: {
        '&:hover $toggle': {
            visibility: 'visible',
        },
    },
    toggle: {},
    listItem: {
        flexGrow: 'initial',
        maxHeight: '3rem',
    },
    childDivHid: {
        display: 'none',
    },
    childDiv: {
        marginLeft: '25px',
        paddingLeft: '10px',
        borderLeft: '2px solid rgba(189, 191, 191, 0.3)',
    },
})

export const SidebarItem: React.FC<SidebarItemProps> = ({
    title,
    to,
    active = false,
    onClick,
    children,
    tooltip,
    childElement,
    open,
    menuExpaned,
}) => {
    const classes = useStyles()
    const tooltipTitle = tooltip ?? title
    const [menuItemExpand, setMenuItemExpand] = React.useState(open)

    if (!to && !onClick)
        throw new Error('Either to or onClick must be provided')

    if (to) {
        if (to.startsWith('http')) {
            return (
                <NavTooltip arrow placement="right" title={tooltipTitle}>
                    <ListItemButton
                        component={Link}
                        id="application-icon-monitoring"
                        href={to}
                        target="_blank"
                        className={clsx(classes.listItem, { active: active })}
                        sx={{ textDecoration: 'none !important' }}
                    >
                        <ListItemIcon>{children}</ListItemIcon>
                        <ListItemText
                            primary={title}
                            id="application-icon-monitoring-text"
                        />
                    </ListItemButton>
                </NavTooltip>
            )
        }
        const expand = menuItemExpand ? <ExpandLess /> : <ExpandMore />
        return (
            <>
                {/* eslint-disable */}
                <NavTooltip arrow placement="right" title={tooltipTitle}>
                    <ListItemButton
                        component={RouterLink}
                        to={to}
                        className={clsx(classes.listItem, { active: active })}
                        onClick={() => {
                            setMenuItemExpand(!menuItemExpand)
                        }}
                    >
                        <ListItemIcon>{children}</ListItemIcon>
                        <ListItemText
                            primary={title}
                            id="application-icon-opportunities-text"
                        />
                        {childElement?.length && expand}
                    </ListItemButton>
                </NavTooltip>
                {menuItemExpand && childElement ? (
                    <div
                        className={
                            !menuExpaned
                                ? classes.childDivHid
                                : classes.childDiv
                        }
                    >
                        {childElement.map((childItem) => {
                            return (
                                <SidebarItem
                                    key={'child' + childItem.title}
                                    title={childItem.title}
                                    active={childItem.active}
                                    to={childItem.to}
                                    tooltip={
                                        !menuExpaned ? childItem.tooltip : ''
                                    }
                                >
                                    {childItem.icon}
                                </SidebarItem>
                            )
                        })}
                    </div>
                ) : (
                    ''
                )}
            </>
        )
    }
    const expand = menuItemExpand ? <ExpandLess /> : <ExpandMore />
    return (
        <>
            <NavTooltip arrow placement="right" title={tooltipTitle}>
                <ListItemButton
                    onClick={() => {
                        setMenuItemExpand(!menuItemExpand)
                        if (onClick) {
                            onClick()
                        }
                    }}
                    id="application-icon-isa"
                    className={clsx(classes.listItem, { active: active })}
                >
                    <ListItemIcon>{children}</ListItemIcon>
                    <ListItemText
                        primary={title}
                        id="application-icon-isa-text"
                    />
                    {childElement?.length && expand}
                </ListItemButton>
            </NavTooltip>
            {menuItemExpand && childElement ? (
                <div
                    className={
                        !menuExpaned ? classes.childDivHid : classes.childDiv
                    }
                >
                    {childElement.map((childItem) => {
                        return childItem.url ? (
                            <NavTooltip
                                arrow
                                placement="right"
                                title={childItem.tooltip}
                            >
                                <ListItemButton
                                    component={RouterLink}
                                    to={childItem.url}
                                    className={clsx(classes.listItem, {
                                        active: childItem.active,
                                    })}
                                >
                                    <ListItemIcon>
                                        {childItem.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={childItem.title}
                                        id={childItem.title + '-menu'}
                                    />
                                </ListItemButton>
                            </NavTooltip>
                        ) : (
                            ''
                        )
                    })}
                </div>
            ) : (
                ''
            )}
        </>
    )
}

export const SideBar: React.VFC<{
    sx?: SxProps
    sidebarType: string
    items: SidebarItemProps[]
    collectNextUrl?: (url: string, navigateFlag: boolean) => void
}> = ({ sx, items, sidebarType, collectNextUrl }) => {
    const [expanded, setExpanded] = React.useState(false)
    const classes = useStyles()

    const matchColumns = useMatch(`${config.app.basePath}/admin/columns`)

    const matchCustomerAttributes = useMatch(
        `${config.app.basePath}/admin/customer-attributes`
    )

    const matchOutcomes = useMatch(`${config.app.basePath}/admin/outcomes`)
    const matchLayout = useMatch(`${config.app.basePath}/admin/layout`)

    const matchCallSession = useMatch(
        `${config.app.basePath}/agent/callsession/:customer_id`
    )

    const showInformationOnTabChange = (item: SidebarItemProps) => {
        const columnAttributeFlag = localStorage.getItem?.(
            'columnAttributeFlag'
        )
        const saveChangesAlertFlag = localStorage.getItem?.(
            'saveChangesAlertFlag'
        )
        if (
            (columnAttributeFlag === 'true' ||
                saveChangesAlertFlag === 'true') &&
            (matchColumns !== null ||
                matchCustomerAttributes !== null ||
                matchOutcomes !== null ||
                matchCallSession !== null ||
                matchLayout !== null)
        ) {
            collectNextUrl?.(item?.url ?? '', false)
        } else {
            collectNextUrl?.(item?.url ?? '', true)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                position: 'relative',
                ...sx,
            }}
            className={classes.sidebar}
        >
            <div>
                <Collapse
                    orientation="horizontal"
                    in={expanded}
                    collapsedSize={64}
                    sx={{
                        height: '100%',
                        '& .active': {
                            backgroundColor: 'rgba(119, 77, 252, 1)',
                            '& .MuiListItemText-primary': {
                                color: 'rgba(255, 255, 255, 1)',
                            },
                        },
                    }}
                >
                    <NavList
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            height: '100%',
                            py: 2,
                            px: '12px',
                            zIndex: 110,
                        }}
                    >
                        {sidebarType !== 'buddy-admin' && (
                            <Box
                                component="img"
                                sx={{
                                    width: expanded ? '100%' : '20%',
                                    height: 56,
                                    background: 'rgba(0, 0, 33, 1)',
                                    p: 0,
                                }}
                                src={LevataLogo}
                                alt="Levata"
                            />
                        )}

                        {items.map((item) => {
                            return (
                                !!item.visible && (
                                    <SidebarItem
                                        title={item.title}
                                        key={'t' + item.title}
                                        active={item.active}
                                        to={item.to}
                                        onClick={() => {
                                            showInformationOnTabChange(item)
                                        }}
                                        menuExpaned={expanded}
                                        tooltip={!expanded ? item.tooltip : ''}
                                        // expanable = {item.childElement && item.childElement.length > 0? true: false}
                                        childElement={item.childElement}
                                        open={item.open}
                                    >
                                        {item.icon}
                                    </SidebarItem>
                                )
                            )
                        })}

                        <Box sx={{ flex: 1 }} />
                        <SidebarItem
                            title={expanded ? 'Collapse' : 'Expand'}
                            onClick={() => {
                                setExpanded((e) => !e)
                            }}
                            tooltip={expanded ? 'Collapse' : 'Expand'}
                        >
                            {expanded ? (
                                <KeyboardDoubleArrowLeftOutlinedIcon id="application-button-collapse-panel" />
                            ) : (
                                <KeyboardDoubleArrowRightOutlinedIcon />
                            )}
                        </SidebarItem>
                        <SidebarItem
                            title="Logout"
                            to={logoutPageURL()}
                            onClick={() => {
                                showInformationOnTabChange({
                                    title: 'forceLogout',
                                    url: 'forceLogout',
                                    active: true,
                                    tooltip: 'forceLogout',
                                })
                            }}
                            tooltip={'Logout'}
                        >
                            <LogoutIcon id="application-icon-logout" />
                        </SidebarItem>
                    </NavList>
                </Collapse>
            </div>
        </Box>
    )
}
