/**
 * @file FollowUpDateDataGrid.tsx
 * @description This file contains the logic to render the follow up date data grid.
 */
import {
    Typography,
    Paper,
    Icon,
    IconButton,
    Tooltip,
    Button,
} from '@mui/material'
import { GridColDef, GridToolbar } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import * as React from 'react'
import { DataGridSX2 } from 'utils/datagridsx'
import { Stack } from '@mui/system'
import { v4 as uuidv4 } from 'uuid'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import formatters from 'utils/formatters'
import { AccountStatus, useCallSession } from 'context/CallSessionProvider'
import { CallSessionUpdateDatePicker } from './CallSessionUpdateDatePicker'
import { DateTime } from 'luxon'
import { toTitleCase } from 'utils/stringformatter'
import { CallSessionHeaderFollowUp } from './CallSessionHeaderFollowUp'
interface FollowUpDateDataGridProps {}

type FollowUpDateDataGridColumes = {
    contact_id: string
    contact_name: string
    follow_up_date: string
    status: string
}
export type TCallSessionFollowFilterOptions = {
    [key: string]: string[]
}

const FollowUpDateDataGrid: React.FC<FollowUpDateDataGridProps> = () => {
    const [filterState, setFilterState] =
        React.useState<TCallSessionFollowFilterOptions>({
            contact_name: [],
        })
    const [filterOptions, setFilterOptions] =
        React.useState<TCallSessionFollowFilterOptions>({
            contact_name: [],
        })
    const {
        account,
        accountContacts,
        deleteFollowUpDateCallback,
        updateFollowUpDateCallback,
    } = useCallSession()
    const [loading, setLoading] = React.useState(false)
    const [rows, setRows] = React.useState<FollowUpDateDataGridColumes[]>([])
    const [deleteRowId, setDeleteRowId] = React.useState<
        string | number | null
    >(null)
    const [deleteFollowUpDate, setDeleteFollowUpDate] = React.useState<
        string | null
    >(null)
    const [deleteContactId, setDeleteContactId] = React.useState<string | null>(
        null
    )
    const handleDeleteApiCall = React.useCallback(() => {
        if (deleteContactId && deleteFollowUpDate) {
            setLoading(true)
            deleteFollowUpDateCallback(
                deleteContactId,
                deleteFollowUpDate
            ).finally(() => {
                setLoading(false)
                setDeleteRowId(null)
                setDeleteFollowUpDate(null)
                setDeleteContactId(null)
            })
        }
    }, [deleteRowId, deleteContactId, deleteFollowUpDate])

    const handleUpdateApiCall = React.useCallback(
        (
            contactId: string,
            newFollowUpDate: string,
            oldFollowUpDate: string
        ) => {
            updateFollowUpDateCallback(
                contactId,
                oldFollowUpDate,
                newFollowUpDate
            )
        },
        []
    )

    const handleDeleteRow = (
        rowId: string | number,
        contact_id: string,
        follow_up_date: string
    ) => {
        setDeleteRowId(rowId)
        setDeleteFollowUpDate(follow_up_date)
        setDeleteContactId(contact_id)
    }

    const handleCancelDelete = () => {
        setDeleteRowId(null)
        setDeleteFollowUpDate(null)
    }

    const handleFilterChangeCallback = React.useCallback(
        async (filterValues: string[], columnField: string) => {
            setFilterState({
                [columnField]: filterValues,
            })
        },
        [filterState, accountContacts]
    )

    React.useEffect(() => {
        setRows([])
        if (accountContacts) {
            const rows: FollowUpDateDataGridColumes[] = []
            if (filterState.contact_name.length > 0) {
                accountContacts.forEach((contact) => {
                    if (
                        contact.follow_ups &&
                        contact.follow_ups.length > 0 &&
                        contact.status !== AccountStatus.OPPORTUNITY &&
                        contact.status !== AccountStatus.REJECTED &&
                        filterState.contact_name.includes(
                            contact.full_name ??
                                contact.first_name + ' ' + contact.last_name
                        )
                    ) {
                        contact.follow_ups.sort((a, b) => {
                            return (
                                DateTime.fromISO(a).toMillis() -
                                DateTime.fromISO(b).toMillis()
                            )
                        })
                        setFilterOptions((prevState) => {
                            return {
                                contact_name: [
                                    ...prevState.contact_name,
                                    contact.full_name ??
                                        contact.first_name +
                                            ' ' +
                                            contact.last_name,
                                ],
                            }
                        })
                        contact.follow_ups.forEach((followUp) => {
                            rows.push({
                                contact_id:
                                    contact.contact_id?.toString() ?? '',
                                contact_name:
                                    contact.full_name ??
                                    contact.first_name +
                                        ' ' +
                                        contact.last_name,
                                follow_up_date:
                                    DateTime.fromISO(
                                        followUp
                                    ).toLocaleString() ?? '',
                                status: contact.status ?? '',
                            })
                        })
                    }
                })
            } else {
                accountContacts.forEach((contact) => {
                    if (
                        contact.follow_ups &&
                        contact.follow_ups.length > 0 &&
                        contact.status !== AccountStatus.OPPORTUNITY &&
                        contact.status !== AccountStatus.REJECTED
                    ) {
                        contact.follow_ups.sort((a, b) => {
                            return (
                                DateTime.fromISO(a).toMillis() -
                                DateTime.fromISO(b).toMillis()
                            )
                        })
                        setFilterOptions((prevState) => {
                            return {
                                contact_name: [
                                    ...prevState.contact_name,
                                    contact.full_name ??
                                        contact.first_name +
                                            ' ' +
                                            contact.last_name,
                                ],
                            }
                        })
                        contact.follow_ups.forEach((followUp) => {
                            rows.push({
                                contact_id:
                                    contact.contact_id?.toString() ?? '',
                                contact_name:
                                    contact.full_name ??
                                    contact.first_name +
                                        ' ' +
                                        contact.last_name,
                                follow_up_date:
                                    DateTime.fromISO(
                                        followUp
                                    ).toLocaleString() ?? '',
                                status: contact.status ?? '',
                            })
                        })
                    }
                })
            }
            setRows(rows)
        }
    }, [accountContacts, filterState])

    const getRowIdCallback = React.useCallback(() => {
        return uuidv4()
    }, [])

    const tableColumns: GridColDef<FollowUpDateDataGridColumes>[] = [
        {
            field: 'contact_name',
            headerName: 'CONTACT NAME',
            headerClassName: 'custom-header-style',
            cellClassName: 'custom-row',
            filterable: false,
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <CallSessionHeaderFollowUp
                    headerName={'CONTACT NAME'}
                    isFilterEnabled={true}
                    columnField={'contact_name'}
                    filterOptions={filterOptions}
                    filterState={filterState}
                    handleFilterChangeCallback={handleFilterChangeCallback}
                ></CallSessionHeaderFollowUp>
            ),
            renderCell: (params) => (
                <>
                    {deleteRowId !== params.id && (
                        <Typography>
                            {toTitleCase(params.row.contact_name)}
                        </Typography>
                    )}
                    {deleteRowId == params.id && (
                        <Typography>Confirm Row Deletion ?</Typography>
                    )}
                </>
            ),
        },
        {
            field: 'follow_up_date',
            headerName: 'FOLLOW UP DATE',
            headerClassName: 'custom-header-style',
            cellClassName: 'custom-row',
            filterable: false,
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="end"
                    padding={1}
                    gap={0.5}
                >
                    {deleteRowId !== params.id && (
                        <Typography width={80}>
                            {params.row.follow_up_date}
                        </Typography>
                    )}
                    {deleteRowId !== params.id && (
                        <div>
                            <CallSessionUpdateDatePicker
                                contactId={params.row.contact_id}
                                oldFollowUpDate={params.row.follow_up_date}
                                status={params.row.status}
                                UpdateFollowUpDate={handleUpdateApiCall}
                            />
                            <Tooltip title="Delete">
                                <IconButton
                                    disabled={
                                        params.row.status ==
                                            AccountStatus.OPPORTUNITY ||
                                        params.row.status ==
                                            AccountStatus.REJECTED ||
                                        account?.status ==
                                            AccountStatus.REJECTED ||
                                        account?.status ==
                                            AccountStatus.OPPORTUNITY
                                    }
                                    size="small"
                                    color="error"
                                    aria-label="delete"
                                    onClick={() =>
                                        handleDeleteRow(
                                            params.id,
                                            params.row.contact_id,
                                            params.row.follow_up_date
                                        )
                                    }
                                >
                                    <DeleteOutlineIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                    {deleteRowId == params.id && (
                        <div>
                            <Button
                                size="small"
                                color="primary"
                                onClick={handleCancelDelete}
                            >
                                No
                            </Button>
                            <Button
                                disabled={loading}
                                onClick={handleDeleteApiCall}
                                size="small"
                                color="error"
                            >
                                Delete
                            </Button>
                        </div>
                    )}
                </Stack>
            ),
        },
    ]
    return (
        <DataGridPro
            sx={DataGridSX2}
            getRowId={getRowIdCallback}
            loading={loading}
            headerHeight={38}
            getRowHeight={() => 'auto'}
            columns={tableColumns}
            rows={rows}
            getRowClassName={(params) => {
                return params.id == deleteRowId ? 'custom-row' : ''
            }}
            disableSelectionOnClick
            disableColumnMenu
            disableDensitySelector
            hideFooter
        />
    )
}

export default FollowUpDateDataGrid
