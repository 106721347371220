import React from 'react'
import { useNavigate } from 'react-router'
import { loginPageURL } from 'routes/routes'
import { BuddyAPIClient } from 'api'
import { IUser } from 'buddy-api'

type IAuthenticatedUserContext = {
    user: IUser | null | undefined
    setUser: React.Dispatch<React.SetStateAction<IUser | null | undefined>>
}

type APIError = {
    response: { status: number }
}

const AuthenticatedUserContext = React.createContext<IAuthenticatedUserContext>(
    {} as IAuthenticatedUserContext
)

const AuthenticatedUserProvider: React.FC = ({ children }) => {
    const [user, setUser] = React.useState<IUser | null | undefined>()
    const navigate = useNavigate()

    React.useEffect(() => {
        const fetchAuthenticatedUser = () => {
            BuddyAPIClient.auth
                .me()
                .then((response) => {
                    if (response) {
                        setUser(response)
                    } else {
                        setUser(null)
                        navigate(loginPageURL(), {
                            replace: true,
                            state: { from: location },
                        })
                    }
                })
                .catch((err: APIError) => {
                    const statusCode = err?.response?.status
                    if (statusCode !== 200 && statusCode !== 201) {
                        setUser(null)
                    }
                })
        }
        if (!user) {
            fetchAuthenticatedUser()
        }
    }, [user, navigate])

    const contextValue = React.useMemo(() => {
        return { user, setUser }
    }, [user, setUser])

    return (
        <AuthenticatedUserContext.Provider value={contextValue}>
            {children}
        </AuthenticatedUserContext.Provider>
    )
}

function useAuthenticatedUser() {
    const context = React.useContext(AuthenticatedUserContext)
    if (context === undefined) {
        throw new Error(
            'useAuthenticatedUser must be used within an AuthenticatedUserProvider'
        )
    }
    return context
}

export { AuthenticatedUserProvider, useAuthenticatedUser }
