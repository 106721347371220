/**
 * @file LoginPage.tsx
 * @description This file contains the implementation of the LoginPage component.
 * @description It provides a login form for user authentication.
 * @description It handles form submission and error handling.
 * @description It redirects the user to the appropriate page after successful login.
 */

import { Box, Button, Divider, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { BuddyAPIClient } from 'api'
import BackgroundImage from 'assets/images/BG-image2.png'
import LoginLogo from 'assets/images/Login-Group.svg'
import { useAuthenticatedUser } from 'context/UserProvider'
import update from 'immutability-helper'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useLocation, useSearchParams } from 'react-router-dom'
import { proactivesPageURL, loginPageURL } from 'routes/routes'
import AccountCircle from '@mui/icons-material/AccountCircle'
import LockIcon from '@mui/icons-material/Lock'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import InputLabel from '@mui/material/InputLabel'
import * as S from './styles/LoginPage.styles'
import msLogo from 'assets/images/microsoft-logo.png'
import * as env from 'env-var'
import Cookies from 'js-cookie'

type Icredentials = {
    email?: string
    password?: string
}

type ILoginError = {
    username?: string[]
    password?: string[]
    non_field_errors?: string[]
}

type IError = {
    response: Response
    body: ILoginError
}

const ErrorList: React.FC<{ errors?: string[]; testId: string }> = ({
    errors,
    testId,
}) => {
    if (!errors) return null
    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            {errors.map((error) => (
                <Typography key={error} color="red" id={testId}>
                    {error}
                </Typography>
            ))}
        </Box>
    )
}

const LoginPage: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { user, setUser } = useAuthenticatedUser()
    const [searchParams] = useSearchParams()
    const [errors, setErrors] = React.useState<ILoginError>({})
    const [form, setForm] = React.useState<Icredentials>({})

    useEffect(() => {
        document.title = 'Buddy'
    }, [])

    function updateForm(name: string, value: string | boolean) {
        setForm(update(form, { [name]: { $set: value } }))
    }

    const retrieveUser = useCallback(async () => {
        try {
            const usr = await BuddyAPIClient?.auth.me()
            setUser(usr)
        } catch (e) {
            setUser(null)
        }
    }, [setUser])

    const OnFinish = useCallback(() => {
        const email = form?.email ?? ''
        const password = form?.password ?? ''
        if (email.includes('__')) {
            const userEmail = email.split('__')[1]
            Cookies.set('Mock', userEmail)
        }
        BuddyAPIClient.auth
            .login(email, password)
            .then((response) => {
                const userID = response?.id
                retrieveUser()
                if (userID) {
                    setUser({
                        id: userID,
                        email: response.email,
                        username: response.username,
                        token: response.token,
                    })
                }
                const nextUrl = searchParams.get('next') ?? proactivesPageURL()
                navigate(nextUrl, {
                    replace: true,
                })
            })
            .catch((err: IError) => {
                const error: ILoginError = err.body
                if (error?.password !== undefined) {
                    if (error.password[0] === 'This field may not be blank.')
                        error.password[0] = 'Password is a required field.'
                }
                if (error?.username !== undefined) {
                    if (error.username[0] === 'This field may not be blank.')
                        error.username[0] = 'Email is a required field.'
                }
                setErrors(error)
            })
    }, [navigate, searchParams, form])

    useEffect(() => {
        if (!user) return
        if (location.state)
            return navigate(location.pathname ?? proactivesPageURL(), {
                replace: true,
            })
        return navigate(proactivesPageURL(), { replace: true })
    }, [user, location, navigate])

    const onSamlLogin = useCallback(() => {
        window.location.replace(
            env.get('REACT_APP_IDP_LOGIN_URL').asString() ?? loginPageURL()
        )
    }, [])

    const handleFormInput = useCallback(
        (e) => {
            if (e.target.id === 'login-text-password')
                updateForm('password', e.target.value)
            if (e.target.id === 'login-text-email-id')
                updateForm('email', e.target.value)
        },
        [updateForm]
    )

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: `linear-gradient(rgba(120, 77, 255, 0.5), rgba(24, 152, 255, 0.5)), url(${BackgroundImage})  no-repeat center / cover`,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '16px',
                    minWidth: '428px',
                    minHeight: 'max-content',
                    overflow: 'hidden',
                    border: '1px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        minHeight: '10rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'rgba(255, 255, 255, 1)',
                    }}
                >
                    <Box component="img" src={LoginLogo} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        background: 'rgba(245, 245, 245, 1)',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography
                        fontWeight={400}
                        fontSize={24}
                        fontFamily={'Inter'}
                        color="#141928"
                        sx={{
                            marginTop: '1rem',
                        }}
                    >
                        <span id="login-heading-to-your-account">
                            Sign-in to your account
                        </span>
                    </Typography>
                    <Box
                        sx={{
                            width: '18rem',
                        }}
                    >
                        {['local', 'dev'].includes(
                            env.get('REACT_APP_ENV').asString() ?? 'local'
                        ) ? (
                            <>
                                <InputLabel
                                    htmlFor="login-text-email-id"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        marginY: '5px',
                                        marginTop: '15px',
                                        color: '#000021',
                                    }}
                                >
                                    Email:
                                </InputLabel>
                                <TextField
                                    sx={{
                                        width: '100%',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '8px',
                                            background: '#FFFFFF',
                                            '& fieldset': {
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                borderColor:
                                                    'rgba(0, 0, 0, 0.23)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderWidth: '1px',
                                                borderColor:
                                                    'rgba(0, 0, 0, 0.23)',
                                            },
                                            '& .MuiInputBase-input::placeholder':
                                                {
                                                    fontWeight: '600',
                                                },
                                        },
                                    }}
                                    value={form.email ?? ''}
                                    id="login-text-email-id"
                                    onChange={handleFormInput}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <ErrorList
                                    testId="login-message-error-valid-email"
                                    errors={errors?.username ?? []}
                                />
                                <InputLabel
                                    htmlFor="login-text-password"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        marginY: '5px',
                                        marginTop: '15px',
                                        color: '#000021',
                                    }}
                                >
                                    Password:
                                </InputLabel>
                                <TextField
                                    id="login-text-password"
                                    sx={{
                                        width: '100%',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '8px',
                                            background: '#FFFFFF',
                                            '& fieldset': {
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                borderColor:
                                                    'rgba(0, 0, 0, 0.23)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderWidth: '1px',
                                                borderColor:
                                                    'rgba(0, 0, 0, 0.23)',
                                            },
                                            '& .MuiInputBase-input::placeholder':
                                                {
                                                    fontWeight: '600',
                                                },
                                        },
                                    }}
                                    type="password"
                                    value={form.password ?? ''}
                                    onChange={handleFormInput}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Link
                                                    href="#"
                                                    variant="body2"
                                                    underline="none"
                                                    sx={{
                                                        color: 'black',
                                                        fontWeight: 600,
                                                        '&:hover': {
                                                            color: 'rgba(120, 77, 255, 1)',
                                                        },
                                                    }}
                                                >
                                                    Forgot?
                                                </Link>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.code === 'Enter') {
                                            OnFinish()
                                        }
                                    }}
                                />
                                <ErrorList
                                    testId="login-message-error-empty-field"
                                    errors={errors?.password ?? []}
                                />
                                <LoadingButton
                                    sx={{
                                        width: '100%',
                                        marginTop: '20px',
                                        marginBottom: '8px',
                                        background:
                                            'linear-gradient(to bottom,  rgba(120, 77, 255, 1),rgba(24, 152, 255, 1))',
                                        borderRadius: '50px',
                                        color: 'white',
                                        '&:hover': {
                                            background:
                                                'linear-gradient(to bottom,rgba(24, 152, 255, 1),rgba(120, 77, 255, 1))',
                                        },
                                    }}
                                    id="login-button-sign-in"
                                    color="primary"
                                    variant="contained"
                                    onClick={OnFinish}
                                >
                                    Login
                                </LoadingButton>
                            </>
                        ) : null}

                        <Divider
                            sx={{
                                marginY: 2,
                            }}
                        ></Divider>
                        <Button
                            variant="text"
                            onClick={onSamlLogin}
                            sx={{
                                width: '100%',
                                marginBottom: 2,
                            }}
                        >
                            <S.LogoWrapper>
                                <S.Logo src={msLogo} />
                            </S.LogoWrapper>
                        </Button>

                        <ErrorList
                            testId="login-message-error-invalid-credentials"
                            errors={errors?.non_field_errors ?? []}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default LoginPage
