/**
 * @file datagridsx.tsx
 * @description This file contains the styling for the data grid component.
 * @returns {CSSObject} The styling for the data grid component.
 */
export const DataGridSX = () => {
    return {
        '& .MuiDataGrid-pinnedColumns--left, & .MuiDataGrid-pinnedColumnHeaders--left':
            {
                backgroundColor: 'background.paper',
            },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            borderBottom: 'none !important',
            justifyContent: 'flex-start !important',
        },
        '& .MuiDataGrid-columnHeader': {
            ':first-of-type': {
                paddingLeft: '12px',
            },
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 700,
            fontSize: '10px',
        },
        '& .MuiDataGrid-pinnedColumnHeaders, & .MuiDataGrid-pinnedColumns--left':
            {
                boxShadow: '2px 0px 28px rgb(0 0 0 / 25%)',
            },
        '& .MuiTablePagination-toolbar p': {
            marginBottom: 0,
        },
        '& .MuiDataGrid-row:hover': {
            boxShadow: '0px 4px 20px rgba(119, 77, 252, 0.2)',
            transition: 'box-shadow 0.3s',
            background: 'rgba(119, 77, 252, 0.1)',
            cursor: 'pointer',
        },
        '& .MuiDataGrid-cell': {
            fontWeight: 400,
            fontSize: '13px',
            ':first-of-type': {
                paddingLeft: '12px',
            },
        },
        '& .MuiTablePagination-selectLabel, & .MuiPagination-ul, & .MuiTablePagination-select & .MuiMenu-list':
            {
                fontSize: '12px !important',
                color: '#3A404D !important',
                fontWeight: '400 !important',
            },
        '& .MuiDataGrid-cellContent': {
            lineHeight: 1.4,
            overflow: 'visible !important',
        },
        '& .MuiDataGridPro-sortIcon': {
            display: 'none !important',
            PointerEvent: 'none !important',
            visibility: 'hidden',
        },
        '& .MuiDataGrid-sortIcon': {
            display: 'none !important',
            PointerEvent: 'none !important',
            visibility: 'hidden !important',
        },
        '& .MuiDataGrid-columnHeader .MuiDataGrid-filterIcon': {
            display: 'none !important',
            PointerEvent: 'none !important',
            visibility: 'hidden !important',
        },
        '& .MuiDataGrid-menuIcon.MuiDataGrid-menuIconOpen': {
            display: 'none !important',
            PointerEvent: 'none !important',
            visibility: 'hidden !important',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-filterIcon': {
            display: 'none !important',
            PointerEvent: 'none !important',
            visibility: 'hidden !important',
        },
    }
}

export const DataGridSX2 = () => {
    return {
        '& .MuiDataGrid-pinnedColumns--left, & .MuiDataGrid-pinnedColumnHeaders--left':
            {
                backgroundColor: 'background.paper',
            },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            borderBottom: 'none !important',
            justifyContent: 'flex-start !important',
        },
        '& .MuiDataGrid-columnHeader': {
            ':first-of-type': {
                paddingLeft: '12px',
            },
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 700,
            fontSize: '10px',
        },
        '& .MuiDataGrid-pinnedColumnHeaders, & .MuiDataGrid-pinnedColumns--left':
            {
                boxShadow: '2px 0px 28px rgb(0 0 0 / 25%)',
            },
        '& .MuiTablePagination-toolbar p': {
            marginBottom: 0,
        },
        '& .MuiDataGrid-row:hover': {
            boxShadow: '0px 4px 20px rgba(119, 77, 252, 0.2)',
            transition: 'box-shadow 0.3s',
            background: 'rgba(119, 77, 252, 0.1)',
            cursor: 'pointer',
        },
        '& .MuiDataGrid-cell': {
            fontWeight: 400,
            fontSize: '13px',
            ':first-of-type': {
                paddingLeft: '12px',
            },
        },
        '& .MuiTablePagination-selectLabel, & .MuiPagination-ul, & .MuiTablePagination-select & .MuiMenu-list':
            {
                fontSize: '12px !important',
                color: '#3A404D !important',
                fontWeight: '400 !important',
            },
        '& .MuiDataGrid-cellContent': {
            lineHeight: 1.4,
            overflow: 'visible !important',
        },
        '& .MuiDataGridPro-sortIcon': {
            display: 'none !important',
            PointerEvent: 'none !important',
            visibility: 'hidden',
        },
        '& .MuiDataGrid-sortIcon': {
            display: 'none !important',
            PointerEvent: 'none !important',
            visibility: 'hidden !important',
        },
        '& .MuiDataGrid-columnHeader .MuiDataGrid-filterIcon': {
            display: 'none !important',
            PointerEvent: 'none !important',
            visibility: 'hidden !important',
        },
        '& .MuiDataGrid-menuIcon.MuiDataGrid-menuIconOpen': {
            display: 'none !important',
            PointerEvent: 'none !important',
            visibility: 'hidden !important',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-filterIcon': {
            display: 'none !important',
            PointerEvent: 'none !important',
            visibility: 'hidden !important',
        },
    }
}
