/**
 * @file CustomerTransactionHistoryDataGrid.tsx
 * @description This file contains the logic to render the data grid for the customer transaction history.
 */
import { Checkbox, Paper, Typography } from '@mui/material'
import {
    DataGridPro,
    GridColDef,
    GridFilterModel,
    GridLinkOperator,
    GridSortModel,
} from '@mui/x-data-grid-pro'
import { ListResponseWrapper } from 'buddy-api/ApiMixin'
import { TransactionHistory } from 'buddy-api/types/TransactionHistory'
import { CustomFooter, CustomHeader } from 'components/opportunity'
import { useCallSession } from 'context/CallSessionProvider'
import React, { useCallback, useEffect, useState } from 'react'
import { DataGridSX } from 'utils/datagridsx'
import { v4 as uuidv4 } from 'uuid'
import { CallSessionTableHeader } from './CallSessionTableHeader'
import useSortableColumns from 'hooks/useSortState'
import {
    GetColumnKey,
    GetSortingColumnKey,
    toOrderBy,
} from 'utils/FieldFormatter'
import { debounce } from 'lodash'
import formatters from 'utils/formatters'

type CustomerTransactionHistoryDataGridProps = {}

const ACTIVE_FILTER_COLUMNS = [
    'order_date',
    'item',
    'manufacturer',
    'sku',
    'doc_id',
    'contact_name',
    'status',
]

type TColumns = {
    order_date: string | null
    item: string | null
    manufacturer: string | null
    sku: string | null
    doc_id: string | null
    contact_name: string | null
    status: string | null
    shipped_address: string | null
    qty: number | null
    unit_price: string | null
    total: string | null
}

export type TransactionHistoryType = 'Sales Order' | 'Quote'

export const CustomerTransactionHistoryDataGrid: React.FC<
    CustomerTransactionHistoryDataGridProps
> = () => {
    const {
        fetchTransactionHistory,
        account,
        filterState,
        setTransactionHistoryType,
        contactBilling,
        handleFilterResetCallback,
        loading,
    } = useCallSession()
    const [queryOptions, setQueryOptions] = useState<any>()
    const [sortModel, setSortModel] = useState<GridSortModel>([
        { field: 'date', sort: 'desc' },
    ])
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [],
        linkOperator: GridLinkOperator.And,
    })
    const [localLoading, setLocalLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [activeButton, setActiveButton] =
        useState<TransactionHistoryType>('Sales Order')
    const [tableRowData, setTableRowData] = useState<TColumns[]>([])
    const [sortOrder, toggleSort] = useSortableColumns({
        order_date: 'desc',
        item: null,
        manufacturer: null,
        sku: null,
        doc_id: null,
        contact_name: null,
        status: null,
        shipped_address: null,
        qty: null,
        unit_price: null,
        total: null,
    })

    const setToggleSortCallback = useCallback(
        async (field: string) => {
            toggleSort(field)
        },
        [toggleSort]
    )

    const debouncedFetch = debounce(() => {
        if (account?.account_id && !loading) {
            setLocalLoading(true)
            setTableRowData([])
            fetchTransactionHistory(
                activeButton,
                pageSize,
                page,
                toOrderBy(sortModel),
                account?.account_id,
                queryOptions
            ).then((res: ListResponseWrapper<TransactionHistory>) => {
                const data: TColumns[] = []
                res.results.forEach((item) => {
                    data.push({
                        order_date: item.date,
                        item: item.item_category,
                        manufacturer: item.item_manufacturer,
                        sku: item.item_name,
                        doc_id: item.document_number,
                        contact_name: item.contact_name,
                        status: item.status,
                        shipped_address: item.address_shipping,
                        qty: item.quantity,
                        unit_price: item.cost_unit,
                        total: item.cost_total,
                    })
                })
                setRowCount(res.count ?? 0)
                setTableRowData(data)
                setLocalLoading(false)
            })
        }
    }, 500)

    useEffect(() => {
        debouncedFetch()
        return () => {
            debouncedFetch.cancel()
        }
    }, [
        pageSize,
        page,
        sortModel,
        queryOptions,
        activeButton,
        account?.account_id,
        loading,
    ])

    const SalesOrderTableColumns: GridColDef<TColumns>[] = [
        {
            field: 'order_date',
            headerName: 'ORDER DATE',
            valueFormatter: (params) =>
                formatters.formatDate(params.value, 'utc', 'America/Chicago')
                    .localDate,
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'ORDER DATE'}
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'order_date'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 120,
            maxWidth: 125,
        },
        {
            field: 'item',
            headerName: 'ITEM',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'ITEM'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'item'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 165,
        },
        {
            field: 'manufacturer',
            headerName: 'MANUFACTURER',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'MANUFACTURER'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'manufacturer'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'sku',
            headerName: 'SKU',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'SKU'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'sku'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 160,
        },
        {
            field: 'doc_id',
            headerName: 'DOC NUMBER',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'DOC NUMBER'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'doc_id'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'contact_name',
            headerName: 'CONTACT NAME',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'CONTACT NAME'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'contact_name'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'shipped_address',
            headerName: 'SHIPPED ADDRESS',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'SHIPPED ADDRESS'}
                    isFilterEnabled={false}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'shipped_address'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Typography noWrap={true}>
                    {params.row.shipped_address}
                </Typography>
            ),
        },
        {
            field: 'qty',
            headerName: 'QTY',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'QTY'}
                    isFilterEnabled={false}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'qty'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
        },
        {
            field: 'unit_price',
            headerName: 'UNIT PRICE',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'UNIT PRICE'}
                    isFilterEnabled={false}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'unit_price'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'total',
            headerName: 'TOTAL',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'TOTAL'}
                    isFilterEnabled={false}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'total'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 120,
        },
    ]
    const QuotesTableColumns: GridColDef<TColumns>[] = [
        {
            field: 'order_date',
            headerName: 'ORDER DATE',
            valueFormatter: (params) =>
                formatters.formatDate(params.value, 'utc', 'America/Chicago')
                    .localDate,
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'ORDER DATE'}
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'order_date'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 120,
            maxWidth: 125,
        },
        {
            field: 'item',
            headerName: 'ITEM',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'ITEM'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'item'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 165,
        },
        {
            field: 'manufacturer',
            headerName: 'MANUFACTURER',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'MANUFACTURER'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'manufacturer'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'sku',
            headerName: 'SKU',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'SKU'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'sku'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 160,
        },
        {
            field: 'doc_id',
            headerName: 'QUOTE NUMBER',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'QUOTE NUMBER'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'doc_id'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'contact_name',
            headerName: 'CONTACT NAME',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'CONTACT NAME'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'contact_name'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'status',
            headerName: 'STATUS',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'STATUS'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'status'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'qty',
            headerName: 'QTY',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'QTY'}
                    isFilterEnabled={false}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'qty'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
        },
        {
            field: 'unit_price',
            headerName: 'UNIT PRICE',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'UNIT PRICE'}
                    isFilterEnabled={false}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'unit_price'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'total',
            headerName: 'TOTAL',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'TOTAL'}
                    isFilterEnabled={false}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'total'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            minWidth: 120,
        },
    ]

    const handlePageChangeCallback = useCallback(
        async (newPage: number) => {
            setPage(newPage)
        },
        [setPage]
    )
    const handlePageSizeCallback = useCallback(
        async (newPageSize: number) => {
            setPageSize(newPageSize)
        },
        [setPageSize]
    )
    const getRowIdCallback = useCallback(() => {
        return uuidv4()
    }, [])

    const handleSalesOrderClick = () => {
        handleFilterResetCallback()
        setTransactionHistoryType('Sales Order')
        setActiveButton('Sales Order')
    }

    const handleQuotesClick = () => {
        handleFilterResetCallback()
        setTransactionHistoryType('Quote')
        setActiveButton('Quote')
    }
    useEffect(() => {
        const newSortModel = Object.keys(sortOrder)
            .filter((key) => sortOrder[key] !== null)
            .map((key) => ({
                field: GetSortingColumnKey(key),
                sort: sortOrder[key],
            }))
        setSortModel(newSortModel)
    }, [sortOrder, setSortModel])

    useEffect(() => {
        const filters: GridFilterModel['items'] = []
        Object.keys(filterState).forEach((columnField) => {
            const filterValue =
                filterState[columnField as keyof typeof filterState]
            const uniqueId = uuidv4()
            if (filterValue.length > 0) {
                filters.push({
                    id: uniqueId,
                    columnField,
                    operatorValue: '__in',
                    value: filterValue,
                })
            }
        })
        setFilterModel({
            items: filters,
            linkOperator: GridLinkOperator.And,
        })
    }, [filterState])

    useEffect(() => {
        const filteredItems = filterModel.items
            .map((item) => {
                if (item.value === undefined) {
                    return {
                        ...item,
                        value: '',
                    }
                }
                return item
            })
            .filter((item) => ACTIVE_FILTER_COLUMNS.includes(item.columnField))
        let tempQueryOptions: Record<string, string> = {}
        filteredItems.forEach((item) => {
            if (item.columnField === 'order_date') {
                tempQueryOptions['date_range_after'] = item.value[0]
                tempQueryOptions['date_range_before'] = item.value[1]
            } else if (item.columnField === 'contact_name') {
                const updatedColumnName = GetColumnKey(
                    item.columnField,
                    item.operatorValue
                )
                const updatedValue: string[] = []
                item.value.forEach((value: string) => {
                    const contact = contactBilling.find(
                        (contact) => contact.full_name == value
                    )
                    if (contact) {
                        updatedValue.push(contact.contact_id?.toString() ?? '')
                    }
                })
                tempQueryOptions[updatedColumnName] =
                    updatedValue?.toString() ?? ''
            } else {
                const updatedColumnName = GetColumnKey(
                    item.columnField,
                    item.operatorValue
                )
                tempQueryOptions[updatedColumnName] = item.value
            }
        })
        setQueryOptions(tempQueryOptions)
    }, [filterModel])

    return (
        <Paper
            sx={{
                height: '100%',
                borderRadius: '4px',
                boxShadow: 'none',
                '& .column-group-customer-info': {
                    backgroundColor: 'rgba(188, 188, 188, 0.25)',
                    borderBottom: '2px solid rgba(148, 109, 255, 1)',
                    borderRight: '1px solid rgba(188, 188, 188, 0.5)',
                },
                '& .column-group-lead-info': {
                    backgroundColor: 'rgba(188, 188, 188, 0.25)',
                    borderBottom: '2px solid rgba(66, 70, 106, 1)',
                    borderLeft: '1px solid rgba(188, 188, 188, 0.5)',
                },
                '& .custom-header-style': {
                    backgroundColor: 'rgba(244, 244, 244, 1)',
                },
            }}
        >
            <DataGridPro
                sx={DataGridSX}
                components={{
                    Toolbar: (props) => (
                        <CustomHeader
                            {...props}
                            activeButton={activeButton}
                            onSalesOrderClick={handleSalesOrderClick}
                            onQuotesClick={handleQuotesClick}
                        />
                    ),
                    BaseSwitch: Checkbox,
                    Pagination: CustomFooter,
                }}
                getRowId={getRowIdCallback}
                loading={localLoading}
                rowHeight={42}
                headerHeight={42}
                columns={
                    activeButton === 'Sales Order'
                        ? SalesOrderTableColumns
                        : QuotesTableColumns
                }
                rows={tableRowData}
                rowsPerPageOptions={[10, 20, 50, 100]}
                pagination
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={handlePageChangeCallback}
                onPageSizeChange={handlePageSizeCallback}
                disableSelectionOnClick
                disableColumnMenu
                disableDensitySelector
                sortingMode="server"
                filterMode="server"
                sortModel={sortModel}
                filterModel={filterModel}
            />
        </Paper>
    )
}
