/**
 * @file CustomerContactCardV2.tsx
 * @description This file contains the logic to render the customer contact card.
 */
import {
    Card,
    CardContent,
    CardHeader,
    Collapse,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useCallback, useState } from 'react'
import { TContactV3 } from 'buddy-api'
import CustomText from 'components/CustomText'
import StarIcon from '@mui/icons-material/Star'
import { leadStatus } from 'components/opportunity'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CustomerContactCard } from './CustomerContactCard'
import { toTitleCase } from 'utils/stringformatter'
import { Status, useCallSession } from 'context/CallSessionProvider'
import formatters from 'utils/formatters'
import { debounce } from 'lodash'

/**
 * Props for the CustomerContactCardV2 component.
 */
type CustomerContactCardV2Props = {
    contact: TContactV3
}

/**
 * Returns the address string based on the city and state.
 * @param city - The city name.
 * @param state - The state name.
 * @returns The formatted address string or null if city and state are not provided.
 */
const getAddress = (
    city: string | null | undefined,
    state: string | null | undefined
): string | null => {
    if (city && state) {
        return city + ', ' + state
    } else if (city) {
        return city
    } else if (state) {
        return state
    } else {
        return null
    }
}

/**
 * Returns the subheader component for the CustomerContactCardV2.
 * @param contact - The contact object.
 * @param handleExpandClick - The function to handle expand click.
 * @returns The subheader component.
 */
const GetSubHeader = (contact: TContactV3, handleExpandClick: () => void) => {
    return (
        <Stack direction={'column'} padding={0} marginLeft={3}>
            <Typography
                fontFamily="Inter"
                color="#727492"
                fontWeight="400"
                fontSize="11px"
            >
                {contact.job_title ?? 'N/A'}
                <br />
                {getAddress(contact.city, contact.state) ?? 'N/A'}
                <br />
                Last Int:{' '}
                {formatters.formatDate(
                    contact.last_interaction ?? '',
                    'utc',
                    'America/Chicago'
                ).localDateWithTime ?? 'N/A'}
                <br />
                Follow-up Date:{' '}
                {formatters.formatDate(
                    contact.follow_up_date ?? '',
                    'utc',
                    'America/Chicago'
                ).localDate ?? 'N/A'}
                <br />
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '4px',
                    }}
                >
                    <Stack spacing={0.5} direction={'row'} flexWrap={'wrap'}>
                        {false && (
                            <span
                                style={{
                                    background: 'rgba(119, 77, 252, 1)',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    borderRadius: '4px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    fontWeight={700}
                                    fontFamily={'Inter'}
                                    fontSize={9}
                                    color={'rgba(255, 255, 255, 1)'}
                                    variant="button"
                                    sx={{
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    OPEN QOUTE
                                </Typography>
                            </span>
                        )}
                        {contact.new_location_flag && (
                            <span
                                style={{
                                    background: 'rgba(56, 176, 183, 1)',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    borderRadius: '4px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    fontWeight={700}
                                    fontFamily={'Inter'}
                                    fontSize={9}
                                    color={'rgba(255, 255, 255, 1)'}
                                    variant="button"
                                    sx={{
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    NEW LOCATION
                                </Typography>
                            </span>
                        )}
                    </Stack>
                    <IconButton
                        aria-label="Expand"
                        onClick={handleExpandClick}
                        sx={{
                            padding: 0,
                            margin: 0,
                        }}
                    >
                        <ExpandMoreIcon
                            sx={{
                                padding: 0,
                                margin: 0,
                                fontSize: 20,
                            }}
                        />
                    </IconButton>
                </span>
            </Typography>
        </Stack>
    )
}

/**
 * Returns the header component for the CustomerContactCardV2.
 * @param name - The contact name.
 * @param contact_id - The contact ID.
 * @param status - The contact status.
 * @param star - The star status.
 * @returns The header component.
 */
const GetHeader = (
    name: string,
    contact_id: string,
    status: string,
    star: boolean
) => {
    const { setContactStar } = useCallSession()
    const [starred, setStarred] = useState<boolean>(star)

    const handleStartClickCallback = useCallback(
        debounce(async (contact_id: string) => {
            setContactStar(contact_id)
                .then(() => {
                    setStarred(!starred)
                })
                .catch((error) => {
                    console.error(error)
                })
        }, 500),
        [starred, setStarred]
    )

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    margin: 0,
                    gap: 1,
                }}
            >
                <FormControlLabel
                    value={contact_id}
                    control={
                        <Radio
                            size="small"
                            sx={{
                                color: '#6200EA',
                                padding: 0,
                                paddingRight: 0.5,
                                margin: 0,
                            }}
                        />
                    }
                    label={
                        <Typography
                            fontFamily="Inter"
                            color="#000021"
                            fontWeight="500"
                            fontSize="13px"
                        >
                            {toTitleCase(name)}
                        </Typography>
                    }
                    sx={{
                        margin: 0,
                        padding: 0,
                    }}
                />
                {contact_id !== 'notListed' && (
                    <IconButton
                        disableRipple
                        onClick={() => handleStartClickCallback(contact_id)}
                        sx={{
                            alignItems: 'center',
                            margin: 0,
                            padding: 0,
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        <StarIcon
                            sx={{
                                fontSize: '16px',
                                color: starred ? '#FFB533' : '#FFF7D9',
                                stroke: '#FFB533',
                                strokeWidth: '1px',
                            }}
                        />
                    </IconButton>
                )}
            </Box>
            {contact_id !== 'notListed' && status && status !== Status.NEW && (
                <CustomText
                    backgroundColor={leadStatus[status.toLowerCase()]?.bgColor}
                    textColor={leadStatus[status.toLowerCase()]?.color}
                    displayText={status}
                />
            )}
        </Stack>
    )
}

/**
 * The CustomerContactCardV2 component.
 * @param contact - The contact object.
 * @returns The CustomerContactCardV2 component.
 */
export const CustomerContactCardV2: React.FC<CustomerContactCardV2Props> = ({
    contact,
}) => {
    const { activeContact } = useCallSession()
    const [expanded, setExpanded] = React.useState(false)
    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    return contact.contact_id !== 'notListed' ? (
        <Card
            elevation={0}
            sx={{
                width: '260px',
            }}
        >
            <CardHeader
                sx={{
                    bgcolor:
                        activeContact?.contact_id == contact.contact_id
                            ? '#F4ECFF'
                            : '',
                    borderBottom: '1px solid #DFDFDF',
                    paddingX: 1,
                    margin: 0,
                }}
                title={GetHeader(
                    contact.full_name
                        ? contact.full_name
                        : contact.first_name + ' ' + contact.last_name,
                    contact.contact_id?.toString() ?? '',
                    contact.status ?? '',
                    contact.star
                )}
                subheader={GetSubHeader(contact, handleExpandClick)}
            ></CardHeader>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <CustomerContactCard
                        customerName={
                            contact.full_name
                                ? contact.full_name
                                : contact.first_name +
                                      ' ' +
                                      contact.last_name ?? 'N/A'
                        }
                        title={contact.job_title ?? 'N/A'}
                        email={
                            contact.primary_email ??
                            contact.secondary_email ??
                            'N/A'
                        }
                        linkedIn={contact.linkedin_url ?? 'N/A'}
                        phoneNo={contact.phone ?? 'N/A'}
                        company_phone={contact.company_phone ?? 'N/A'}
                        contactType={
                            contact.netsuite_flag ? 'NetSuite' : 'Apollo'
                        }
                        clientSenority={contact.seniority ?? 'N/A'}
                        address={getAddress(contact.city, contact.state)}
                    ></CustomerContactCard>
                </CardContent>
            </Collapse>
        </Card>
    ) : (
        <Card elevation={0}>
            <CardHeader
                sx={{
                    bgcolor:
                        activeContact?.contact_id == contact.contact_id
                            ? '#F4ECFF'
                            : '',
                    padding: 1,
                    margin: 0,
                }}
                title={GetHeader(
                    contact.full_name
                        ? contact.full_name
                        : contact.first_name + ' ' + contact.last_name,
                    contact.contact_id?.toString() ?? '',
                    contact.status ?? '',
                    contact.star
                )}
            ></CardHeader>
        </Card>
    )
}
