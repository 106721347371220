/**
 * @file TalkTrackButtonSection.tsx
 * @description This file contains the logic to render the buttons for the talk track.
 */
import { Button, Stack, SxProps } from '@mui/material'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { TrackEntry } from 'types/CallSessionPage'

type TalkTrackButtonSectionProps = {
    sx?: SxProps
    button: TrackEntry[]
    setSelectedButton: (value: string) => void
    selectedButton: string
}
export const TalkTrackButtonSection: React.FC<TalkTrackButtonSectionProps> = ({
    button,
    setSelectedButton,
    selectedButton,
}) => {
    const handleClick = (e: any, item: TrackEntry) => {
        setSelectedButton(item.name)
    }
    useEffect(() => {
        if (button.length > 0) {
            setSelectedButton(button[1].name)
        }
    }, [button])
    return (
        <>
            <Stack
                direction={'row'}
                sx={{ width: '100%' }}
                paddingY={1}
                spacing={0.5}
            >
                {button &&
                    button.map((item, key: any) => {
                        return (
                            <Button
                                onClick={(e) => {
                                    handleClick(e, item)
                                }}
                                disableRipple
                                sx={{
                                    flexGrow: 1,
                                    padding: '1',
                                    background:
                                        selectedButton == item.name
                                            ? 'rgba(119, 77, 252, 1)'
                                            : 'rgba(255, 255, 255, 1)',
                                    color:
                                        selectedButton == item.name
                                            ? 'rgba(255, 255, 255, 1)'
                                            : 'rgba(20, 25, 40, 1)',
                                    border: '1.5px solid transparent',
                                    '&:hover': {
                                        background:
                                            selectedButton == item.name
                                                ? 'rgba(119, 77, 252, 1)'
                                                : 'rgba(255, 255, 255, 1)',
                                        color:
                                            selectedButton == item.name
                                                ? 'rgba(255, 255, 255, 1)'
                                                : 'rgba(20, 25, 40, 1)',
                                        border: '1.5px solid rgba(92, 40, 255, 1)',
                                    },
                                }}
                                key={'TalkTrackButton' + key}
                            >
                                {key + 1 + ': ' + item.name}
                            </Button>
                        )
                    })}
            </Stack>
        </>
    )
}
