import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const ToasterSuccessIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 18 18" fill="none" {...props}>
            <path
                d="M8.57109 11.9461C8.18789 12.3293 7.56211 12.3293 7.17891 11.9461L4.92891 9.69609C4.5457 9.31289 4.5457 8.68711 4.92891 8.30391C5.31211 7.9207 5.93789 7.9207 6.32109 8.30391L7.875 9.85781L11.6789 6.05391C12.0621 5.6707 12.6879 5.6707 13.0711 6.05391C13.4543 6.43711 13.4543 7.06289 13.0711 7.44609L8.57109 11.9461ZM18 9C18 13.9711 13.9711 18 9 18C4.02891 18 0 13.9711 0 9C0 4.02891 4.02891 0 9 0C13.9711 0 18 4.02891 18 9ZM9 1.6875C4.96055 1.6875 1.6875 4.96055 1.6875 9C1.6875 13.0395 4.96055 16.3125 9 16.3125C13.0395 16.3125 16.3125 13.0395 16.3125 9C16.3125 4.96055 13.0395 1.6875 9 1.6875Z"
                fill="white"
            />
        </SvgIcon>
    )
}

export default ToasterSuccessIcon
