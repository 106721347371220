/**
 * @file CallSessionSection2.tsx
 * @description
 */
import {
    Box,
    Button,
    ClickAwayListener,
    Divider,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material'
import { AccountStatus, useCallSession } from 'context/CallSessionProvider'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { CallSessionForm } from './CallSessionForm'
import { CallSessionDatePicker } from './CallSessionDatePicker'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import formatters from 'utils/formatters'

import FollowUpDateDataGrid from './FollowUpDateDataGrid'
import { MeetingEmailDialog } from './SendMail'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { set } from 'lodash'
import { DateTime } from 'luxon'
type CallSessionSection2Props = {}

export const CallSessionSection2: React.FC<CallSessionSection2Props> = () => {
    const { account } = useCallSession()
    const [expanded, setExpanded] = useState<boolean>(false)
    const [followUp, setFollowUp] = useState<string | null>('')
    const [openPopper, setOpenPopper] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClickAway = () => {
        setOpenPopper(false)
        setAnchorEl(null)
    }

    useEffect(() => {
        if (account) {
            const date =
                account.follow_up_date &&
                formatters.formatDate(
                    account.follow_up_date,
                    'utc',
                    'America/Chicago'
                ).localDate
            setFollowUp(date ?? 'N/A')
        }
    }, [account])

    const onTogglePopper = (event: React.MouseEvent<HTMLElement>) => {
        if (openPopper) {
            setOpenPopper(false)
            setAnchorEl(null)
        } else {
            setOpenPopper(true)
            setAnchorEl(event.currentTarget)
        }
    }

    return (
        <>
            <Stack
                direction={'column'}
                justifyContent={'center'}
                sx={{
                    padding: 0.5,
                    background: 'rgba(255, 255, 255, 1)',
                }}
            >
                <Stack
                    direction={'row'}
                    justifyContent={'end'}
                    alignItems={'center'}
                    spacing={1}
                >
                    <Button
                        onClick={() => setExpanded(!expanded)}
                        startIcon={<AddIcon></AddIcon>}
                        sx={{
                            '&:hover': {
                                bgcolor: 'transparent',
                            },
                            display: 'flex',
                            alignItems: 'center',
                            color: '#774DFC',
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: 12,
                        }}
                    >
                        Add Note
                    </Button>
                    <Divider flexItem orientation="vertical"></Divider>
                    <Typography
                        color={'rgba(114, 116, 146, 1)'}
                        fontWeight={500}
                        fontSize={11}
                        fontFamily={'Inter'}
                    >
                        Follow Up:
                    </Typography>
                    <Typography
                        onClick={onTogglePopper}
                        color="primary"
                        fontWeight={600}
                        fontSize={12}
                        fontFamily={'Inter'}
                        alignItems={'center'}
                        display={'flex'}
                        sx={{
                            cursor: 'pointer',
                        }}
                    >
                        <AccessAlarmIcon
                            sx={{
                                fontSize: 12,
                            }}
                        />
                        {followUp ?? 'N/A'}
                    </Typography>
                    <Divider flexItem orientation="vertical"></Divider>
                    <CallSessionDatePicker></CallSessionDatePicker>
                    {/* <Divider flexItem orientation="vertical"></Divider>
                    <Button
                        onClick={() => setDialogShow(true)}
                        startIcon={<MailOutlineIcon></MailOutlineIcon>}
                        sx={{
                            '&:hover': {
                                bgcolor: 'transparent',
                            },
                            display: 'flex',
                            alignItems: 'center',
                            color: '#774DFC',
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: 12,
                        }}
                    >
                        Send Mail
                    </Button> */}
                </Stack>
                <CallSessionForm isVisible={expanded}></CallSessionForm>
            </Stack>
            <Popper
                id={'follow_up_date_table'}
                open={openPopper}
                anchorEl={anchorEl}
                placement="bottom-start"
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 15],
                            },
                        },
                    ],
                }}
                sx={{
                    zIndex: 1000,
                    fontFamily: 'Inter !important',
                    padding: 1,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #e3e3e3',
                    borderRadius: '8px',
                    width: '400px',
                    height: '240px',
                }}
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Paper
                        sx={{
                            height: '100%',
                            borderRadius: '4px',
                            boxShadow: 'none',
                            '& .column-group-customer-info': {
                                backgroundColor: 'rgba(188, 188, 188, 0.25)',
                                borderBottom:
                                    '2px solid rgba(148, 109, 255, 1)',
                                borderRight:
                                    '1px solid rgba(188, 188, 188, 0.5)',
                            },
                            '& .column-group-lead-info': {
                                backgroundColor: 'rgba(188, 188, 188, 0.25)',
                                borderBottom: '2px solid rgba(66, 70, 106, 1)',
                                borderLeft:
                                    '1px solid rgba(188, 188, 188, 0.5)',
                            },
                            '& .custom-header-style': {
                                backgroundColor: 'rgba(244, 244, 244, 1)',
                            },
                            '& .custom-row': {
                                borderBottom: `0.1rem solid red`,
                            },
                        }}
                    >
                        <FollowUpDateDataGrid></FollowUpDateDataGrid>
                    </Paper>
                </ClickAwayListener>
            </Popper>
            {/* <MeetingEmailDialog
                open={dialogShow}
                onClose={() => setDialogShow(false)}
            ></MeetingEmailDialog> */}
        </>
    )
}
