/**
 * @file formatters.ts
 * @description This file contains the formatters for the application.
 * @description It contains the currency formatter, date formatter, and the difference in days formatter.
 * @description It also contains the locale and currency for the application.
 * @description It also contains the formatters object that contains all the formatters.WW
 */

export const LOCALE = navigator.language || 'en-US'
export const CURRENCY = 'USD'
import { DateTime } from 'luxon'
type IFormattersCurrencyOption = {
    currency?: 'EUR' | 'USD'
    isCompact?: boolean
    hasNoFractions?: boolean
}

const currency = (value = 0, options: IFormattersCurrencyOption = {}) => {
    const CurrencyFormatter = new Intl.NumberFormat(LOCALE, {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: options.currency ?? CURRENCY,
        notation: options.isCompact ? 'compact' : 'standard',
        maximumFractionDigits: options.hasNoFractions ? 0 : undefined,
    })

    return CurrencyFormatter.format(value)
}

const formatDate = (
    dateString: string,
    fromZone: string,
    toZone: string
): {
    isoDate: string | null
    localDate: string | null
    localDateWithTime: string | null
    localDateTextForm: string | null
    dateTime: DateTime | null
} => {
    try {
        let date = DateTime.fromISO(dateString, { zone: fromZone })
        if (!date.isValid) {
            return {
                isoDate: null,
                localDate: null,
                localDateWithTime: null,
                localDateTextForm: null,
                dateTime: null,
            }
        }
        const originalDay = date.day
        date = date.setZone(toZone)
        if (date.day !== originalDay) {
            date = date.plus({ days: date.day < originalDay ? 1 : -1 })
        }
        const isoDate = date.set({ hour: 10 }).toISO({
            suppressMilliseconds: true,
        })
        const localDate = date.toLocaleString(DateTime.DATE_SHORT)
        const localDateWithTime = date.toLocaleString(DateTime.DATETIME_SHORT)
        const localDateTextForm = date.toLocaleString(DateTime.DATE_FULL)

        return {
            isoDate,
            localDate,
            localDateWithTime,
            localDateTextForm,
            dateTime: date,
        }
    } catch (error) {
        console.error('An error occurred during date conversion:', error)
        return {
            isoDate: null,
            localDate: null,
            localDateWithTime: null,
            localDateTextForm: null,
            dateTime: null,
        }
    }
}

const getDifferenceInDays = (date: string): number | null => {
    const todayDate = DateTime.now().setZone('utc').startOf('day')
    const dateToCheck = DateTime.fromISO(date, { zone: 'utc' })
        .setZone('utc')
        .startOf('day')
    if (!dateToCheck.isValid) {
        console.error('Invalid follow-up date provided')
        return null
    }
    const diff = dateToCheck.diff(todayDate, 'days')
    const daysLeft = Math.round(diff.days)
    return daysLeft
}

const monthsSinceLastPurchase = (
    lastPurchasedDate: string
): number | string => {
    try {
        const lastPurchase = DateTime.fromISO(lastPurchasedDate)

        if (!lastPurchase.isValid) {
            throw new Error('Invalid date format')
        }

        const currentDate = DateTime.now()

        const yearsDifference = currentDate.year - lastPurchase.year
        const monthsDifference = currentDate.month - lastPurchase.month

        const totalMonths = yearsDifference * 12 + monthsDifference

        return totalMonths
    } catch (error) {
        return `N/A`
    }
}

const formatters = {
    currency,
    formatDate,
    getDifferenceInDays,
    monthsSinceLastPurchase,
}

export default formatters
