/**
 * @file CustomerContactsV2.tsx
 * @description This file contains the logic to render the customer contacts.
 */
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useCallSession } from 'context/CallSessionProvider'
import { CustomerContactCardV2 } from './CustomerContactCardV2'
import FilterListIcon from '@mui/icons-material/FilterList'

type CustomerContactV2Prop = {}

export const CustomerContactV2: React.FC<CustomerContactV2Prop> = () => {
    const {
        accountContacts,
        loading,
        activeContact,
        handleContactChangeCallback,
        setEmailCampaignOutreachCallback,
    } = useCallSession()

    const [searchTerm, setSearchTerm] = React.useState('')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const [filteredContacts, setFilteredContacts] = useState([])

    const [netsuiteSelect, setNetSuiteSelect] = useState(false)
    const [apolloSelect, setApolloSelect] = useState(false)
    const [allContactSelect, setAllContactSelect] = useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const contact = accountContacts.find(
            (contact) =>
                contact.contact_id?.toString() ==
                (event.target as HTMLInputElement).value
        )
        if (contact) {
            setEmailCampaignOutreachCallback(contact.email_campaign)
            handleContactChangeCallback(contact)
        }
    }

    useEffect(() => {
        const resp: any = accountContacts.filter(
            (contact) =>
                contact.full_name
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                contact.first_name
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                contact.last_name
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase())
        )
        setFilteredContacts(resp)
    }, [accountContacts, searchTerm])

    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSelectAll = (event: any) => {
        const newSelectAll = !allContactSelect
        setAllContactSelect(newSelectAll)
        setNetSuiteSelect(newSelectAll)
        setApolloSelect(newSelectAll)
    }

    const handleSelectNetSuite = (event: any) => {
        setNetSuiteSelect(!netsuiteSelect)
    }

    const handleSelectApollo = (event: any) => {
        setApolloSelect(!apolloSelect)
    }

    useEffect(() => {
        if (netsuiteSelect && apolloSelect) {
            setAllContactSelect(true)
        } else {
            setAllContactSelect(false)
        }
    }, [netsuiteSelect, apolloSelect])

    const filterRecords = filteredContacts.filter((contact: any) => {
        if (!netsuiteSelect && !apolloSelect && !allContactSelect) {
            return true
        }

        if (allContactSelect) return true

        if (netsuiteSelect && contact.netsuite_flag === true) {
            return true
        }

        if (apolloSelect && contact.netsuite_flag !== true) {
            return true
        }

        return false
    })

    const GetSubHeader = (heading: string, title: string) => {
        return (
            <Stack direction={'row'} gap={7}>
                <Typography
                    fontFamily="Inter"
                    color="#000021"
                    fontWeight="500"
                    fontSize="16px"
                >
                    {heading}
                </Typography>
                <Box m={-0.7}>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <FilterListIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        open={open}
                        slotProps={{
                            paper: {
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: '20ch',
                                },
                            },
                        }}
                    >
                        <MenuItem
                            onClick={(e) => handleSelectAll('Select All')}
                        >
                            <Checkbox
                                sx={{
                                    color: '#774DFC',
                                    '&.Mui-checked': {
                                        color: '#774DFC',
                                    },
                                }}
                                checked={allContactSelect}
                            />
                            {'All Contacts'}
                        </MenuItem>
                        <MenuItem onClick={(e) => handleSelectNetSuite('NetSuite')}>
                            <Checkbox
                                sx={{
                                    color: '#774DFC',
                                    '&.Mui-checked': {
                                        color: '#774DFC',
                                    },
                                }}
                                checked={netsuiteSelect}
                            />
                            {'NetSuite'}
                        </MenuItem>
                        <MenuItem onClick={(e) => handleSelectApollo('Apollo')}>
                            <Checkbox
                                sx={{
                                    color: '#774DFC',
                                    '&.Mui-checked': {
                                        color: '#774DFC',
                                    },
                                }}
                                checked={apolloSelect}
                            />
                            {'Apollo'}
                        </MenuItem>
                    </Menu>
                </Box>
            </Stack>
        )
    }

    return (
        <Card elevation={0}>
            <CardHeader title={GetSubHeader('Customer Contacts', 'title')} />
            <CardContent
                sx={{
                    padding: 0,
                    margin: 0,
                    '& .cm-bold': {
                        color: 'rgba(0, 0, 33, 1)',
                        fontWeight: 600,
                    },
                    height: '420px',
                    minHeight: '420px',
                    overflowY: 'auto',
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress size={25} />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: 0,
                            margin: 0,
                        }}
                    >
                        <TextField
                            variant="outlined"
                            placeholder="Search contacts"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{ marginBottom: 2, width: '100%' }}
                        />
                        <RadioGroup
                            aria-labelledby="Contact-radio-button"
                            name="Contact-radio-button"
                            onChange={handleChange}
                            value={activeContact?.contact_id}
                        >
                            {filterRecords &&
                                filterRecords?.map(
                                    (contact: any, index: any) => (
                                        <CustomerContactCardV2
                                            key={
                                                index + 'CustomerContactCardV2'
                                            }
                                            contact={contact}
                                        />
                                    )
                                )}
                        </RadioGroup>
                        {filterRecords.length === 0 && (
                            <Typography
                                variant="caption"
                                align="center"
                                sx={{ fontSize: '15px' }}
                            >
                                No Contacts Found
                            </Typography>
                        )}
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}
