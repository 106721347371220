import Cookies from 'js-cookie'
import { cloneDeep } from 'lodash'
import APIClient from './api'
import { IAPIParams, IAPIResponse, StorageKeys } from './types'

type TApiSignal = AbortSignal | null | undefined
export default class ApiRequest {
    public static get baseUrl() {
        const baseUrl: string = APIClient.baseUrl

        return baseUrl.replace(':tenantSlug', APIClient.getTenant())
    }

    public static get = <T>(
        path: string,
        signal: TApiSignal,
        params: IAPIParams = {}
    ): Promise<IAPIResponse<T>> => {
        const headers = ApiRequest.getHeaders({})
        const url = new URL(`${ApiRequest.baseUrl}${path}`)
        const clonedParams = cloneDeep(params)

        // If limit is set to null, load "all" possible results
        if (clonedParams.limit === null) {
            clonedParams.limit = 1_000_000
        }

        Object.entries(clonedParams).forEach(([key, value]) => {
            url.searchParams.set(key, value as string)
        })

        const response = fetch(url.toString(), {
            method: 'GET',
            headers,
            signal,
        })

        return ApiRequest.handleResponse<T>(response)
    }

    public static post = <T, B = unknown>(
        path: string,
        signal: TApiSignal,
        data: B
    ): Promise<IAPIResponse<T>> => {
        const headers = ApiRequest.getHeaders({})

        const response = fetch(`${ApiRequest.baseUrl}${path}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
            signal,
        })

        return ApiRequest.handleResponse<T>(response)
    }

    public static put = <T, B = unknown>(
        path: string,
        signal: TApiSignal,
        data: B
    ): Promise<IAPIResponse<T>> => {
        const headers = ApiRequest.getHeaders({})

        const response = fetch(`${ApiRequest.baseUrl}${path}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data),
            signal,
        })

        return ApiRequest.handleResponse<T>(response)
    }

    public static patch = <T, B = unknown>(
        path: string,
        signal: TApiSignal,
        data: B
    ): Promise<IAPIResponse<T>> => {
        const headers = ApiRequest.getHeaders({})

        const response = fetch(`${ApiRequest.baseUrl}${path}`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(data),
            signal,
        })

        return ApiRequest.handleResponse<T>(response)
    }

    public static delete = <T>(
        path: string,
        signal: TApiSignal
    ): Promise<IAPIResponse<T>> => {
        const headers = ApiRequest.getHeaders({})
        const response = fetch(`${ApiRequest.baseUrl}${path}`, {
            method: 'DELETE',
            headers: headers,
            signal,
        })

        return ApiRequest.handleResponse<T>(response)
    }

    public static getHeaders = (customHeaders: Record<string, unknown>) => {
        const defaults = { contentType: 'application/json' }
        const options = { ...defaults, ...customHeaders }
        const headers: Headers = new Headers()

        headers.set('Content-Type', options.contentType)
        const authenticatedBy = Cookies.get(StorageKeys.SSO)
        const mockUser = Cookies.get(StorageKeys.MOCK)
        if (authenticatedBy === 'keycloakSSO') {
            const token = Cookies.get(StorageKeys.KC_TOKEN)
            if (token) headers.set('Authorization', `KCToken ${token}`)
        } else {
            const token = Cookies.get(StorageKeys.TOKEN)
            if (token) headers.set('Authorization', `Token ${token}`)
        }

        if (mockUser) {
            headers.set('Mock', mockUser)
        }
        // const token = localStorage.getItem(StorageKeys.ACCESS_TOKEN)
        // if (token) headers.set('Authorization', `Token ${token}`)

        return headers
    }

    public static handleResponse = async <T>(
        responsePromise: Promise<Response>
    ): Promise<IAPIResponse<T>> => {
        const response = await responsePromise
        let body: T
        switch (response.headers.get('Content-Type')) {
            case 'application/json':
                body = (await response.json()) as T
                break
            case 'text/html':
            default:
                body = (await response.text()) as unknown as T
                break
        }
        switch (response.status) {
            case 400:
                APIClient.onHttp400?.(body)
                break
            case 401:
                APIClient.onHttp401?.(body)
                break
            case 403:
                APIClient.onHttp403?.(body)
                break
            case 404:
                APIClient.onHttp404?.(body)
                break
            case 500:
                APIClient.onHttp500?.(body)
                break
        }

        if (!response.ok) {
            return Promise.reject({ response, body })
        }

        return Promise.resolve({ response, body: body })
    }
}
