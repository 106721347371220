export enum StorageKeys {
    TOKEN = 'TOKEN',
    KC_TOKEN = 'KC_TOKEN',
    SSO = 'KC_SSO',
    LANGUAGE = 'LANGUAGE',
    TENANT_SLUG = 'TENANT_SLUG',
    USER_ID = 'USER_ID',
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken',
    EXPIRES_IN = 'expireIn',
    USER_NAME = 'USER_NAME',
    MOCK = 'Mock',
}
