/**
 * @file App.tsx
 * @description This file contains the main component of the application.
 * @description It wraps the application with the necessary providers and routers.
 * @description It also sets the default timezone and locale for the application.
 * @description It also sets the license key for the application.
 *
 */

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LicenseInfo } from '@mui/x-license-pro'
import 'antd/dist/antd.variable.min.css'
import config from 'config'
import { APIProvider } from 'context/APIProvider'
import { ShareStateProvider } from 'context/ShareStateProvider'
import { SnackBarAlertProvider } from 'context/SnackBarAlertProvider'
import { AuthenticatedUserProvider } from 'context/UserProvider'
import { Settings } from 'luxon'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import MainRouter from 'routes/MainRouter'

Settings.defaultZone = 'America/Chicago'
Settings.defaultLocale = 'en-US'
LicenseInfo.setLicenseKey(config.app.muiKey)

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <LocalizationProvider
                dateAdapter={AdapterLuxon}
                adapterLocale="en-US"
            >
                <APIProvider>
                    <SnackBarAlertProvider>
                        <AuthenticatedUserProvider>
                            <ShareStateProvider>
                                <MainRouter />
                            </ShareStateProvider>
                        </AuthenticatedUserProvider>
                    </SnackBarAlertProvider>
                </APIProvider>
            </LocalizationProvider>
        </BrowserRouter>
    )
}

export default App
