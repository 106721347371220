/**
 * @file TenantNotFound.tsx
 * @description This file contains the implementation of the TenantNotFound component.
 * @description It provides a page that is displayed when a requested page is not found.
 */

import { Box, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import buddyLogo from 'assets/images/buddy_logo.svg'
import React from 'react'

/**
 * Styled image component.
 */
const Img = styled('img')({
    width: '100px',
    height: '100px',
    marginBottom: '20px',
})

/**
 * Functional component that renders the TenantNotFound page.
 */
export const TenantNotFound: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Img src={buddyLogo} alt="Buddy Logo" />
            <Typography variant="h4" gutterBottom>
                404 Not Found
            </Typography>
            <Typography variant="body1" fontWeight="regular" gutterBottom>
                Requested Page Not Found.
            </Typography>
        </Box>
    )
}
