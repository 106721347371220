import Cookies from 'js-cookie'
import ApiRequest from '../ApiRequest'
import { IUser, StorageKeys, TMapsKey } from '../types'

export default class AuthAPI {
    public login = async (
        email: string,
        password: string,
        signal?: AbortSignal | null
    ) => {
        const result = await ApiRequest.post<IUser>('/auth/login', signal, {
            email,
            password,
        })
        const user = result.body
        Cookies.set(StorageKeys.TOKEN, user.token ?? '')
        Cookies.set(StorageKeys.USER_ID, user.id?.toString() ?? '')
        const fullName =
            (user.first_name ?? '') + ' ' + (user.last_name ?? '').trim()
        Cookies.set(
            StorageKeys.USER_NAME,
            user.username?.toString() ?? (fullName ? fullName : '')
        )
        return user
    }

    public me = async (signal?: AbortSignal | null) => {
        const url = `/auth/me`
        const result = await ApiRequest.get<IUser>(url, signal)
        return result.body
    }

    public mapskey = async (signal?: AbortSignal | null) => {
        const url = `/auth/mapskey`
        const result = await ApiRequest.get<TMapsKey>(url, signal)
        return result.body
    }

    public logout = async (signal?: AbortSignal | null) => {
        const result = await ApiRequest.post<IUser>('/auth/logout', signal, {})
        const response = result.body
        Cookies.remove(StorageKeys.TOKEN)
        Cookies.remove(StorageKeys.USER_ID)
        Cookies.remove(StorageKeys.USER_NAME)
        Cookies.remove(StorageKeys.MOCK)
        return response
    }
}
