/**
 * @file SideNav.tsx
 * @description This file contains the logic to render the side navigation bar of the application.
 * @description The side navigation bar is hidden by default and can be toggled by the user.
 */
import React from 'react'

import List, { ListTypeMap } from '@mui/material/List'
import { styled, useTheme } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

export const NavTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.popper}`]: {
        marginLeft: -10,
        position: 'relative',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'rgb(59, 64, 76)',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgb(59, 64, 76)',
        padding: '0.5rem',
    },
}))

export const NestedNavList = styled(List)(() => {
    return {
        marginLeft: '2rem',
        paddingLeft: '1rem',
        borderLeft: '1px solid rgba(189, 191, 191, 0.3)',
        borderImage:
            'linear-gradient(0deg, transparent 20px, rgba(189, 191, 191, 0.3) 20px) 2 / 2px',
        borderImageWidth: '0 0 0 2px',
    }
})

export const NavList = styled(List)(() => {
    const theme = useTheme()
    const textColor = 'white'
    return {
        width: 240,
        color: textColor,
        padding: '0.25rem 0.75rem',
        background: 'rgba(0, 0, 33, 1)',
        '& .MuiListSubheader-root': {
            fontSize: '0.875rem',
            color: textColor,
            fontWeight: 600,
        },
        '& .MuiListItemButton-root': {
            height: '3rem',
            marginTop: '0.25rem',
            marginBottom: '0.25rem',
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: 5,
            transition: 'background .6s ease',
            '&:hover': {
                background: 'rgba(242, 242, 243, 0.1)',
            },
            ':not(.MuiCollapse-entered &)': {
                width: '2.5rem',
                overflow: 'hidden',
            },
        },
        '& .MuiDivider-root': {
            margin: '1rem 0.25rem',
            borderColor: 'rgba(189, 191, 191, 0.3)',
            transition: 'width 1s .4s ease',
            ':not(.MuiCollapse-entered &)': {
                width: '2rem',
            },
        },
        '& .MuiListItemButton-root, & .MuiListSubheader-root': {
            '&.Mui-selected': {
                background: '#4F5664',
                color: theme.palette.primary.main,
            },
            '& .MuiListItemText-root .MuiTypography-root': {
                fontSize: '0.875rem',
                color: textColor,
                fontWeight: 600,
                opacity: 0,
                transition: 'opacity .4s ease',
                '.MuiCollapse-entered &': {
                    opacity: 1,
                },
            },
            '& .MuiListItemIcon-root': {
                minWidth: '47px',
                width: '47px',
            },
            '& .MuiListItemIcon-root .MuiSvgIcon-root': {
                width: 20,
                minWidth: 20,
                height: 20,
                color: textColor,
                marginRight: '1rem',
            },
            '&.Mui-selected > .MuiListItemText-root > .MuiTypography-root': {
                color: theme.palette.primary.main,
            },
            '&.Mui-selected > .MuiListItemIcon-root .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
            },
            '& .MuiListItemButton-root': {
                '&:hover': {
                    background: 'rgba(77, 82, 93)',
                },
            },
        },
    }
})
