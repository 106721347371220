/**
 * @file Saml.tsx
 * @description This file contains the implementation of the Saml component.
 */
import React, { useCallback, useEffect } from 'react'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loginPageURL } from 'routes/routes'
import { StorageKeys } from 'buddy-api'
import Cookies from 'js-cookie'
import { useAuthenticatedUser } from 'context/UserProvider'
import { BuddyAPIClient } from 'api'

export const Saml: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const token = searchParams.get('token')
    const user_id = searchParams.get('user_id')
    const [cookies, setCookie] = useCookies()
    const navigate = useNavigate()
    const { setUser } = useAuthenticatedUser()

    /**
     * Retrieves the user information from the API and sets it in the user context.
     */
    const retrieveUser = useCallback(async () => {
        try {
            const usr = await BuddyAPIClient?.auth.me()
            setUser(usr)
        } catch (e) {
            console.log('user could not me set')
            setUser(null)
        }
    }, [setUser])

    useEffect(() => {
        /**
         * Sets the authentication token and user ID in cookies and handles any errors.
         */
        const setToken = async () => {
            try {
                setCookie(StorageKeys.TOKEN, token, {
                    path: '/',
                    domain: window.location.hostname,
                    secure: true,
                    // httpOnly: true,
                    sameSite: 'strict',
                    expires: moment().add(30, 'day').toDate(),
                })
                Cookies.set(StorageKeys.USER_ID, user_id?.toString() ?? '')
                return Promise.resolve()
            } catch (err) {
                const { response } = err as { response: { status: number } }
                switch (response?.status) {
                    case 401:
                    case 403:
                        break
                    default:
                        navigate(loginPageURL(), {
                            replace: true,
                        })
                        break
                }
                return Promise.reject(err)
            }
        }
        setToken()
            .then((res) => {
                retrieveUser()
                    .then(() =>
                        navigate(loginPageURL(), {
                            replace: true,
                        })
                    )
                    .catch((err) => console.log(err))
            })
            .catch((err) => console.log(err))
    }, [navigate, setCookie, token])

    return <></>
}
