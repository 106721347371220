import React from 'react'
import { TalkTrackDataTable } from './TalkTrackDataTable'
import { TalkTrackDataList } from './TalkTrackDataList'

type Props = {
    type: string
    data?: any
}
export const RenderTalkTrackComponent: React.FC<Props> = ({ type, data }) => {
    switch (type) {
        case 'list':
            return (
                <TalkTrackDataList
                    data={(data?.value as string[]) ?? []}
                ></TalkTrackDataList>
            )
        case 'table':
            return (
                <TalkTrackDataTable
                    columnNames={data?.columns as string[]}
                    data={data?.value as unknown as { [key: string]: string }[]}
                ></TalkTrackDataTable>
            )
        case 'radio':
            return <div>--</div>
        default:
            return <div>No data available</div>
    }
}
