/**
 * @file PreferredVendors.tsx
 * @description This file contains the logic to render the preferred vendors.
 */
import { Collapse, Link, Popper, Tooltip, Typography } from '@mui/material'
import { Box, Stack, SxProps } from '@mui/system'
import { IPreferredVendors } from 'buddy-api/types/talktrack'
import { useCallSession } from 'context/CallSessionProvider'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { toTitleCase } from 'utils/stringformatter'
import LaunchIcon from '@mui/icons-material/Launch'
import { InitiativesList } from './InitiativesList'

type PreferredVendorsProps = {
    sx?: SxProps
    expanded: boolean
}

const printVendorList = (vendors: IPreferredVendors[]) => {
    const filteredVendors = vendors.filter(
        (vendor) => vendor.item__name || vendor.item_manufacturer
    )
    if (filteredVendors.length === 0) {
        return (
            <Typography
                fontFamily="Inter"
                fontSize={12}
                fontWeight={600}
                color="#000021"
            >
                N/A
            </Typography>
        )
    }

    return filteredVendors.map((text, index) => {
        return (
            <Typography
                fontFamily={'Inter'}
                fontSize={13}
                fontWeight={400}
                color={'rgba(114, 116, 146, 1)'}
                key={'PV' + index}
                sx={{
                    '.PV-bold': {
                        color: 'rgba(0, 0, 33, 1)',
                    },
                }}
            >
                {toTitleCase(text.item__name)}:{' '}
                <span className="PV-bold">
                    {toTitleCase(text.item_manufacturer)}
                </span>
            </Typography>
        )
    })
}

export const PreferredVendors: React.FC<PreferredVendorsProps> = ({
    expanded,
}) => {
    const { callSessionV2, account, fetchPreferredVendors } = useCallSession()
    const [vendors, setVendors] = useState<IPreferredVendors[]>([])
    useEffect(() => {
        // Fetch preferred vendors for all the items in the account(including expired leads)
        const idArr = account?.filtered_leads
            ? account.filtered_leads
                  .map((lead) => lead.item?.item_id)
                  .filter((item): item is number => item !== undefined)
            : []
        if (callSessionV2.account !== undefined && !isEmpty(idArr)) {
            fetchPreferredVendors(idArr.join(',')).then(
                (res: IPreferredVendors[]) => {
                    setVendors(res)
                }
            )
        }
    }, [callSessionV2.account, account])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const id = open ? 'oem-promotion-popper' : undefined

    return (
        <>
            <Stack
                width={'100%'}
                margin={1}
                padding={1}
                maxWidth={'236px'}
                overflow={'clip'}
                sx={{
                    maxHeight: !expanded ? '6rem' : 'max-content',
                }}
            >
                <Typography
                    fontSize={16}
                    fontFamily={'Inter'}
                    fontWeight={500}
                    color={'#000000'}
                >
                    Preferred Vendors
                </Typography>
                <Stack direction={'column'} spacing={1} sx={{ marginTop: 1 }}>
                    {printVendorList(vendors)}
                </Stack>
                {account?.campaign_target_list &&
                account?.campaign_target_list.length > 0 ? (
                    <Link
                        aria-describedby={id}
                        onClick={(event) =>
                            setAnchorEl(anchorEl ? null : event.currentTarget)
                        }
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: 2,
                            color: '#774DFC',
                            textDecoration: 'none',
                            textDecorationColor: '#774DFC',
                            '&:hover': {
                                textDecoration: 'none',
                                textDecorationColor: '#774DFC',
                                color: '#774DFC',
                            },
                        }}
                        fontWeight={400}
                        fontFamily="Inter"
                    >
                        See Eligible Initiatives
                        <LaunchIcon
                            sx={{
                                fontSize: 12,
                                marginLeft: 0.5,
                            }}
                        />
                    </Link>
                ) : (
                    <Tooltip
                        title={'No Initiatives Present for the Account'}
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -12],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Link
                            aria-describedby={id}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: 2,
                                color: 'rgba(114, 116, 146, 1)',
                                textDecoration: 'none',
                                textDecorationColor: 'rgba(114, 116, 146, 1)',
                                '&:hover': {
                                    textDecoration: 'none',
                                    textDecorationColor:
                                        'rgba(114, 116, 146, 1)',
                                    color: 'rgba(114, 116, 146, 1)',
                                },
                            }}
                            fontWeight={400}
                            fontFamily="Inter"
                        >
                            See Eligible Initiatives
                            <LaunchIcon
                                sx={{
                                    fontSize: 12,
                                    marginLeft: 0.5,
                                }}
                            />
                        </Link>
                    </Tooltip>
                )}
            </Stack>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                sx={{
                    maxWidth: 220,
                    width: '100%',
                    zIndex: 1500,
                    fontFamily: 'Inter !important',
                    padding: 1,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #e3e3e3',
                    borderRadius: '4px',
                }}
            >
                {account?.campaign_target_list.map((campaign, index) => {
                    return (
                        <InitiativesList
                            key={index + new Date().toISOString()}
                            title={campaign.campaign_definition.campaign_tag}
                            detail={campaign.campaign_definition.details}
                        ></InitiativesList>
                    )
                })}
            </Popper>
        </>
    )
}
