/**
 * @file useSortState.tsx
 * @description This file contains the implementation of the useSortState custom hook.
 * @description It provides a custom hook for managing sort state for datagrids.
 */

import { useState } from 'react'

/**
 * Custom hook for managing sortable columns and their sort order.
 *
 * @param initialState - The initial state of the sortable columns and their sort order.
 * @returns A tuple containing the current sort order and a function to toggle the sort order.
 */
export type TSortOrder = 'asc' | 'desc' | null
export type TSortState = Record<string, TSortOrder>

export default function useSortableColumns(initialState: TSortState) {
    const [sortOrder, setSortOrder] = useState<TSortState>(initialState)
    const toggleSort = (columnName: string) => {
        setSortOrder((prevState) => {
            const currentSort = prevState[columnName]
            let newSort: TSortOrder
            switch (currentSort) {
                case 'asc':
                    newSort = 'desc'
                    break
                case 'desc':
                    newSort = null
                    break
                default:
                    newSort = 'asc'
            }
            return Object.keys(prevState).reduce((acc, key) => {
                acc[key] = key === columnName ? newSort : null
                return acc
            }, {} as TSortState)
        })
    }

    return [sortOrder, toggleSort] as const
}
