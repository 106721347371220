/**
 * @file APIProvider.tsx
 * @description This file contains the implementation of the APIProvider component.
 * @description It provides a context for API-related functionality.
 */

import { configureApi } from 'api'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router'
import { loginPageURL, tenantNotFoundURL, logoutPageURL } from 'routes/routes'

/**
 * Context for API-related functionality.
 */
const APIContext = React.createContext({})

/**
 * Provider component for API-related functionality.
 */
const APIProvider: React.FC = ({ children }) => {
    const navigate = useNavigate()

    const redirectToLoginPage = () => {
        navigate(loginPageURL(), { replace: true })
    }
    const redirectToLogoutPage = () => {
        navigate(logoutPageURL(), {
            replace: true,
            state: {
                isSessionExpired: true,
            },
        })
    }

    const redirectToTenantNotFoundPage = () => navigate(tenantNotFoundURL())

    // configure the API with the redirect functions
    configureApi({
        redirectToLoginPage,
        redirectToLogoutPage,
        redirectToTenantNotFoundPage,
    })

    const contextValue = useMemo(() => {
        return {}
    }, [])

    return (
        <APIContext.Provider value={contextValue}>
            {children}
        </APIContext.Provider>
    )
}

export { APIProvider }
