/**
 * @file CustomerRecommendationPanel.tsx
 * @description This file contains the implementation of the CustomerRecommendationPanel component.
 * @description It provides a panel that displays customer recommendations.
 */

import { SxProps } from '@mui/material'
import { Stack } from '@mui/system'
import { IContactV2, ILead } from 'buddy-api'
import {
    ComplementaryProducts,
    RecommendationReason,
} from 'components/callsession'
import { RecommendedOpportunities } from 'components/callsession/RecommendedOpportunites'
import React from 'react'

/**
 * Props for the CustomerRecommendationPanel component.
 */
type CustomerRecommendationPanelProps = {
    sx?: SxProps
    opportunities: ILead[]
    contacts: IContactV2[]
    reasons: string | undefined
}

/**
 * A panel that displays customer recommendations.
 */
export const CustomerRecommendationPanel: React.FC<
    CustomerRecommendationPanelProps
> = ({ opportunities, contacts, reasons }) => {
    return (
        <Stack
            spacing={2}
            direction={'column'}
            width={'100%'}
            maxWidth={'390px'}
            minWidth={'365px'}
            paddingBottom={2}
        >
            <RecommendedOpportunities
                opportunities={opportunities}
            ></RecommendedOpportunities>
            <RecommendationReason reason={reasons}></RecommendationReason>
            <ComplementaryProducts
                opportunities={opportunities}
            ></ComplementaryProducts>
        </Stack>
    )
}
