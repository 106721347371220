/**
 * @file OutboundOpportunityPage.tsx
 * @description This file contains the implementation of the OutboundOpportunitiesPage component.
 * @description It provides a page that displays the top panel and table panel for outbound opportunities.
 */
import { Stack } from '@mui/material'
import React from 'react'
import { OpportunitiesProvider } from 'context/OpportunitiesProvider'
import OpportunityTopPanel from './OpportunityTopPanel'
import OpportunityTablePanel from './OpportunityTablePanel'

/**
 * Renders the OutboundOpportunities component.
 * This component is responsible for rendering the top panel and table panel for outbound opportunities.
 */
const OutboundOpportunities: React.FC = () => {
    return (
        <Stack direction="column" height="100vh">
            <OpportunityTopPanel></OpportunityTopPanel>
            <OpportunityTablePanel></OpportunityTablePanel>
        </Stack>
    )
}

/**
 * Renders the OutboundOpportunitiesPage component.
 * This component wraps the OutboundOpportunities component with the OpportunitiesProvider.
 */
const OutboundOpportunitiesPage: React.FC = () => {
    return (
        <OpportunitiesProvider>
            <OutboundOpportunities />
        </OpportunitiesProvider>
    )
}

export default OutboundOpportunitiesPage
