/**
 * @file MainRouter.tsx
 * @description This file contains the main router of the application.
 * @description It also contains the logic to redirect the user to the login page if the user is not authenticated.
 */
import config from 'config'
import SideBar from 'pages/BuddySideBar'
import CallSessionPage from 'pages/CallSessionPage'
import Logout from 'pages/LogoutPage'
import { Saml } from 'pages/Saml'
import { TenantNotFound } from 'pages/TenantNotFound'
import React, { useEffect } from 'react'
import { Outlet, Route, Routes, Navigate } from 'react-router'
import AuthenticatedRoute from './AuthenticatedRoute'
import RootRoute from './RootRoute'
import { useAuthenticatedUser } from 'context/UserProvider'
import LoginPage from 'pages/LoginPage'
import AdminLoginPage from 'pages/AdminLoginPage'
import { proactivesPageURL } from 'routes/routes'
import { useSearchParams } from 'react-router-dom'
import OutboundOpportunitiesPage from 'pages/OutboundOpportunityPage'

const RouteElement: React.FC = () => {
    useEffect(() => {
        document.title = 'Buddy'
    }, [])
    return (
        <AuthenticatedRoute>
            <SideBar />
        </AuthenticatedRoute>
    )
}

const MainRouter: React.FC = () => {
    const [searchParams] = useSearchParams()
    const { user } = useAuthenticatedUser()
    const nextUrl = searchParams.get('next') ?? proactivesPageURL()
    const getElementForLoginRoute = () => {
        if (user) {
            return <Navigate to={{ pathname: nextUrl }} />
        } else {
            return <LoginPage />
        }
    }
    const getElementForAdminLoginRoute = () => {
        if (user) {
            return <Navigate to={{ pathname: nextUrl }} />
        } else {
            return <AdminLoginPage />
        }
    }
    return (
        <Routes>
            <Route path={`${config.app.basePath}`} element={<Outlet />}>
                <Route index element={<RootRoute />} />
                {/* public routes */}
                <Route path="login" element={getElementForLoginRoute()} />
                <Route
                    path="admin/login"
                    element={getElementForAdminLoginRoute()}
                />
                {/* SAML authentication routes */}
                <Route path="auth" element={<Saml />} />
                {/* authenticated routes */}
                <Route path="agent" element={<RouteElement />}>
                    <Route index element={<OutboundOpportunitiesPage />} />
                    <Route
                        path="callsession/:customer_id"
                        element={<CallSessionPage />}
                    />
                    <Route
                        path="callsession/:customer_id/recommendation/:recommendationId"
                        element={<CallSessionPage />}
                    />
                    <Route path="logout" element={<Logout />} />
                </Route>

                {/* admin routes */}

                <Route path="not-found" element={<TenantNotFound />} />
            </Route>
            {/* catch other paths */}
            <Route path="*" element={<TenantNotFound />} />
        </Routes>
    )
}

export default MainRouter
