import { IAgent } from './user'

import { ILead } from './lead'
import { IContactV2 } from './contact'
import { ICallStatusOptionMapping } from './callstatus'
export type IAccount = {
    pk?: number
    account_id: number
    name: string
    agent?: IAgent
    parent?: string
    industry?: string
    industry_ns?: string
    start_date?: string
    address?: string
    zip?: string
    city?: string
    state?: string
    leads: ILead[]
    filtered_leads: ILead[]
    description?: string
    source?: string
    campaign_type?: string
    growth_propsect?: string
    dnb_employee_count?: number
    dnb_sales_revenue?: number
    modified_date?: string
    contacts?: IContactV2[]
    follow_up_date?: string
    last_transaction_date?: string
    last_purchase_date?: string
    first_purchase_date?: string
    shipping_address?: string
    state_shipping?: string
    website: null
    building_type?: string
    estimated_growth?: string | null
    annual_revenue?: number
    unsuccessful_attempts?: string
    last_unsuccessful_attempt?: string | null
    num_purchased_categories?: string
    true_support_flag?: boolean
    action: ICallStatusOptionMapping | null
    status: string
    profit?: number
    email_flag: boolean
    inbound: boolean
    managed_flag: boolean
    campaign_target_list: TCampaignTarget[]
    new_location_flag: boolean
}

export enum AccountStatus {
    All = 'all',
    Open = 'open',
    closed = 'closed',
    in_progress = 'in_progress',
    opportunity = 'opportunity',
    today = 'today',
    new = 'new',
}

export type IAccountCounts = {
    [key in AccountStatus]: number
}

export type TMapsKey = {
    data: string
}

export type TCampaignDefinition = {
    campaign_tag: string
    details: string
}

export type TCampaignTarget = {
    start_date: string
    end_date: string
    account_id: number
    campaign_definition: TCampaignDefinition
}
