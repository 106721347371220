import { ID, TContactV3 } from 'buddy-api/types'
import ApiMixin from '../ApiMixin'
import ApiRequest from 'buddy-api/ApiRequest'

export default class ContactsAPI extends ApiMixin<TContactV3> {
    public star = async (contact_id: ID, signal?: AbortSignal | null) => {
        const url = `/contacts/${contact_id}/star`
        const result = await ApiRequest.patch(url, signal, {})
        return result.body
    }

    public setFollowUpDate = async (
        contact_id: ID,
        account_id: ID,
        date: string,
        signal?: AbortSignal | null
    ): Promise<{ body: TContactV3; status: number }> => {
        const url = `/contacts/${contact_id}`
        const result = await ApiRequest.patch<TContactV3>(url, signal, {
            account_id: account_id,
            follow_up_date: date,
        })
        return { body: result.body, status: result.response.status }
    }

    public deleteFollowUpDate = async (
        contact_id: ID,
        date: string,
        signal?: AbortSignal | null
    ): Promise<{ body: TContactV3; status: number }> => {
        const url = `/contacts/${contact_id}?type=delete`
        const result = await ApiRequest.patch<TContactV3>(url, signal, {
            follow_up_date: date,
        })
        return { body: result.body, status: result.response.status }
    }

    public updateFollowUpDate = async (
        contact_id: ID,
        account_id: ID,
        oldDate: string,
        newDate: string,
        signal?: AbortSignal | null
    ): Promise<{ body: TContactV3; status: number }> => {
        const url = `/contacts/${contact_id}`
        const result = await ApiRequest.patch<TContactV3>(url, signal, {
            account_id: account_id,
            new_follow_up_date: newDate,
            old_follow_up_date: oldDate,
        })
        return { body: result.body, status: result.response.status }
    }
}
