/**
 * @file ActionButtons.tsx
 * @description This file contains the logic to render the action buttons for the opportunities page.
 * @description The action buttons are used to filter the opportunities based on their status.
 */
import { Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import React from 'react'
import { TStatus, useOpportunities } from 'context/OpportunitiesProvider'
import { AccountStatus } from 'buddy-api'

export type ActionButton = {
    value: string
    label: string
    textColor: string
}

interface ActionButtonsProps {}

const ActionButtonsList: ActionButton[] = [
    {
        value: 'today',
        label: `Today`,
        textColor: 'rgba(114, 116, 146, 1)',
    },
    {
        value: 'new',
        label: `New`,
        textColor: 'rgba(34, 126, 74, 1)',
    },

    {
        value: 'open',
        label: `Open `,
        textColor: 'rgba(1, 118, 143, 1)',
    },
    {
        value: 'in_progress',
        label: `In Progress `,
        textColor: 'rgba(207, 165, 14, 1)',
    },
    {
        value: 'opportunity',
        label: `Closed : Opportunity Created`,
        textColor: 'rgba(112, 138, 59, 1)',
    },
    {
        value: 'closed',
        label: `Rejected`,
        textColor: 'rgba(215, 48, 48, 1)',
    },
    {
        value: 'all',
        label: `All`,
        textColor: 'rgba(114, 116, 146, 1)',
    },
]

const ActionButtons: React.FC<ActionButtonsProps> = () => {
    const { counts, setStatusCallback, status } = useOpportunities()

    return (
        <Stack spacing={2} direction="row">
            {ActionButtonsList.map((ActionButton, key) => (
                <Button
                    disableRipple
                    disableTouchRipple
                    onClick={() =>
                        setStatusCallback(ActionButton.value as TStatus)
                    }
                    key={'OPAB' + key}
                    variant="outlined"
                    sx={{
                        color:
                            status === ActionButton.value
                                ? '#ffffff !important'
                                : ActionButton.textColor,
                        background:
                            status === ActionButton.value
                                ? 'linear-gradient(to bottom, rgba(24, 152, 255, 0.7) 0%, rgba(120, 77, 255, 0.7) 69.91%)'
                                : 'transparent',

                        border: status === ActionButton.value ? 0 : '1px solid',
                        borderColor: ActionButton.textColor,
                        '&:hover': {
                            border:
                                status === ActionButton.value ? 0 : '1px solid',
                            background: '',
                            borderColor:
                                status === ActionButton.value
                                    ? 'transparent'
                                    : ActionButton.textColor,
                        },
                    }}
                >
                    <Typography
                        key={'OPABT' + key}
                        id={`listing-tab-${ActionButton.label}`}
                        sx={{
                            color:
                                status === ActionButton.value
                                    ? '#ffffff !important'
                                    : ActionButton.textColor,
                            fontWeight: 600,
                            fontSize: '13px',
                        }}
                    >
                        {ActionButton.label}
                        {` (${counts?.[ActionButton.value as AccountStatus]})`}
                    </Typography>
                </Button>
            ))}
        </Stack>
    )
}

export default ActionButtons
