import * as env from 'env-var'

export enum APP_ENV_TYPE {
    demo = 'demo',
    development = 'development',
    local = 'local',
    prepoduction = 'preproduction',
    production = 'production',
}

export default {
    basePath: env.get('REACT_APP_BASE_PATH').default('').asString(),
    env: env.get('REACT_APP_ENV').default(APP_ENV_TYPE.local).asString(),
    isa: {
        appEnv: env.get('REACT_APP_ENV').default('').asString(),
    },
    muiKey: env.get('REACT_APP_MUI_KEY').default('').asString(),
}
