import React, { useState, useCallback, useEffect } from 'react'
import { SxProps, Stack, IconButton, Tooltip, Typography } from '@mui/material'
import { useCallSession } from 'context/CallSessionProvider'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import { DateRange, RangeKeyDict } from 'react-date-range'
import { DateTime } from 'luxon'

type CallSessionDateRangePickerProps = {
    sx?: SxProps
    columnField: string
}

type DateRangeState = {
    startDate: Date | undefined
    endDate: Date | undefined
    key: string
    color: string
}

export const CallSessionDateRangePicker: React.FC<
    CallSessionDateRangePickerProps
> = ({ columnField }) => {
    const { handleFilterStateChangeCallback } = useCallSession()

    const [dateFilter, setDateFilter] = useState<DateRangeState[]>([
        {
            startDate: undefined,
            endDate: undefined,
            color: '#774DFC',
            key: 'selection',
        },
    ])

    const handleDateChange = (ranges: RangeKeyDict) => {
        const { selection } = ranges
        setDateFilter([selection as DateRangeState])
    }

    useEffect(() => {
        if (
            dateFilter[0].startDate === undefined ||
            dateFilter[0].endDate === undefined
        ) {
            return
        }

        const startDate = DateTime.fromJSDate(dateFilter[0].startDate, {
            zone: 'utc',
        })
            .plus({ days: 1 })
            .toISODate()
        const endDate = DateTime.fromJSDate(dateFilter[0].endDate, {
            zone: 'utc',
        })
            .plus({ days: 1 })
            .toISODate()
        handleFilterStateChangeCallback(columnField, [startDate, endDate])
    }, [dateFilter, columnField, handleFilterStateChangeCallback])

    const handleFilterClear = useCallback(() => {
        setDateFilter([
            {
                startDate: undefined,
                endDate: undefined,
                color: '#774DFC',
                key: 'selection',
            },
        ])
        handleFilterStateChangeCallback(columnField, [])
    }, [columnField, handleFilterStateChangeCallback])

    return (
        <Stack direction={'column'} gap={1} justifyContent={'start'}>
            <Stack direction={'row'} justifyContent={'flex-end'}>
                <Tooltip title="Clear Filter">
                    <IconButton color="primary" onClick={handleFilterClear}>
                        <FilterListOffIcon
                            style={{
                                fontSize: '16px',
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Stack>
            <Typography
                fontFamily="Inter"
                fontSize={14}
                fontWeight={500}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                Select Date Range
            </Typography>
            <DateRange
                editableDateInputs={true}
                onChange={handleDateChange}
                moveRangeOnFirstSelection={false}
                ranges={dateFilter}
                rangeColors={['#774DFC']}
                showDateDisplay={false}
                maxDate={new Date()}
            />
        </Stack>
    )
}

export default CallSessionDateRangePicker
