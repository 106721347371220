/**
 * @file CallSessionSection3.tsx
 * @description
 */
import { Button, Divider, Stack, SxProps, Typography } from '@mui/material'
import { useCallSession } from 'context/CallSessionProvider'
import React, { useState } from 'react'

import { TalkTrackCustomerMetrics } from './TalkTrackCustomerMetrics'
import { TalkTrackEmailOutReach } from './TalkTrackEmailOutReach'
import { TalkTrackSection } from './TalkTrackSection'
import { CustomerSessionTopBar } from './CustomerSessionTopBar'
import CallSessionSection4 from './CallSessionSection4'
import { CustomerContactV2 } from './CustomerContactsV2'

type CallSessionSection3Props = {
    sx?: SxProps
}

export const CallSessionSection3: React.FC<CallSessionSection3Props> = ({
    sx,
}) => {
    const { emailCampaignOutreach } = useCallSession()

    return (
        <Stack
            display={'flex'}
            direction={'row'}
            spacing={1}
            sx={{
                width: '100%',
                minHeight: '735px',
                ...sx,
            }}
        >
            <Stack
                flexGrow={1}
                minWidth={'702px'}
                width={'702px'}
                direction={'column'}
                spacing={1}
            >
                <CustomerSessionTopBar></CustomerSessionTopBar>
                <TalkTrackSection></TalkTrackSection>
                <CallSessionSection4></CallSessionSection4>
            </Stack>
            <Stack
                direction={'column-reverse'}
                spacing={1}
                height={'100%'}
                width={'280px'}
                minWidth={'280px'}
            >
                <TalkTrackCustomerMetrics></TalkTrackCustomerMetrics>
                {emailCampaignOutreach && (
                    <TalkTrackEmailOutReach
                        data={emailCampaignOutreach}
                    ></TalkTrackEmailOutReach>
                )}
                <CustomerContactV2></CustomerContactV2>
            </Stack>
        </Stack>
    )
}
