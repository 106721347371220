import ApiMixin, { ListResponseWrapper } from '../ApiMixin'
import { IAccount, IAccountCounts, ID, ILeadList } from 'buddy-api/types'
import { omitBy } from 'lodash'
import ApiRequest from 'buddy-api/ApiRequest'
import { TStatus } from 'context/OpportunitiesProvider'

export type ListParams = {
    inbound?: boolean
    ordering?: string
    action_status?: string
    search_term?: string
    status?: TStatus
    recommendation__status_to__status_detail?: TStatus
    limit?: number
    offset?: number
    queryOptions?: {
        items: Array<{
            columnField: string
            operatorValue: string
            value?: string
        }>
        linkOperator?: string
    }
}

export type TAgents = {
    agent: ID
    agent_id: ID
    full_name: string | null
    email: string | null
    job_title: string | null
}
export default class AccountAPI extends ApiMixin<
    ILeadList,
    IAccount,
    IAccount
> {
    public list = async (params: ListParams, signal?: AbortSignal | null) => {
        const searchType = (operatorValue: string) => {
            if (operatorValue === 'contains') {
                return '__icontains'
            } else if (operatorValue === 'equals') {
                return ''
            } else {
                return operatorValue
            }
        }

        const replaceField = (
            field: string,
            replacements: Record<string, string>
        ) => {
            const prefix = field.startsWith('-') ? '-' : ''
            const baseField = field.replace('-', '')
            const replacement = replacements[baseField] || baseField

            return prefix + replacement
        }

        const fieldReplacements: Record<string, string> = {
            account_name: 'name',
            account_industry: 'industry',
            last_interaction_date: 'latest_interaction_date',
            creation_date: 'created_at',
            campaign_type: 'leads__campaign_type',
            rating: 'leads__propensity',
            account_ownership: 'managed_flag',
        }

        const createFilterKey = ({
            columnField,
            operatorValue,
        }: {
            columnField: string
            operatorValue: string
        }) => {
            const updatedColumnField =
                fieldReplacements[columnField] || columnField
            return updatedColumnField + searchType(operatorValue)
        }
        const queryParams: Record<string, string> = {}

        if (params.ordering) {
            params.ordering = replaceField(params.ordering, fieldReplacements)
        }

        params?.queryOptions?.items?.forEach((item) => {
            if (item.value !== undefined) {
                const value = String(item.value)
                // Special case for account_ownership, replace with managed with true and unmanaged with false
                if (item.columnField === 'account_ownership')
                    queryParams[createFilterKey(item)] = String(
                        value.toLowerCase() === 'managed'
                    )
                else queryParams[createFilterKey(item)] = value
            }
        })

        if (params?.queryOptions?.linkOperator) {
            queryParams[`${params.queryOptions.linkOperator}_filter`] = 'true'
        }

        const shouldOmit = (value: any, key: string): boolean => {
            if (key === 'queryOptions') return true
            if (value === undefined || value === null) return true
            if (typeof value === 'string' && value.trim() === '') return true
            return false
        }

        const cleanParams = omitBy(params, shouldOmit)

        const combinedParams = { ...cleanParams, ...queryParams }

        const result = await ApiRequest.get<ListResponseWrapper<ILeadList>>(
            this.endpoint,
            signal,
            combinedParams
        )
        return result.body
    }

    public counts = async (signal?: AbortSignal | null) => {
        const url = '/accounts/counts'
        const result = await ApiRequest.get<IAccountCounts>(url, signal)
        return result.body
    }
    public agents = async (account_id: ID, signal?: AbortSignal | null) => {
        const url = `/accounts/${account_id}/agents`
        const result = await ApiRequest.get<TAgents[]>(url, signal)
        return result.body
    }
}
