/**
 * @file TalkTrackDataList.tsx
 * @description This file contains the logic to render the list of data for the talk track.
 */
import React from 'react'
import { Typography } from '@mui/material'
import { SxProps } from '@mui/system'

type TalkTrackDataListProps = {
    sx?: SxProps
    data: string[]
}

export const TalkTrackDataList: React.FC<TalkTrackDataListProps> = ({
    sx,
    data,
}) => {
    return (
        <>
            {data.map((text, index) =>
                text.split('\n').map((line, lineIndex) => (
                    <Typography
                        key={'datalist-' + index + '-' + lineIndex}
                        sx={{
                            ...sx,
                            display: 'block',
                            fontFamily: 'Inter',
                            fontSize: 13,
                            fontWeight: 400,
                            marginTop: lineIndex === 0 ? 0 : 1,
                            marginLeft: line.startsWith('\u2022') ? 2 : 0,
                            textIndent: '-1em',
                            paddingLeft: line.startsWith('\u2022')
                                ? '3em'
                                : '1em',
                            marginBottom: line.startsWith('\u2022')
                                ? '2px'
                                : '',
                        }}
                    >
                        {line}
                    </Typography>
                ))
            )}
        </>
    )
}

export default TalkTrackDataList
