/**
 * @file RadioTypeFilter.tsx
 * @description This file contains the logic to filter using radio.
 */
import React, { useCallback } from 'react'
import {
    SxProps,
    Stack,
    TextField,
    FormGroup,
    FormControlLabel,
    Radio,
    IconButton,
    Divider,
    Tooltip,
    InputAdornment,
    RadioGroup,
} from '@mui/material'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import SearchIcon from '@mui/icons-material/Search'
import {
    TFilterOptions,
    TFilterValues,
    useOpportunities,
} from 'context/OpportunitiesProvider'
import { toTitleCase } from 'utils/stringformatter'
import { RenderStars } from './ListPageSearchComponent'
import { makeStyles } from '@mui/styles'

type CustomTableHeaderProps = {
    sx?: SxProps
    columnField: string
}
const useStyles = makeStyles({
    formControlLabel: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export const RadioTypeFilter: React.FC<CustomTableHeaderProps> = ({
    columnField,
}) => {
    const classes = useStyles()
    const { filterOptions, handleFilterStateChangeCallback, filterState } =
        useOpportunities()

    const handleFilterChange = (value: string) => {
        handleFilterStateChangeCallback(
            columnField as keyof TFilterValues,
            value
        )
    }

    const handleClearFilter = () => {
        handleFilterStateChangeCallback(columnField as keyof TFilterValues, '')
    }

    const handleTextInput = useCallback((e) => {
        if (e.target.id === 'input_filter') {
            handleFilterStateChangeCallback(
                columnField as keyof TFilterValues,
                e.target.value
            )
        }
    }, [])

    return (
        <Stack direction={'column'} gap={1} maxWidth={'230px'}>
            <Stack
                direction={'row-reverse'}
                justifyContent={'space-between'}
                alignItems={'center'}
                spacing={1}
            >
                <Tooltip title="Clear Filter">
                    <IconButton color="primary" onClick={handleClearFilter}>
                        <FilterListOffIcon
                            style={{
                                fontSize: '16px',
                            }}
                        />
                    </IconButton>
                </Tooltip>
                {(columnField === 'account_industry' ||
                    columnField === 'days_left_duration' ||
                    columnField === 'initiatives') && (
                    <TextField
                        value={filterState[columnField as keyof TFilterOptions]}
                        id="input_filter"
                        onChange={handleTextInput}
                        variant="outlined"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                    {
                                        borderWidth: '1px',
                                    },
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),
                            sx: {
                                paddingLeft: '5px',
                            },
                        }}
                        size="small"
                    />
                )}
            </Stack>
            <Divider />
            <FormGroup>
                <RadioGroup
                    value={
                        filterState[columnField as keyof TFilterOptions] || ''
                    }
                    onChange={(e) => handleFilterChange(e.target.value)}
                >
                    {filterOptions[columnField as keyof TFilterOptions].map(
                        (value, index) => {
                            return (
                                <FormControlLabel
                                    value={value}
                                    key={index + 'RBF'}
                                    control={<Radio size="small" />}
                                    label={
                                        columnField === 'propensity'
                                            ? RenderStars(value)
                                            : toTitleCase(value)
                                    }
                                    classes={{
                                        root: classes.formControlLabel,
                                        label: classes.label,
                                    }}
                                />
                            )
                        }
                    )}
                </RadioGroup>
            </FormGroup>
        </Stack>
    )
}
