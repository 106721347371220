import BuddyAPIClient, { StorageKeys } from 'buddy-api'
import config from 'config'
import Cookies from 'js-cookie'

class URLConfigurationError extends Error {
    constructor(url: string, base_path: string) {
        super(`URL ${url} does not match ${base_path}`)
        this.name = 'URLConfigurationError'
    }
}

const getTenant = () => {
    let { pathname } = window.location
    if (config.app.basePath && pathname.startsWith(config.app.basePath)) {
        pathname = pathname.slice(config.app.basePath.length)
    }

    // Remove leading '/'
    if (pathname.startsWith('/')) pathname = pathname.slice(1)
    const parts = pathname.split('/')
    const tenantSlug = parts[0]
    if (tenantSlug && tenantSlug !== ':tenantSlug') return tenantSlug

    return (
        sessionStorage.getItem(StorageKeys.TENANT_SLUG) ??
        config.api.defaultTenantSlug
    )
}

type ConfigType = {
    redirectToLoginPage: () => void
    redirectToLogoutPage: () => void
    redirectToErrorBoundary?: (e: string) => void
    redirectToTenantNotFoundPage: () => void
}

export const configureApi = (handlers: ConfigType): void => {
    BuddyAPIClient.configure({
        baseUrl: config.api.baseUrl,
        getTenant: getTenant,
        onHttp401: handlers.redirectToLogoutPage,
        onHttp403: handlers.redirectToLogoutPage,
        onHttp404: (body) => {
            if (
                (body as Record<string, unknown>)?.error_code ===
                'ERR_TENANT_NOT_FOUND'
            ) {
                handlers.redirectToTenantNotFoundPage()
            }
        },
        // onHttp500: redirectToErrorBoundary,
    })
}

class BuddyWebSocketClient {
    private static baseUrl: string = config.api.baseWsUrl
    public static getTenant: () => string = getTenant

    public static callStream(number: string): string {
        const tenant = BuddyWebSocketClient.getTenant()
        const urlWithTenant = BuddyWebSocketClient.baseUrl.replace(
            ':tenantSlug',
            tenant
        )
        const url = `${urlWithTenant}/twilio/call/transcript/${number}`
        return url
    }
}

export {
    BuddyAPIClient,
    BuddyWebSocketClient,
    URLConfigurationError,
    getTenant,
}
