/**
 * @file CustomGoogleMaps.tsx
 * @description This file contains the logic to render the custom google maps.
 */
import React, { useState, useCallback, useRef, useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

type TMapsStyle = {
    width: string
    height: string
}
type TCustomGoogleMapsProps = {
    sx?: TMapsStyle
    address: string
    API_KEY: string
}

const CustomGoogleMaps: React.FC<TCustomGoogleMapsProps> = ({
    sx,
    address,
    API_KEY,
}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY,
    })
    const mapRef = useRef<HTMLElement | null>(null)
    const [isFullscreen, setIsFullscreen] = useState(false)

    const [zoom, setZoom] = useState(15)
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [location, setLocation] = useState({
        lat: 0,
        lng: 0,
    })

    useEffect(() => {
        if (map) {
            if (isFullscreen) {
                const panorama = new google.maps.StreetViewPanorama(
                    map.getDiv().children[0] as HTMLElement,
                    {
                        position: location,
                        visible: true,
                    }
                )
                map.setStreetView(panorama)
            } else {
                if (map.getStreetView()) {
                    map.getStreetView().setVisible(false)
                }
                map.setCenter(location)
                map.setZoom(zoom)
            }
        }
    }, [isFullscreen, location, map, zoom])
    const handleFullscreenChange = useCallback(() => {
        const isCurrentlyFullscreen =
            document.fullscreenElement === mapRef.current?.children[0]
        setIsFullscreen(isCurrentlyFullscreen)
    }, [])

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreenChange)
        return () => {
            document.removeEventListener(
                'fullscreenchange',
                handleFullscreenChange
            )
        }
    }, [handleFullscreenChange])

    const geocodeAddress = useCallback(
        (address) => {
            const geocoder = new window.google.maps.Geocoder()
            geocoder.geocode({ address: address }, (results, status) => {
                if (
                    status === window.google.maps.GeocoderStatus.OK &&
                    results
                ) {
                    if (results[0]) {
                        const loc = results[0].geometry.location
                        setLocation({ lat: loc.lat(), lng: loc.lng() })
                        map && map.setCenter(loc)
                        map && map.setZoom(zoom)
                    } else {
                        console.error('No results found for the given address.')
                    }
                } else {
                    console.error('Geocoding failed: ' + status)
                }
            })
        },
        [map]
    )

    React.useEffect(() => {
        if (isLoaded) {
            geocodeAddress(address)
        }
    }, [isLoaded, geocodeAddress, address])

    const onLoad = useCallback(
        function callback(map) {
            const mapDiv = map.getDiv()
            if (mapDiv) {
                mapRef.current = mapDiv
                setMap(map)
                map.fitBounds(new window.google.maps.LatLngBounds(location))
            }
        },
        [location]
    )

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{ ...sx }}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {!isFullscreen && <Marker position={location} />}
        </GoogleMap>
    ) : (
        <>Loading...</>
    )
}

export default React.memo(CustomGoogleMaps)
