/**
 * @file ShareStateProvider.tsx
 * @description This file contains the implementation of the ShareStateProvider component and the useShareState hook.
 * @description It provides a context for sharing state across components.
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ILayout, ILayoutType } from '../buddy-api'
import { BuddyAPIClient } from 'api'
import { useAuthenticatedUser } from './UserProvider'

/**
 * Represents the context value for the ShareStateContext.
 */
type IShareStateContext = {
    isVisible: (type: ILayoutType) => boolean
    setLayout: (layout: ILayout[]) => void
    layout: ILayout[]
    mapsApiKey: string
}

/**
 * The ShareStateContext used for sharing state across components.
 */
const ShareStateContext = React.createContext<IShareStateContext>({
    isVisible: () => false,
    setLayout: () => undefined,
    layout: [],
    mapsApiKey: '',
} as IShareStateContext)

/**
 * The provider component that wraps the application and provides the ShareStateContext.
 */
export const ShareStateProvider: React.FC = ({ children }) => {
    const [layout, setLayout] = useState<ILayout[]>([])
    const [mapsApiKey, setMapsApiKey] = useState<string>('')
    const { user } = useAuthenticatedUser()
    /**
     * Checks if a layout type is visible.
     * @param type - The layout type to check.
     * @returns True if the layout type is visible, false otherwise.
     */
    const isVisible = useCallback(
        (type: ILayoutType) =>
            layout.find((l) => l.type === type)?.visible ?? false,
        [layout]
    )

    /**
     * Fetches the maps API key.
     */
    const fetchMapsApiKeyCallback = useCallback(async () => {
        if (mapsApiKey === '' && user) {
            const response = await BuddyAPIClient.auth.mapskey()
            setMapsApiKey(response.data)
        }
    }, [user, mapsApiKey, setMapsApiKey])

    useEffect(() => {
        fetchMapsApiKeyCallback()
    }, [user, mapsApiKey])

    /**
     * The memoized context value for the ShareStateContext.
     */
    const contextValue = useMemo(
        () => ({
            isVisible,
            setLayout,
            layout,
            mapsApiKey,
        }),
        [isVisible, layout, mapsApiKey, setLayout]
    )

    return (
        <ShareStateContext.Provider value={contextValue}>
            {children}
        </ShareStateContext.Provider>
    )
}

/**
 * A custom hook that returns the ShareStateContext.
 * @returns The ShareStateContext.
 * @throws An error if useShareState is used outside of a ShareStateProvider.
 */
export function useShareState() {
    const context = React.useContext(ShareStateContext)
    if (context === undefined) {
        throw new Error(
            'useShareState must be used within a ShareStateProvider'
        )
    }
    return context
}
