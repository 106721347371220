/**
 * @file CallSessionForm.tsx
 * @description This file contains the logic to render the call session form.(add note form)
 */
import LoadingButton from '@mui/lab/LoadingButton'
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Stack,
    TextField,
} from '@mui/material'
import { AccountStatus, useCallSession } from 'context/CallSessionProvider'
import React, { useCallback, useState } from 'react'
import { CallSessionFormProps } from 'types/CallSessionPage'

export const CallSessionForm: React.FC<CallSessionFormProps> = ({
    isVisible,
}) => {
    const {
        callSessionV2,
        updateSelections,
        handleSaveNoteCallback,
        saveSessionDisabled,
    } = useCallSession()
    if (!isVisible) return null

    const [isSaving, setIsSaving] = useState<boolean>(false)

    const handleSaveSession = () => {
        setIsSaving(true)
        handleSaveNoteCallback().finally(() => {
            setIsSaving(false)
        })
    }

    const handleChange = (event: any) => {
        const inputText = event.target.value
        if (inputText.length <= 120) {
            updateSelections({
                title: {
                    $set: event.target.value,
                },
            })
        }
    }
    return (
        <>
            <Box paddingY={1}>
                <Divider />
                <Stack padding={2} direction={'row-reverse'} spacing={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'end',
                            alignItems: 'start',
                        }}
                    >
                        {saveSessionDisabled && (
                            <LoadingButton
                                variant="contained"
                                disableFocusRipple
                                disableRipple
                                loading={isSaving}
                                onClick={handleSaveSession}
                                sx={{
                                    borderRadius: '100px',
                                    background:
                                        'linear-gradient(0deg, #1898FF 0%, #784DFF 69.91%)',
                                    paddingX: 3,
                                    color: 'white !important',
                                    fontWeight: 600,
                                    fontSize: 12,
                                }}
                            >
                                Save Note
                            </LoadingButton>
                        )}
                    </Box>

                    <Box
                        sx={{
                            width: '50%',
                        }}
                    >
                        <form>
                            <FormControl
                                fullWidth
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 2,
                                }}
                            >
                                <FormLabel
                                    htmlFor="title"
                                    sx={{
                                        flex: 1,
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                    }}
                                >
                                    Title:
                                </FormLabel>
                                <TextField
                                    autoComplete="off"
                                    value={callSessionV2?.title ?? ''}
                                    onChange={handleChange}
                                    id="title"
                                    name="title"
                                    sx={{ flex: 2 }}
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 2,
                                }}
                            >
                                <FormLabel
                                    htmlFor="notes"
                                    sx={{
                                        flex: 1,
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                    }}
                                >
                                    Notes:
                                </FormLabel>
                                <TextField
                                    autoComplete="off"
                                    value={callSessionV2?.notes ?? ''}
                                    onChange={(e) => {
                                        updateSelections({
                                            notes: {
                                                $set: e.target.value,
                                            },
                                        })
                                    }}
                                    name="notes"
                                    id="notes"
                                    multiline
                                    rows={4}
                                    sx={{ flex: 2 }}
                                />
                            </FormControl>
                        </form>
                    </Box>
                </Stack>
            </Box>
        </>
    )
}
