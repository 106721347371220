/**
 * @file CustomerContactCard.tsx
 * @description This file contains the logic to render the customer contact card.
 */
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Link,
    Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { CustomerContactCardProps } from 'types/CallSessionPage'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

import contact_icon from '../../assets/images/contact_circle.svg'

const GetSubHeader = (title: string, address: string | null) => {
    return (
        <Stack direction={'column'}>
            <Typography
                fontFamily="Inter"
                color="#000021"
                fontWeight="300"
                fontSize="12px"
            >
                {title}
            </Typography>
            <Typography
                fontFamily="Inter"
                color="#000021"
                fontWeight="300"
                fontSize="12px"
            >
                {address ?? 'N/A'}
            </Typography>
        </Stack>
    )
}
const GetHeader = (title: string) => {
    return (
        <Stack direction={'column'} gap={1}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    justifyContent: 'center',
                }}
            >
                <img src={contact_icon} alt="My SVG" />
                <img src={contact_icon} alt="My SVG" />
                <img src={contact_icon} alt="My SVG" />
                <img src={contact_icon} alt="My SVG" />
                <img src={contact_icon} alt="My SVG" />
                <img src={contact_icon} alt="My SVG" />
                <img src={contact_icon} alt="My SVG" />
            </Box>
            <Typography
                sx={{
                    fontFamily: 'Inter',
                    color: '#000000',
                    fontWeight: 600,
                    fontSize: 12,
                }}
            >
                {title}
            </Typography>
        </Stack>
    )
}
export const CustomerContactCard: React.FC<CustomerContactCardProps> = ({
    customerName,
    title,
    email,
    linkedIn,
    phoneNo,
    company_phone,
    contactType,
    clientSenority,
    address,
}) => {
    return (
        <Card
            elevation={0}
            sx={{
                backgroundColor: '#F8F8F8',
                border: '1px solid rgba(188, 188, 188, 0.3)',
            }}
        >
            <CardHeader
                title={GetHeader(customerName)}
                subheader={GetSubHeader(title, address)}
            ></CardHeader>
            <CardContent
                sx={{
                    padding: 0,
                    paddingX: 1,
                    margin: 0,
                }}
            >
                <Divider></Divider>
                <Stack spacing={1} paddingY={1} direction={'column'}>
                    <Stack
                        spacing={0.5}
                        direction={'row'}
                        alignItems={'center'}
                        color={'#727492'}
                    >
                        <EmailIcon fontSize="small" />
                        <Link
                            href={`mailto:${email}`}
                            target="_blank"
                            color={'rgba(119, 77, 252, 1)'}
                            sx={{
                                '&:hover': {
                                    color: 'rgba(119, 77, 252, 1)',
                                },
                            }}
                        >
                            <Typography
                                fontFamily={'Inter'}
                                fontSize={11}
                                fontWeight={500}
                            >
                                {email}
                            </Typography>
                        </Link>
                    </Stack>

                    <Stack
                        spacing={0.5}
                        direction={'row'}
                        alignItems={'center'}
                        color={'#727492'}
                    >
                        <LinkedInIcon fontSize="small" />
                        <Link
                            href={linkedIn === 'N/A' ? '#' : linkedIn}
                            target="_blank"
                            color={'rgba(119, 77, 252, 1)'}
                            sx={{
                                '&:hover': {
                                    color: 'rgba(119, 77, 252, 1)',
                                },
                            }}
                        >
                            <Typography
                                fontFamily={'Inter'}
                                fontSize={11}
                                fontWeight={500}
                            >
                                {linkedIn}
                            </Typography>
                        </Link>
                    </Stack>

                    <Stack
                        spacing={0.5}
                        direction={'row'}
                        alignItems={'center'}
                        color={'#727492'}
                    >
                        <LocalPhoneIcon fontSize="small" />
                        <Typography
                            fontFamily={'Inter'}
                            fontSize={11}
                            fontWeight={500}
                        >
                            Personal:{' '}
                            <Link
                                href={`tel:${phoneNo}`}
                                target="_blank"
                                color={'rgba(119, 77, 252, 1)'}
                                sx={{
                                    '&:hover': {
                                        color: 'rgba(119, 77, 252, 1)',
                                    },
                                }}
                            >
                                {phoneNo}
                            </Link>
                        </Typography>
                    </Stack>
                    <Stack
                        spacing={0.5}
                        direction={'row'}
                        alignItems={'center'}
                        color={'#727492'}
                    >
                        <LocalPhoneIcon fontSize="small" />
                        <Typography
                            fontFamily={'Inter'}
                            fontSize={11}
                            fontWeight={500}
                        >
                            Company:{' '}
                            <Link
                                href={`tel:${company_phone}`}
                                target="_blank"
                                color={'rgba(119, 77, 252, 1)'}
                                sx={{
                                    '&:hover': {
                                        color: 'rgba(119, 77, 252, 1)',
                                    },
                                }}
                            >
                                {company_phone}
                            </Link>
                        </Typography>
                    </Stack>
                </Stack>
                <Divider></Divider>
                <Typography
                    paddingTop={1}
                    fontFamily={'Inter'}
                    fontSize={11}
                    fontWeight={500}
                    color={'#727492'}
                >
                    Contact Type:&nbsp;
                    <span style={{ fontWeight: 'bold', color: 'black' }}>
                        {contactType}
                    </span>
                    <br />
                    Client Seniority:&nbsp;
                    <span style={{ fontWeight: 'bold', color: 'black' }}>
                        {clientSenority}
                    </span>
                </Typography>
            </CardContent>
        </Card>
    )
}
