import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import ToasterErrorIcon from 'components/icons/ToasterErrorIcon'
import ToasterSuccessIcon from 'components/icons/ToasterSuccessIcon'
import React, { useMemo, useState, useCallback, useEffect } from 'react'

type Severity = 'success' | 'error' | 'warning' | 'info'

interface ISnackBarAlertContext {
    showAlert: (severity: Severity, content: React.ReactNode) => void
    closeAlert: () => void
}

const SnackBarAlertContext = React.createContext<ISnackBarAlertContext>(
    {} as ISnackBarAlertContext
)

const SnackBarAlertProvider: React.FC = ({ children }) => {
    const [alertQueue, setAlertQueue] = useState<
        Array<{ severity: Severity; content: React.ReactNode }>
    >([])
    const [currentAlert, setCurrentAlert] = useState<{
        severity: Severity
        content: React.ReactNode
    } | null>(null)

    const showAlert = useCallback(
        (severity: Severity, content: React.ReactNode) => {
            setAlertQueue((prevQueue) => [...prevQueue, { severity, content }])
        },
        []
    )

    const closeAlert = useCallback(() => {
        setCurrentAlert(null)
    }, [])

    useEffect(() => {
        if (alertQueue.length > 0 && !currentAlert) {
            setCurrentAlert(alertQueue[0])
            setAlertQueue((prevQueue) => prevQueue.slice(1))
        }
    }, [alertQueue, currentAlert])

    const contextValue = useMemo(() => {
        return { showAlert, closeAlert }
    }, [showAlert, closeAlert])

    const color =
        currentAlert?.severity === 'error'
            ? '#DF2E2E'
            : currentAlert?.severity === 'success'
            ? '#13AE57'
            : '#009ACB'

    const icon =
        currentAlert?.severity === 'error' ? (
            <ToasterErrorIcon fontSize="inherit" />
        ) : currentAlert?.severity === 'success' ? (
            <ToasterSuccessIcon fontSize="inherit" />
        ) : (
            false
        )

    return (
        <SnackBarAlertContext.Provider value={contextValue}>
            <>
                {currentAlert && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        autoHideDuration={6000}
                        onClose={closeAlert}
                        TransitionProps={{ onExited: closeAlert }}
                    >
                        <Alert
                            onClose={closeAlert}
                            variant="filled"
                            icon={icon}
                            sx={{
                                paddingY: '9px',
                                paddingX: '12px',
                                boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.25)',
                                borderRadius: '3px',
                                backgroundColor: color,
                                fontWeight: 400,
                                fontSize: '13px',
                                lineHeight: '20px',
                                alignItems: 'center',
                                gap: '12px',
                                '& ul': {
                                    padding: 0,
                                    margin: 0,
                                    listStyleType: 'none',
                                },
                                '.MuiAlert-icon': {
                                    padding: 0,
                                    margin: 0,
                                },
                                '.MuiAlert-message': {
                                    padding: 0,
                                    margin: 0,
                                },
                                '.MuiAlert-action': {
                                    padding: 0,
                                    margin: 0,
                                },
                            }}
                        >
                            {currentAlert.content}
                        </Alert>
                    </Snackbar>
                )}
                {children}
            </>
        </SnackBarAlertContext.Provider>
    )
}

function useSnackBarAlert() {
    const context = React.useContext(SnackBarAlertContext)
    if (context === undefined) {
        throw new Error(
            'useSnackBarAlert must be used within a SnackBarAlertProvider'
        )
    }
    return context
}

export { SnackBarAlertProvider, useSnackBarAlert }
