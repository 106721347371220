/**
 * @file InteractionHistroyDataGrid.tsx
 * @description This file contains the logic to render the interaction history data grid.
 */
import { Checkbox, Paper, Typography } from '@mui/material'
import {
    DataGridPro,
    GridColDef,
    GridFilterModel,
    GridLinkOperator,
    GridSortModel,
} from '@mui/x-data-grid-pro'
import { ListResponseWrapper } from 'buddy-api/ApiMixin'
import { TGetInteractionHistory } from 'buddy-api/types/GetInteractionHistroyAPI'
import { CustomFooter } from 'components/opportunity'
import { useCallSession } from 'context/CallSessionProvider'
import _, { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { DataGridSX } from 'utils/datagridsx'
import { v4 as uuidv4 } from 'uuid'
import { CallSessionTableHeader } from './CallSessionTableHeader'
import useSortableColumns from 'hooks/useSortState'
import {
    GetColumnKey,
    GetSortingColumnKey,
    toOrderBy,
} from 'utils/FieldFormatter'
import formatters from 'utils/formatters'

const ACTIVE_FILTER_COLUMNS = [
    'date',
    'reason_code',
    'added_by',
    'netsuite_flag',
]

type InteractionHistroyDataGridProps = {}

type InteractionHistroyDataGridColumns = {
    date: string | null
    title: string | null
    notes: string | null
    reason_code: string | null
    added_by: string | null
    netsuite_flag: string | null
}

export const InteractionHistroyDataGrid: React.FC<
    InteractionHistroyDataGridProps
> = () => {
    const {
        fetchInteractionHistory,
        filterState,
        account,
        agents,
        callStatusOptions,
        loading,
    } = useCallSession()
    const [localLoading, setLocalLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [queryOptions, setQueryOptions] = useState<Record<string, string>>({})
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [],
        linkOperator: GridLinkOperator.And,
    })
    const [sortOrder, toggleSort] = useSortableColumns({
        date: null,
        title: null,
        notes: null,
        reason_code: null,
        added_by: null,
        netsuite_flag: null,
    })
    const [interactionHistoryRows, setInteractionHistoryRows] = useState<
        InteractionHistroyDataGridColumns[]
    >([])

    const setToggleSortCallback = useCallback(
        async (field: string) => {
            toggleSort(field)
        },
        [toggleSort]
    )

    const handlePageChangeCallback = useCallback(
        async (newPage: number) => {
            setPage(newPage)
        },
        [setPage]
    )
    const handlePageSizeCallback = useCallback(
        async (newPageSize: number) => {
            setPageSize(newPageSize)
        },
        [setPageSize]
    )

    const getRowIdCallback = useCallback(() => {
        return uuidv4()
    }, [])

    const tableColumns: GridColDef<InteractionHistroyDataGridColumns>[] = [
        {
            field: 'date',
            headerName: 'DATE ADDED',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'DATE ADDED'}
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'date'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            width: 160,
            minWidth: 160,
            maxWidth: 160,
            renderCell: (params) => (
                <Typography
                    sx={{
                        paddingY: 2,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: 1.5,
                    }}
                >
                    {
                        formatters.formatDate(
                            params.row.date ?? '',
                            'utc',
                            'America/Chicago'
                        ).localDateWithTime
                    }
                </Typography>
            ),
        },
        {
            field: 'title',
            headerName: 'TITLE',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'TITLE'}
                    isFilterEnabled={false}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'title'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            flex: 1,
            width: 220,
            minWidth: 220,
            renderCell: (params) => (
                <Typography
                    sx={{
                        paddingY: 2,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: 1.5,
                    }}
                >
                    {params.row.title}
                </Typography>
            ),
        },
        {
            field: 'notes',
            headerName: 'NOTES',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'NOTES'}
                    isFilterEnabled={false}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'notes'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            minWidth: 250,
            flex: 1,
            renderCell: (params) => (
                <Typography
                    sx={{
                        paddingY: 2,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: 1.5,
                    }}
                >
                    {params.row.notes}
                </Typography>
            ),
        },
        {
            field: 'reason_code',
            headerName: 'REASON CODE',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'REASON CODE'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'reason_code'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            width: 250,
            minWidth: 250,
            renderCell: (params) => {
                if (params.row.reason_code === 'New') {
                    return (
                        <Typography
                            sx={{
                                paddingY: 2,
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                lineHeight: 1.5,
                            }}
                        ></Typography>
                    )
                } else {
                    return (
                        <Typography
                            sx={{
                                paddingY: 2,
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                lineHeight: 1.5,
                            }}
                        >
                            {params.row.reason_code}
                        </Typography>
                    )
                }
            },
        },
        {
            field: 'added_by',
            headerName: 'ADDED BY',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'ADDED BY'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'added_by'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            width: 150,
            minWidth: 150,
            renderCell: (params) => (
                <Typography
                    sx={{
                        paddingY: 2,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: 1.5,
                    }}
                >
                    {params.row.added_by}
                </Typography>
            ),
        },
        {
            field: 'netsuite_flag',
            headerName: 'SOURCE',
            renderHeader: () => (
                <CallSessionTableHeader
                    headerName={'SOURCE'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'netsuite_flag'}
                ></CallSessionTableHeader>
            ),
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            width: 150,
            minWidth: 150,
            renderCell: (params) => (
                <Typography
                    sx={{
                        paddingY: 2,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: 1.5,
                    }}
                >
                    {params.row.netsuite_flag}
                </Typography>
            ),
        },
    ]

    const debouncedFetch = debounce(() => {
        if (account?.account_id && !loading) {
            setLocalLoading(true)
            setInteractionHistoryRows([])
            fetchInteractionHistory(
                pageSize,
                page,
                toOrderBy(sortModel),
                account?.account_id,
                queryOptions
            ).then((res: ListResponseWrapper<TGetInteractionHistory>) => {
                const formattedArr: InteractionHistroyDataGridColumns[] = []
                res.results.forEach((item) => {
                    formattedArr.push({
                        date: item.date,
                        title: item.title,
                        notes: item.notes,
                        reason_code: item.reason_code,
                        added_by: item.agent.full_name,
                        netsuite_flag: item.netsuite_flag
                            ? 'Netsuite'
                            : 'Buddy',
                    })
                })
                setLocalLoading(false)
                setRowCount(res.count ?? 0)
                setInteractionHistoryRows(formattedArr)
            })
        }
    }, 500)

    useEffect(() => {
        debouncedFetch()
        return () => {
            debouncedFetch.cancel()
        }
    }, [pageSize, page, sortModel, queryOptions, account?.account_id, loading])

    useEffect(() => {
        const newSortModel = Object.keys(sortOrder)
            .filter((key) => sortOrder[key] !== null)
            .map((key) => ({
                field: GetSortingColumnKey(key),
                sort: sortOrder[key],
            }))
        setSortModel(newSortModel)
    }, [sortOrder, setSortModel])

    useEffect(() => {
        const filters: GridFilterModel['items'] = []
        Object.keys(filterState).forEach((columnField) => {
            const filterValue =
                filterState[columnField as keyof typeof filterState]
            const uniqueId = uuidv4()
            if (filterValue.length > 0) {
                filters.push({
                    id: uniqueId,
                    columnField,
                    operatorValue: '__in',
                    value: filterValue,
                })
            }
        })
        setFilterModel({
            items: filters,
            linkOperator: GridLinkOperator.And,
        })
    }, [filterState])

    useEffect(() => {
        const filteredItems = filterModel.items
            .map((item) => {
                if (item.value === undefined) {
                    return {
                        ...item,
                        value: '',
                    }
                }
                return item
            })
            .filter((item) => ACTIVE_FILTER_COLUMNS.includes(item.columnField))
        let tempQueryOptions: Record<string, string> = {}
        filteredItems.forEach((item) => {
            if (item.columnField === 'date') {
                tempQueryOptions['date_range_after'] = item.value[0]
                tempQueryOptions['date_range_before'] = item.value[1]
            } else if (item.columnField === 'added_by') {
                const updatedColumnName = GetColumnKey(
                    item.columnField,
                    item.operatorValue
                )
                const updatedValue: string[] = []
                item.value.forEach((value: string) => {
                    const status = agents.find(
                        (status) => status.full_name === value
                    )
                    if (status) {
                        updatedValue.push(status.agent_id.toString())
                    }
                })
                tempQueryOptions[updatedColumnName] =
                    updatedValue?.toString() ?? ''
            } else if (item.columnField === 'reason_code') {
                const updatedColumnName = GetColumnKey(
                    item.columnField,
                    item.operatorValue
                )
                const updatedValue: string[] = []
                item.value.forEach((value: string) => {
                    const status = callStatusOptions.find(
                        (status) => status.code === value
                    )
                    if (status) {
                        updatedValue.push(status.id.toString())
                    }
                })
                tempQueryOptions[updatedColumnName] =
                    updatedValue?.toString() ?? ''
            } else if (item.columnField === 'netsuite_flag') {
                const updatedColumnName = GetColumnKey(item.columnField, '')
                const updatedValue: string[] = []

                item.value.forEach((value: string) => {
                    if (value == 'Netsuite') {
                        updatedValue.push('true')
                    } else {
                        updatedValue.push('false')
                    }
                })
                tempQueryOptions[updatedColumnName] =
                    item.value.length > 1 ? '' : updatedValue?.toString() ?? ''
            } else {
                const updatedColumnName = GetColumnKey(
                    item.columnField,
                    item.operatorValue
                )
                tempQueryOptions[updatedColumnName] = item.value
            }
        })
        setQueryOptions(tempQueryOptions)
    }, [filterModel])

    return (
        <Paper
            sx={{
                height: '100%',
                borderRadius: '4px',
                boxShadow: 'none',
                '& .column-group-customer-info': {
                    backgroundColor: 'rgba(188, 188, 188, 0.25)',
                    borderBottom: '2px solid rgba(148, 109, 255, 1)',
                    borderRight: '1px solid rgba(188, 188, 188, 0.5)',
                },
                '& .column-group-lead-info': {
                    backgroundColor: 'rgba(188, 188, 188, 0.25)',
                    borderBottom: '2px solid rgba(66, 70, 106, 1)',
                    borderLeft: '1px solid rgba(188, 188, 188, 0.5)',
                },
                '& .custom-header-style': {
                    backgroundColor: 'rgba(244, 244, 244, 1)',
                },
            }}
        >
            <DataGridPro
                sx={DataGridSX}
                components={{
                    BaseSwitch: Checkbox,
                    Pagination: CustomFooter,
                }}
                getRowId={getRowIdCallback}
                loading={localLoading}
                headerHeight={38}
                getRowHeight={() => 'auto'}
                columns={tableColumns}
                rows={interactionHistoryRows}
                rowsPerPageOptions={[10, 20, 50, 100]}
                pagination
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={handlePageChangeCallback}
                onPageSizeChange={handlePageSizeCallback}
                disableSelectionOnClick
                disableColumnMenu
                disableDensitySelector
                sortingMode="server"
                filterMode="server"
                sortModel={sortModel}
                filterModel={filterModel}
            />
        </Paper>
    )
}
