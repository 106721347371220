/**
 * @file routes.ts
 * @description This file contains the URL paths for the different pages in the application.
 */

import config from 'config'

export function callSessionPageURL(
    customerId: number | string,
    recommendationId?: number | string
) {
    if (recommendationId !== undefined) {
        return `${config.app.basePath}/agent/callsession/${customerId}/recommendation/${recommendationId}`
    }
    return `${config.app.basePath}/agent/callsession/${customerId}`
}

export function proactivesPageURL() {
    return `${config.app.basePath}/agent`
}

export function loginPageURL() {
    return `${config.app.basePath}/login`
}
export function adminLoginPageURL() {
    return `${config.app.basePath}/admin/login`
}

export function authPageURL() {
    return `${config.app.basePath}/auth`
}

export function tenantNotFoundURL() {
    return `${config.app.basePath}/not-found`
}

export function logoutPageURL() {
    return `${config.app.basePath}/agent/logout`
}
