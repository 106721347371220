import { ID } from './general'

export enum FilterOperator {
    EQ = 'eq',
    GT = 'gt',
    LT = 'lt',
    GTE = 'gte',
    LTE = 'lte',
    IN = 'in',
}

export enum LogicOperator {
    AND = 'AND',
    OR = 'OR',
}
export type IBasicFilter = {
    attribute_id: ID
    operator: FilterOperator
    type: 'BasicFilter'
    value: string | number
}

export type IComplexFilter = {
    filters: Array<IBasicFilter | IComplexFilter>
    operator: LogicOperator
    type: 'ComplexFilter'
}
