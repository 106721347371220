import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const ToasterErrorIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 18 18" fill="none" {...props}>
            <path
                d="M18 9C18 13.9711 13.9711 18 9 18C4.02891 18 0 13.9711 0 9C0 4.02891 4.02891 0 9 0C13.9711 0 18 4.02891 18 9ZM9 1.6875C4.96055 1.6875 1.6875 4.96055 1.6875 9C1.6875 13.0395 4.96055 16.3125 9 16.3125C13.0395 16.3125 16.3125 13.0395 16.3125 9C16.3125 4.96055 13.0395 1.6875 9 1.6875Z"
                fill="white"
            />
            <path
                d="M13.0014 11.8863L9.58266 5.54056C9.34034 5.09065 8.69482 5.09065 8.4523 5.54056L5.03375 11.8863C4.98113 11.984 4.95475 12.0936 4.95719 12.2045C4.95962 12.3154 4.99078 12.4238 5.04762 12.5191C5.10447 12.6143 5.18506 12.6932 5.28152 12.7481C5.37799 12.803 5.48704 12.8319 5.59802 12.832H12.4361C12.5472 12.832 12.6564 12.8033 12.753 12.7485C12.8496 12.6937 12.9303 12.6148 12.9873 12.5195C13.0442 12.4242 13.0755 12.3157 13.078 12.2048C13.0804 12.0938 13.0541 11.984 13.0014 11.8863ZM9.01758 11.8496C8.93823 11.8496 8.86066 11.8261 8.79469 11.782C8.72871 11.738 8.67729 11.6753 8.64693 11.6021C8.61656 11.5288 8.60861 11.4482 8.62409 11.3704C8.63958 11.2926 8.67779 11.2211 8.73389 11.1651C8.79 11.109 8.86149 11.0708 8.93931 11.0553C9.01713 11.0398 9.0978 11.0478 9.17111 11.0781C9.24442 11.1085 9.30707 11.1599 9.35116 11.2258C9.39524 11.2918 9.41877 11.3693 9.41877 11.4486C9.41877 11.5013 9.40839 11.5534 9.38823 11.6021C9.36807 11.6507 9.33852 11.6949 9.30126 11.7321C9.26401 11.7694 9.21978 11.7989 9.17111 11.8191C9.12243 11.8392 9.07026 11.8496 9.01758 11.8496ZM9.45327 7.8166L9.33813 10.2627C9.33813 10.3477 9.30432 10.4293 9.24413 10.4895C9.18393 10.5497 9.1023 10.5835 9.01718 10.5835C8.93205 10.5835 8.85042 10.5497 8.79023 10.4895C8.73004 10.4293 8.69622 10.3477 8.69622 10.2627L8.58108 7.8176C8.57849 7.75918 8.5877 7.70083 8.60816 7.64603C8.62861 7.59124 8.65989 7.54112 8.70014 7.49867C8.74039 7.45622 8.78877 7.4223 8.84241 7.39893C8.89605 7.37557 8.95385 7.36324 9.01236 7.36268H9.01657C9.07548 7.36265 9.13379 7.37455 9.18797 7.39766C9.24215 7.42077 9.29109 7.45461 9.33182 7.49714C9.37256 7.53968 9.40425 7.59002 9.42499 7.64513C9.44572 7.70024 9.45507 7.75898 9.45247 7.81781L9.45327 7.8166Z"
                fill="white"
            />
        </SvgIcon>
    )
}

export default ToasterErrorIcon
