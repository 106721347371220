/**
 * @file TalkTrackEmailOutReach.tsx
 * @description This file contains the logic to display the email outreach section of the call session page.
 */
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Collapse,
    IconButton,
    SxProps,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useCallSession } from 'context/CallSessionProvider'
import ReactHtmlParser from 'react-html-parser'

type TalkTrackEmailOutReachProps = {
    sx?: SxProps
    data: string | null
}
export const TalkTrackEmailOutReach: React.FC<TalkTrackEmailOutReachProps> = ({
    data,
}) => {
    const [expanded, setExpanded] = useState<boolean>(true)
    const { account, loading } = useCallSession()
    return (
        <Card
            elevation={0}
            sx={{
                width: '100%',
                minWidth: '260px',
                flexShrink: '1',
                height: '100%',
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            }}
        >
            <CardHeader
                titleTypographyProps={{
                    sx: {
                        fontFamily: 'Inter',
                        color: '#000021',
                        fontWeight: 500,
                        fontSize: 16,
                    },
                }}
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'background.paper',
                }}
                title="Email Outreach"
                action={
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon
                            sx={{
                                transform: expanded
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)',
                            }}
                        />
                    </IconButton>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent
                    sx={{
                        paddingTop: 0,
                        '& .cm-bold': {
                            color: 'rgba(0, 0, 33, 1)',
                            fontWeight: 600,
                        },
                    }}
                >
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <CircularProgress size={25} />
                        </Box>
                    ) : (
                        ReactHtmlParser(data ?? '')
                    )}
                </CardContent>
            </Collapse>
        </Card>
    )
}
