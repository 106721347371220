/**
 * @file CallSessionTableHeader.tsx
 * @description This file contains the logic to render the call session table header.
 */
import React, { useCallback, useState } from 'react'
import {
    Typography,
    IconButton,
    SxProps,
    Stack,
    Popper,
    ClickAwayListener,
} from '@mui/material'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import FilterListIcon from '@mui/icons-material/FilterList'
import { TSortState } from 'hooks/useSortState'
import { CallSessionFilter } from './CallSessionFilter'
import CallSessionDateRangePicker from './CallSessionDateRangePicker'

type CallSessionTableHeaderProps = {
    sx?: SxProps
    headerName: string | React.ReactFragment
    isSortEnabled: boolean
    isFilterEnabled: boolean
    sortOrder: TSortState
    toggleSort: (columnName: string) => void
    columnField: string
}

function getSortIcon(sortOrder: TSortState, columnField: string) {
    const order = sortOrder?.[columnField]
    if (order === 'desc') {
        return <KeyboardArrowDownIcon style={{ fontSize: '16px' }} />
    } else if (order === 'asc') {
        return <KeyboardArrowUpIcon style={{ fontSize: '16px' }} />
    } else {
        return <UnfoldMoreIcon style={{ fontSize: '16px' }} />
    }
}

export const CallSessionTableHeader: React.FC<CallSessionTableHeaderProps> = ({
    sx,
    headerName,
    columnField,
    isSortEnabled,
    isFilterEnabled,
    sortOrder,
    toggleSort,
}) => {
    const [openPopper, setOpenPopper] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const onTogglePopper = (currentAnchorEl: any) => {
        if (openPopper) {
            setOpenPopper(false)
            setAnchorEl(null)
        } else {
            setOpenPopper(true)
            setAnchorEl(currentAnchorEl)
        }
    }

    const handleSortClick = useCallback(
        (event) => {
            toggleSort(columnField)
        },
        [toggleSort, columnField]
    )

    const handleFilterClick = useCallback(
        (event) => {
            onTogglePopper(event.currentTarget)
        },
        [onTogglePopper]
    )

    const handleClickAway = () => {
        setOpenPopper(false)
        setAnchorEl(null)
    }

    return (
        <>
            <Stack direction={'row'} alignItems="center" height="100%">
                <Typography fontFamily={'Inter'} fontSize={10} fontWeight={700}>
                    {headerName}
                </Typography>
                <>
                    {isSortEnabled && (
                        <IconButton
                            onClick={handleSortClick}
                            sx={{
                                padding: 0,
                                margin: 0,
                            }}
                        >
                            {getSortIcon(sortOrder, columnField)}
                        </IconButton>
                    )}
                    {isFilterEnabled && (
                        <IconButton
                            aria-describedby={columnField}
                            onClick={handleFilterClick}
                            sx={{
                                padding: 0,
                                margin: 0,
                            }}
                        >
                            <FilterListIcon
                                style={{
                                    fontSize: '16px',
                                }}
                            />
                        </IconButton>
                    )}
                </>
            </Stack>

            <Popper
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 15],
                            },
                        },
                    ],
                }}
                sx={{
                    zIndex: 1000,
                    fontFamily: 'Inter !important',
                    padding: 1,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #e3e3e3',
                    borderRadius: '8px',
                    width: 'auto',
                    height: 'auto',
                }}
                id={columnField}
                open={openPopper}
                anchorEl={anchorEl}
                placement="bottom"
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                        {columnField === 'date' ||
                        columnField === 'order_date' ? (
                            <CallSessionDateRangePicker
                                columnField={columnField}
                            ></CallSessionDateRangePicker>
                        ) : (
                            <CallSessionFilter columnField={columnField} />
                        )}
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
