/**
 * @file TalkTrackDataTable.tsx
 * @description This file contains the logic to display the talk track data table.
 */

import React, { useEffect, useState } from 'react'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { Box, SxProps } from '@mui/system'
import ReactHtmlParser from 'react-html-parser'
import { Typography } from '@mui/material'

type TalkTrackDataTableProps = {
    sx?: SxProps
    data: { [key: string]: string }[]
    columnNames: string[]
}

type IRow = {
    id: number
    [key: string]: string | number | JSX.Element
}

export const TalkTrackDataTable: React.FC<TalkTrackDataTableProps> = ({
    columnNames,
    data,
}) => {
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [rows, setRows] = useState<IRow[]>([])

    useEffect(() => {
        const newColumns = columnNames.map((columnName) => {
            if (columnName === 'Category') {
                return {
                    field: columnName,
                    renderHeader: () => (
                        <Typography
                            className="custom-header-css"
                            fontFamily={'Inter'}
                            fontSize={10}
                            fontWeight={700}
                        >
                            {columnName.toUpperCase()}
                        </Typography>
                    ),
                    flex: 1,
                    maxWidth: 200,
                    sortable: false,
                    renderCell: (params: any) => (
                        <Box
                            className="custom-css"
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                fontFamily="Inter"
                                fontSize={13}
                                fontWeight={400}
                                sx={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                    maxWidth: '100%',
                                }}
                            >
                                {ReactHtmlParser(params.value)}
                            </Typography>
                        </Box>
                    ),
                }
            }
            if (columnName === 'Case') {
                return {
                    field: columnName,
                    renderHeader: () => (
                        <Typography
                            className="custom-header-css"
                            fontFamily={'Inter'}
                            fontSize={10}
                            fontWeight={700}
                        >
                            {columnName.toUpperCase()}
                        </Typography>
                    ),
                    flex: 1,
                    minWidth: 300,
                    sortable: false,
                    renderCell: (params: any) => (
                        <Box
                            className="custom-css"
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                fontFamily="Inter"
                                fontSize={13}
                                fontWeight={400}
                                sx={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                    maxWidth: '100%',
                                }}
                            >
                                {ReactHtmlParser(params.value)}
                            </Typography>
                        </Box>
                    ),
                }
            }
            if (
                columnName === 'Competitor' ||
                columnName === 'Industry' ||
                columnName === 'Facility'
            ) {
                return {
                    field: columnName,
                    renderHeader: () => (
                        <Typography
                            className="custom-header-css"
                            fontFamily={'Inter'}
                            fontSize={10}
                            fontWeight={700}
                        >
                            {columnName.toUpperCase()}
                        </Typography>
                    ),
                    flex: 1,
                    minWidth: columnName === 'Competitor' ? 120 : 120,
                    maxWidth: columnName === 'Competitor' ? 120 : 200,
                    sortable: false,
                    renderCell: (params: any) => (
                        <Box
                            className="custom-css"
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                fontFamily="Inter"
                                fontSize={13}
                                fontWeight={400}
                                sx={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                    maxWidth: '100%',
                                }}
                            >
                                {params.value}
                            </Typography>
                        </Box>
                    ),
                }
            } else {
                return {
                    field: columnName,
                    renderHeader: () => (
                        <Typography
                            className="custom-header-css"
                            fontFamily={'Inter'}
                            fontSize={10}
                            fontWeight={700}
                        >
                            {columnName.toUpperCase()}
                        </Typography>
                    ),
                    flex: 1, // The column will grow with the DataGrid size
                    minWidth: 180, // Minimum width of the column
                    sortable: false,
                    renderCell: (params: any) => (
                        <Box
                            className="custom-css"
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                fontFamily="Inter"
                                fontSize={13}
                                fontWeight={400}
                                sx={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                    maxWidth: '100%',
                                }}
                            >
                                {params.value}
                            </Typography>
                        </Box>
                    ),
                }
            }
        })
        setColumns(newColumns)
    }, [columnNames])

    useEffect(() => {
        const processedRows = data.map((item, index) => ({
            id: index,
            ...item,
        }))
        setRows(processedRows)
    }, [data])
    return (
        <DataGridPro
            sx={{
                '& .custom-css': {
                    paddingY: 2,
                },
                '& .custom-header-css': {
                    padding: 0,
                    margin: 0,
                },
            }}
            rows={rows}
            autoHeight
            getRowHeight={() => 'auto'}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableColumnFilter
            disableColumnMenu
            disableChildrenSorting
            disableColumnReorder={true}
            disableColumnResize={true}
            disableSelectionOnClick={true}
        />
    )
}
