export type IStyling = {
    config: {
        info_color?: string
        alert_color?: string
        primary_color?: string
        success_color?: string
        warning_color?: string
        secondary_color?: string
        background_color?: string
        panel_background_opacity?: string
        input_border_radius?: string
        button_border_radius?: string
        select_border_radius?: string
        datepicker_border_radius?: string
    }
}

export type ILayoutType =
    | 'open_opportunities'
    | 'closed_opportunities'
    | 'search'
    | 'appointments'
    | 'appointment_email'
    | 'twilio_call'
    | 'customer_contacts'
    | 'customer_xray'
    | 'gamification'
    | 'monitoring'
    | 'isa'
    | 'recommended_opportunities'
    | 'customer_specific_tips'
    | 'battle_cards'
    | 'inbound_opportunities'
    | 'outbound_opportunities'
    | 'outcomes_and_reasons'

export type ILayout = {
    id: number
    type: ILayoutType
    visible: boolean
}

export type IGroup = {
    id: number
    name: string
    slug: string
    order: number
    color: string
    pinned: boolean
}

export enum IColumnFormatterType {
    None = 'none',
    StarRating = 'star-rating',
}
type IBaseColumnFormatter = {
    type: IColumnFormatterType
}

type IColumnFormatter = IBaseColumnFormatter &
    (
        | {
              type: IColumnFormatterType.None
          }
        | {
              type: IColumnFormatterType.StarRating
          }
    )

export type IColumn = {
    id: number
    name: string
    slug: string
    attribute: boolean
    datatype: string
    order: number
    group: number | null
    visible: boolean
    sortable: boolean
    filterable: boolean
    pinned: boolean
    formatter: IColumnFormatter
    formatters: IColumnFormatterType[]
}
