/**
 * @file ComplementaryProducts.tsx
 * @description This file contains the logic to render the complementary products.
 */
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import React, { useState } from 'react'
import { RecommendedOpportunitiesProps } from 'types/CallSessionPage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useCallSession } from 'context/CallSessionProvider'
import { toTitleCase } from 'utils/stringformatter'

export const ComplementaryProducts: React.FC<
    RecommendedOpportunitiesProps
> = () => {
    const [expanded, setExpanded] = useState<boolean>(true)
    const { callSessionV2, account, loading } = useCallSession()

    return (
        <Card
            elevation={0}
            sx={{
                maxHeight: '352px',
                height: '100%',
                overflowY: 'scroll',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            }}
        >
            <CardHeader
                titleTypographyProps={{
                    sx: {
                        fontFamily: 'Inter',
                        color: '#000021',
                        fontWeight: 500,
                        fontSize: 16,
                    },
                }}
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'background.paper',
                }}
                title="Complementary Products"
                action={
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon
                            sx={{
                                transform: expanded
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)',
                            }}
                        />
                    </IconButton>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent
                    sx={{
                        paddingTop: 0,
                    }}
                >
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '150px',
                            }}
                        >
                            <CircularProgress size={25} />
                        </Box>
                    ) : (
                        <List
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: 0,
                                flexWrap: 'wrap',
                            }}
                        >
                            {callSessionV2.lead &&
                                account?.filtered_leads
                                    .find(
                                        (item) =>
                                            item.lead_id == callSessionV2.lead
                                    )
                                    ?.complementary?.split('<BREAK>')
                                    .map((text, index) => (
                                        <ListItem
                                            key={index}
                                            sx={{
                                                width: 'auto',
                                                padding: 0,
                                            }}
                                        >
                                            <ListItemIcon>
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        fontSize: '10px',
                                                        color: 'rgba(0, 0, 33, 1)',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={toTitleCase(text)}
                                                sx={{ marginX: 1 }}
                                            />
                                        </ListItem>
                                    ))}
                        </List>
                    )}
                </CardContent>
            </Collapse>
        </Card>
    )
}
