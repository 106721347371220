/**
 * @file utils.tsx
 * @description This file contains the implementation of the pagination button components.
 * @description It provides button components for navigating to the next, previous, first, and last page.
 */

import FirstPageIcon from '@mui/icons-material/FirstPage'
import LastPageIcon from '@mui/icons-material/LastPage'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Tooltip } from '@mui/material'

/**
 * Renders a button component for navigating to the next page.
 */
export const NextButton: React.FC = () => {
    return (
        <Tooltip title="Next Page">
            <NavigateNextIcon fontSize="small" />
        </Tooltip>
    )
}

/**
 * Renders a button component for navigating to the previous page.
 */
export const PreviousButton: React.FC = () => {
    return (
        <Tooltip title="Previous Page">
            <NavigateBeforeIcon fontSize="small" />
        </Tooltip>
    )
}

/**
 * Renders a button component for navigating to the first page.
 */
export const FirstButton: React.FC = () => {
    return (
        <Tooltip title="First Page">
            <FirstPageIcon fontSize="small" />
        </Tooltip>
    )
}

/**
 * Renders a button component for navigating to the last page.
 */
export const LastButton: React.FC = () => {
    return (
        <Tooltip title="Last Page">
            <LastPageIcon fontSize="small" />
        </Tooltip>
    )
}
