/**
 * @file RecommendedOpportunities.tsx
 * @description This file contains the logic to render the recommended opportunities.
 */
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    SxProps,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { RecommendedOpportunitiesProps } from 'types/CallSessionPage'
import StarIcon from '@mui/icons-material/Star'
import { useCallSession } from 'context/CallSessionProvider'
import { toTitleCase } from 'utils/stringformatter'
import formatters from 'utils/formatters'

const commonBoxStyles: SxProps = {
    paddingX: 1,
    paddingY: 0.5,
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
}

const getBackgroundStyle = (isSelected: boolean) => ({
    background: isSelected
        ? 'linear-gradient(0deg, rgba(24, 152, 255, 0.15), rgba(120, 77, 255, 0.15))'
        : undefined,
})

const getLabelStyle = (isSelected: boolean) => ({
    fontWeight: isSelected ? 700 : 400,
    color: isSelected ? 'transparent' : 'black',
    background: isSelected
        ? 'linear-gradient(0deg, rgba(24, 152, 255, 1), rgba(120, 77, 255, 1))'
        : 'transparent',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: isSelected ? 'transparent' : 'inherit',
    display: 'inline',
})

const commonChipStyles = {
    borderRadius: '3px',
    color: 'white',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 600,
}

// Function to determine the background color based on the propensity value
const getBackgroundColor = (propensity: number) => {
    if (propensity > 4) {
        return 'rgba(25, 122, 86, 1)'
    } else if (propensity > 3) {
        return 'rgba(29, 193, 131, 1)'
    } else if (propensity > 2) {
        return 'rgb(237, 108, 2, 1)'
    } else {
        return 'rgba(227, 59, 59, 1)'
    }
}

export const RecommendedOpportunities: React.FC<
    RecommendedOpportunitiesProps
> = ({ opportunities }) => {
    const { callSessionV2, updateSelections, loading } = useCallSession()
    useEffect(() => {
        updateSelections({
            lead: {
                $set: opportunities[0]?.lead_id,
            },
        })
    }, [opportunities])

    const handleRounding = (propensity: string) => {
        let rating = parseFloat(propensity)
        const decimal = rating - Math.floor(rating)
        if (decimal < 0.5) {
            rating = Math.floor(rating)
        } else {
            rating = Math.ceil(rating)
        }
        return rating
    }

    return (
        <Card
            elevation={0}
            sx={{
                maxHeight: '352px',
                height: '100%',
                overflowY: 'scroll',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            }}
        >
            <CardHeader
                title="Recommended Opportunities"
                titleTypographyProps={{
                    sx: {
                        fontFamily: 'Inter',
                        color: '#000021',
                        fontWeight: 500,
                        fontSize: 16,
                    },
                }}
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'background.paper',
                }}
            ></CardHeader>
            <CardContent
                sx={{
                    paddingTop: 0,
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px',
                        }}
                    >
                        <CircularProgress size={25} />
                    </Box>
                ) : (
                    callSessionV2.lead && (
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={callSessionV2.lead}
                            onChange={(e) => {
                                updateSelections({
                                    lead: {
                                        $set: e.target.value,
                                    },
                                })
                            }}
                        >
                            {opportunities &&
                                callSessionV2.lead != '' &&
                                opportunities.map((opportunity, index) => {
                                    return (
                                        <Box
                                            key={'rob' + index}
                                            sx={{
                                                ...commonBoxStyles,
                                                ...getBackgroundStyle(
                                                    callSessionV2.lead ==
                                                        opportunity?.lead_id
                                                ),
                                                '& .MuiFormControlLabel-label':
                                                    getLabelStyle(
                                                        callSessionV2.lead ==
                                                            opportunity?.lead_id
                                                    ),
                                            }}
                                        >
                                            <FormControlLabel
                                                value={opportunity?.lead_id}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#00002199',
                                                            '&.Mui-checked': {
                                                                color: '#774DFC',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={LabelWithDate(
                                                    toTitleCase(
                                                        opportunity?.item?.name.toLocaleLowerCase()
                                                    ),
                                                    opportunity?.last_purchase_date
                                                )}
                                            />
                                            <Chip
                                                sx={{
                                                    ...commonChipStyles,
                                                    background:
                                                        getBackgroundColor(
                                                            opportunity?.propensity
                                                        ),
                                                }}
                                                label={handleRounding(
                                                    opportunity?.propensity.toString()
                                                )}
                                                icon={
                                                    <StarIcon
                                                        sx={{ fill: 'white' }}
                                                    />
                                                }
                                            />
                                        </Box>
                                    )
                                })}
                        </RadioGroup>
                    )
                )}
            </CardContent>
        </Card>
    )
}

const LabelWithDate = (label: string, date: string | undefined) => {
    let dateObj = null

    if (date) {
        dateObj = formatters.formatDate(date, 'utc', 'America/Chicago').dateTime
    }
    const formattedDate = dateObj ? dateObj.toFormat('MMMM dd, yyyy') : null
    return (
        <Stack direction="column">
            {label}
            <Typography
                fontFamily="Inter"
                fontWeight="500"
                fontSize="10px"
                color="#727492"
            >
                {formattedDate
                    ? 'Last Purchase Date: ' + formattedDate
                    : 'Never Purchased Category'}
            </Typography>
        </Stack>
    )
}
