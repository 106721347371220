/**
 * @file SessionOutcome.tsx
 * @description This file contains the logic to render the session outcome buttons.
 */
import { Button, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useCallSession } from 'context/CallSessionProvider'
import { isEmpty } from 'lodash'
import React from 'react'
import { OutcomeSectionProps } from 'types/CallSessionPage'
import CloseIcon from '@mui/icons-material/Close'
import { ICallOption } from 'buddy-api/types/callstatus'

export const SessionOutcome: React.FC<OutcomeSectionProps> = () => {
    const { sessionOutcomes, callSessionV2, updateSelections } =
        useCallSession()

    const handleClick = (e: any, button: ICallOption) => {
        if (callSessionV2.sessionOutcome?.id == button.id) {
            updateSelections({
                sessionOutcome: {
                    $set: null,
                },
                reason: {
                    $set: null,
                },
            })
            return
        }
        updateSelections({
            sessionOutcome: {
                $set: {
                    id: button.id,
                    name: button.name,
                },
            },
            reason: {
                $set: null,
            },
        })
    }

    if (isEmpty(sessionOutcomes)) return null

    return (
        <Grid paddingY={1} item xs={12}>
            <Stack direction="row" alignItems="center" spacing={1} gap={1}>
                <Typography fontFamily="Inter" fontSize={15} fontWeight={700}>
                    SESSION OUTCOME:<span className="required-star">*</span>
                </Typography>
                <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                    {sessionOutcomes.map((button, index) => {
                        return (
                            <Button
                                id={`${button.id}::${button.name}`}
                                onClick={(e) => {
                                    handleClick(e, button)
                                }}
                                endIcon={
                                    callSessionV2.sessionOutcome?.id ==
                                    button.id ? (
                                        <CloseIcon fontSize="small"></CloseIcon>
                                    ) : (
                                        <></>
                                    )
                                }
                                key={'SOK' + index}
                                variant="outlined"
                                sx={{
                                    background:
                                        callSessionV2.sessionOutcome?.id ==
                                        button.id
                                            ? 'rgba(119, 77, 252, 0.2)'
                                            : '',
                                    borderRadius: '100px',
                                    border: `1px solid #774DFC`,
                                    '&:hover': {
                                        border: `1px solid #774DFC`,
                                        bgcolor: 'transparent',
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: `#774DFC`,
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: 12,
                                }}
                            >
                                {button.name}
                            </Button>
                        )
                    })}
                </Stack>
            </Stack>
        </Grid>
    )
}
