/**
 * @file OpportunityForm.tsx
 * @description This file contains the logic to render the form for the opportunity.
 */
import {
    Divider,
    FormControl,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    SxProps,
    TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useCallSession } from 'context/CallSessionProvider'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import CustomSingleSelect from './CustomSingleSelect'
import CustomMultiSelect from './CustomMultiSelect'

type ExpectedOpportunitySizeProps = {
    sx?: SxProps
}

export const idToDisplayNameMap: { [key: string]: string } = {
    custbody_hardware_category: 'Hardware category',
    custbody_opportunity_category: 'Opportunity category',
    custbody_opp_manufacturer1: 'Primary Manufacturer',
    title: 'Opportunity Name',
    custbody_opp_next_steps: 'Next Steps',
    expectedclosedate: 'Expected Close',
    custbody_opp_expected_ship_date: 'Expected Ship',
    custbody_opp_amount: 'hardware_revenue',
    custbody_opp_expected_margin: 'hardware_margin',
    custbody_consumables_amount: 'consumable_revenue',
    custbody_opp_consumable_expectedmargin: 'consumable_margin',
    custbody_prof_svcs_amount: 'services_revenue',
    custbody_opp_prof_svcs_expected_margin: 'services_margin',
    custbody_buddy_expected_oppo_size: 'opportunity_value',
}
export const OpportunityForm: React.FC<ExpectedOpportunitySizeProps> = () => {
    const { nsOppFormMapping, nsOppData, updateNsOppForm } = useCallSession()
    //for restricting the user to enter only numbers between 0-100
    const handleChange = (event: any) => {
        let value = event.target.value
        if (
            !/^\d*\.?\d*$/.test(value) ||
            parseFloat(value) < 0 ||
            parseFloat(value) > 100 ||
            /^0\d/.test(value)
        ) {
            event.target.value = ''
            event.preventDefault()
        } else {
            updateNsOppForm({
                [event.target.id]: {
                    $set: parseFloat(value),
                },
            })
        }
    }
    //for restricting the user to enter only numbers between 0-100
    const handleBlur = (event: any) => {
        let value = event.target.value

        if (
            value &&
            (!/^\d*\.?\d*$/.test(value) ||
                parseFloat(value) < 0 ||
                parseFloat(value) > 100 ||
                /^0\d/.test(value))
        ) {
            event.target.value = ''
        }
    }

    const handleChange2 = (event: any) => {
        let value = event.target.value
        if (!/^\d*\.?\d*$/.test(value) || /^0\d/.test(value)) {
            event.target.value = ''
            event.preventDefault()
        } else {
            updateNsOppForm({
                [event.target.id]: {
                    $set: parseFloat(value),
                },
            })
        }
    }

    const handleBlur2 = (event: any) => {
        let value = event.target.value
        if (value && (!/^\d*\.?\d*$/.test(value) || /^0\d/.test(value))) {
            event.target.value = ''
        }
    }

    return (
        <Grid
            paddingY={1}
            item
            xs={12}
            sx={{
                width: '100%',
            }}
        >
            <Divider></Divider>
            <form>
                <Grid container spacing={5} padding={2}>
                    <Grid item xs={6}>
                        <FormControl
                            required
                            fullWidth
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 2,
                                '& .MuiFormLabel-asterisk': {
                                    color: 'red',
                                },
                            }}
                        >
                            <FormLabel
                                htmlFor="title"
                                sx={{
                                    flex: 1,
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                }}
                            >
                                Opportunity Name
                            </FormLabel>
                            <TextField
                                autoComplete="off" // Disable auto-fill
                                onChange={(e) => {
                                    updateNsOppForm({
                                        title: {
                                            $set: e.target.value,
                                        },
                                    })
                                }}
                                value={nsOppData.title}
                                placeholder="Enter Opportunity Name"
                                id="title"
                                name="title"
                                sx={{ flex: 2 }}
                            />
                        </FormControl>
                        <FormControl
                            required
                            fullWidth
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 2,
                                '& .MuiFormLabel-asterisk': {
                                    color: 'red',
                                },
                            }}
                        >
                            <FormLabel
                                htmlFor="custbody_opp_next_steps"
                                sx={{
                                    flex: 1,
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                }}
                            >
                                Next Steps
                            </FormLabel>
                            <TextField
                                autoComplete="off" // Disable auto-fill
                                onChange={(e) => {
                                    updateNsOppForm({
                                        custbody_opp_next_steps: {
                                            $set: e.target.value,
                                        },
                                    })
                                }}
                                value={nsOppData.custbody_opp_next_steps}
                                placeholder="Enter Next Steps"
                                name="custbody_opp_next_steps"
                                id="custbody_opp_next_steps"
                                multiline
                                rows={3.5}
                                sx={{ flex: 2 }}
                            />
                        </FormControl>
                        {nsOppFormMapping
                            ?.filter(
                                (nsData) =>
                                    nsData.data_type ===
                                    'custbody_opportunity_category'
                            )
                            .map((nsData) => (
                                <CustomMultiSelect
                                    key={nsData.pk + nsData.data_type}
                                    id={nsData.data_type}
                                    options={nsData.data}
                                    updateNsOppForm={updateNsOppForm}
                                ></CustomMultiSelect>
                            ))}
                        {nsOppFormMapping
                            ?.filter(
                                (nsData) =>
                                    nsData.data_type ===
                                    'custbody_hardware_category'
                            )
                            .map((nsData) => (
                                <CustomMultiSelect
                                    key={nsData.pk + nsData.data_type}
                                    id={nsData.data_type}
                                    options={nsData.data}
                                    updateNsOppForm={updateNsOppForm}
                                ></CustomMultiSelect>
                            ))}
                        <FormControl
                            required
                            fullWidth
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 2,
                                '& .MuiFormLabel-asterisk': {
                                    color: 'red',
                                },
                            }}
                        >
                            <FormLabel
                                htmlFor="hardwareRevenue&Margin"
                                sx={{
                                    flex: 1,
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                }}
                            >
                                Hardware Revenue & Margin
                            </FormLabel>
                            <TextField
                                autoComplete="off" // Disable auto-fill
                                placeholder="Enter Hardware Revenue"
                                id="custbody_opp_amount"
                                name="custbody_opp_amount"
                                sx={{ flex: 1 }}
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9]*[.]?[0-9]*',
                                }}
                                onChange={handleChange2}
                                onBlur={handleBlur2}
                            />
                            <TextField
                                autoComplete="off" // Disable auto-fill
                                placeholder="Enter Hardware Margin"
                                id="custbody_opp_expected_margin"
                                name="custbody_opp_expected_margin"
                                sx={{ flex: 1, paddingLeft: 1 }}
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9]*[.]?[0-9]*',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            required
                            fullWidth
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 2,
                                '& .MuiFormLabel-asterisk': {
                                    color: 'red',
                                },
                            }}
                        >
                            <FormLabel
                                htmlFor="opportunityValue"
                                sx={{
                                    flex: 1,
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                }}
                            >
                                Opportunity Value
                            </FormLabel>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                                autoComplete="off" // Disable auto-fill
                                placeholder="Enter Opportunity Value"
                                id="custbody_buddy_expected_oppo_size"
                                name="custbody_buddy_expected_oppo_size"
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9]*[.]?[0-9]*',
                                }}
                                onChange={handleChange2}
                                onBlur={handleBlur2}
                                sx={{
                                    flex: 2,
                                }}
                            />
                        </FormControl>
                        {nsOppFormMapping
                            ?.filter(
                                (nsData) =>
                                    nsData.data_type ===
                                    'custbody_opp_manufacturer1'
                            )
                            .map((nsData) => (
                                <CustomSingleSelect
                                    key={nsData.pk + nsData.data_type}
                                    id={nsData.data_type}
                                    options={nsData.data}
                                    updateNsOppForm={updateNsOppForm}
                                ></CustomSingleSelect>
                            ))}
                        <FormControl
                            required
                            fullWidth
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 2,
                                '& .MuiFormLabel-asterisk': {
                                    color: 'red',
                                },
                            }}
                        >
                            <FormLabel
                                htmlFor="expectedclosedate"
                                sx={{
                                    flex: 1,
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                }}
                            >
                                Expected Close Date
                            </FormLabel>
                            <DatePicker
                                minDate={DateTime.now()}
                                onAccept={(value: any) => {
                                    updateNsOppForm({
                                        expectedclosedate: {
                                            $set: DateTime.fromISO(
                                                value.toISODate()
                                            ).toFormat('MM/dd/yyyy'),
                                        },
                                    })
                                }}
                                sx={{
                                    flex: 2,
                                }}
                                slots={{
                                    textField: (params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                readOnly: true,
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </FormControl>
                        <FormControl
                            required
                            fullWidth
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 2,
                                '& .MuiFormLabel-asterisk': {
                                    color: 'red',
                                },
                            }}
                        >
                            <FormLabel
                                htmlFor="custbody_opp_expected_ship_date"
                                sx={{
                                    flex: 1,
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                }}
                            >
                                Expected Ship Date
                            </FormLabel>
                            <DatePicker
                                minDate={
                                    nsOppData.expectedclosedate &&
                                    DateTime.fromFormat(
                                        nsOppData.expectedclosedate,
                                        'MM/dd/yyyy'
                                    ).plus({ days: 1 })
                                }
                                onAccept={(value: any) => {
                                    updateNsOppForm({
                                        custbody_opp_expected_ship_date: {
                                            $set: DateTime.fromISO(
                                                value.toISODate()
                                            ).toFormat('MM/dd/yyyy'),
                                        },
                                    })
                                }}
                                sx={{
                                    flex: 2,
                                }}
                                slots={{
                                    textField: (params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                readOnly: true,
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </FormControl>

                        <FormControl
                            required
                            fullWidth
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 2,
                                '& .MuiFormLabel-asterisk': {
                                    color: 'red',
                                },
                            }}
                        >
                            <FormLabel
                                htmlFor="consumerRevenue&Margin"
                                sx={{
                                    flex: 1,
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                }}
                            >
                                Consumable Revenue & Margin
                            </FormLabel>
                            <TextField
                                autoComplete="off" // Disable auto-fill
                                placeholder="Enter Consumable Revenue"
                                id="custbody_consumables_amount"
                                name="custbody_consumables_amount"
                                sx={{ flex: 1 }}
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9]*[.]?[0-9]*',
                                }}
                                onChange={handleChange2}
                                onBlur={handleBlur2}
                            />
                            <TextField
                                autoComplete="off" // Disable auto-fill
                                placeholder="Enter Consumable Margin"
                                id="custbody_opp_consumable_expectedmargin"
                                name="custbody_opp_consumable_expectedmargin"
                                sx={{ flex: 1, paddingLeft: 1 }}
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9]*[.]?[0-9]*',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormControl>
                        <FormControl
                            required
                            fullWidth
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 2,
                                '& .MuiFormLabel-asterisk': {
                                    color: 'red',
                                },
                            }}
                        >
                            <FormLabel
                                htmlFor="serviceRevenue&Margin"
                                sx={{
                                    flex: 1,
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    fontFamily: 'Inter',
                                }}
                            >
                                Service Revenue & Margin
                            </FormLabel>
                            <TextField
                                autoComplete="off" // Disable auto-fill
                                placeholder="Enter Service Revenue"
                                id="custbody_prof_svcs_amount"
                                name="custbody_prof_svcs_amount"
                                sx={{ flex: 1 }}
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9]*[.]?[0-9]*',
                                }}
                                onChange={handleChange2}
                                onBlur={handleBlur2}
                            />
                            <TextField
                                autoComplete="off" // Disable auto-fill
                                placeholder="Enter Service Margin"
                                id="custbody_opp_prof_svcs_expected_margin"
                                name="custbody_opp_prof_svcs_expected_margin"
                                sx={{ flex: 1, paddingLeft: 1 }}
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9]*[.]?[0-9]*',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
}
