/**
 * @file ListPageSearchComponent.tsx
 * @description This file contains the logic to search for opportunities.
 */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
    Typography,
    SxProps,
    Stack,
    Button,
    TextField,
    CircularProgress,
    Popper,
    Paper,
    Box,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import { TStatus, useOpportunities } from 'context/OpportunitiesProvider'
import StarIcon from '@mui/icons-material/Star'
import { leadStatus } from './DatagridComponents'
import debounce from 'lodash/debounce'

type CustomTableHeaderProps = {
    sx?: SxProps
}

export const RenderStars = (propensity: number | string) => {
    let starRating = parseFloat(propensity.toString())
    const decimal = starRating - Math.floor(starRating)
    if (decimal < 0.5) {
        starRating = Math.floor(starRating)
    } else {
        starRating = Math.ceil(starRating)
    }
    const stars = []
    for (let i = 0; i < 5; i++) {
        if (i <= starRating - 1) {
            stars.push(
                <StarIcon
                    key={`star-adxcczx-{i}`}
                    fontSize="small"
                    sx={{
                        color: '#0F1835',
                        width: '16px',
                        height: '16px',
                    }}
                />
            )
        } else {
            stars.push(
                <StarIcon
                    key={`star-czxcxzedsdf-${i}`}
                    fontSize="small"
                    sx={{
                        color: 'rgba(188, 188, 188, 0.6)',
                        width: '16px',
                        height: '16px',
                    }}
                />
            )
        }
    }
    return stars.map((star) => star)
}

export const LPSearchComponent: React.FC<CustomTableHeaderProps> = () => {
    const {
        searchResults,
        fetchSearchResultsCallback,
        handleButtonClickCallback,
        setStatusCallback,
    } = useOpportunities()
    const [localSearchTerm, setLocalSearchTerm] = useState('')
    const [searching, setSearching] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const inputRef = useRef<HTMLDivElement | null>(null)

    const debouncedFetchSearchResults = useCallback(
        debounce((term: string) => {
            fetchSearchResultsCallback(term)
            setSearching(false)
        }, 500),
        [fetchSearchResultsCallback]
    )

    useEffect(() => {
        if (localSearchTerm.length === 1) {
            setStatusCallback('all' as TStatus)
        }
        if (localSearchTerm.length >= 3) {
            setSearching(true)
            debouncedFetchSearchResults(localSearchTerm)
        } else {
            setSearching(false)
        }
    }, [localSearchTerm, debouncedFetchSearchResults, setStatusCallback])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalSearchTerm(e.target.value)
        setAnchorEl(inputRef.current)
    }

    const handleClearSearch = () => {
        setLocalSearchTerm('')
        setAnchorEl(null)
    }

    const handleTextFieldFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setAnchorEl(inputRef.current)
    }

    return (
        <Stack direction={'row'} spacing={2} width="400px">
            <TextField
                value={localSearchTerm}
                onChange={handleSearchChange}
                onFocus={handleTextFieldFocus}
                placeholder="Search by Account ID or Account Name"
                variant="outlined"
                autoComplete="off"
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        '& fieldset': {
                            borderRadius: '8px',
                        },
                        '&:hover fieldset': {
                            borderColor: '#774DFC',
                        },
                        '&.Mui-focused fieldset': {
                            borderWidth: '1px',
                            borderColor: '#774DFC',
                        },
                        '& .MuiInputBase-input::placeholder': {
                            fontWeight: '600',
                        },
                    },
                }}
                inputRef={inputRef}
            />
            <Button
                onClick={localSearchTerm.length ? handleClearSearch : undefined}
                variant="outlined"
                startIcon={
                    searching ? (
                        <CircularProgress size={16} sx={{ color: '#774DFC' }} />
                    ) : localSearchTerm.length ? (
                        <CancelIcon fontSize="small" />
                    ) : (
                        <SearchIcon fontSize="small" />
                    )
                }
                sx={{
                    paddingX: 3,
                    borderRadius: '100px',
                    border: `1px solid #774DFC`,
                    '&:hover': {
                        border: `1px solid #774DFC`,
                        bgcolor: 'transparent',
                    },
                    display: 'flex',
                    alignItems: 'center',
                }}
                disabled={searching}
            >
                <Typography
                    fontWeight={600}
                    sx={{
                        background:
                            'linear-gradient(to bottom,rgba(24, 152, 255, 1),rgba(120, 77, 255, 1))',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        backgroundClip: 'text',
                        textFillColor: 'transparent',
                    }}
                >
                    {searching
                        ? 'Searching'
                        : localSearchTerm.length
                        ? 'Clear'
                        : 'Search'}
                </Typography>
            </Button>
            <Popper
                open={Boolean(anchorEl) && localSearchTerm.length >= 3}
                anchorEl={anchorEl}
                placement="bottom-end"
                style={{ zIndex: 1500 }}
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                ]}
            >
                <Paper
                    sx={{
                        maxHeight: 300,
                        overflow: 'auto',
                        width: '100%',
                        borderRadius: '4px',
                    }}
                >
                    <Stack direction="column" spacing={0.5} padding={1}>
                        {searchResults?.results?.length === 0 && (
                            <Box
                                sx={{
                                    width: '100%',
                                    maxWidth: '400px',
                                    minWidth: '400px',
                                }}
                            >
                                No Results
                            </Box>
                        )}
                        {searchResults?.results.map((result, index) => (
                            <Button
                                key={index + 'listSerachComponent'}
                                onClick={() => {
                                    handleButtonClickCallback(
                                        result,
                                        searchResults.count
                                    )
                                }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    textAlign: 'left',
                                    background: '',
                                    '&:hover': {
                                        background: 'rgba(219, 191, 255, 0.3)',
                                    },
                                    '& .MuiTouchRipple-root span': {
                                        backgroundColor:
                                            'rgba(219, 191, 255, 0.3) !important',
                                    },
                                    width: '100%',
                                    maxWidth: '400px',
                                    minWidth: '400px',
                                }}
                            >
                                <Typography
                                    fontFamily={'Inter'}
                                    fontWeight={600}
                                    fontSize={12}
                                    color={'#000000'}
                                >
                                    Account ID: {result.account_id}
                                </Typography>
                                <Typography
                                    fontFamily={'Inter'}
                                    fontWeight={400}
                                    fontSize={11}
                                    color={'#727492'}
                                >
                                    Account Name: {result.account_name}
                                </Typography>
                                <Typography
                                    fontFamily={'Inter'}
                                    fontWeight={400}
                                    fontSize={11}
                                    color={'#727492'}
                                >
                                    Campaign: {result.campaign_type}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        textAlign={'center'}
                                        fontFamily={'Inter'}
                                        fontWeight={400}
                                        fontSize={11}
                                        color={'#727492'}
                                    >
                                        Propensity:
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {RenderStars(result.propensity)}
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        bgcolor:
                                            leadStatus[
                                                `${result.status.toLowerCase()}`
                                            ]?.bgColor,
                                        paddingX: '12px',
                                        paddingY: '4px',
                                        borderRadius: '4px',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        fontWeight={700}
                                        fontFamily={'Inter'}
                                        fontSize={10}
                                        color={
                                            leadStatus[
                                                `${result.status.toLowerCase()}`
                                            ]?.color
                                        }
                                        variant="button"
                                        sx={{ textTransform: 'uppercase' }}
                                    >
                                        {result.status ?? '--'}
                                    </Typography>
                                </Box>
                            </Button>
                        ))}
                    </Stack>
                </Paper>
            </Popper>
        </Stack>
    )
}
