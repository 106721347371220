/**
 * @file CallSessionTableHeader.tsx
 * @description This file contains the logic to render the call session table header.
 */
import React, { useCallback, useState } from 'react'
import {
    Typography,
    IconButton,
    SxProps,
    Stack,
    Popper,
    ClickAwayListener,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import FollowUpFilter from './FollowUpFilter'
import { TContactV3 } from 'buddy-api'
import { TCallSessionFollowFilterOptions } from './FollowUpDateDataGrid'

type CallSessionTableHeaderProps = {
    sx?: SxProps
    headerName: string | React.ReactFragment
    isFilterEnabled: boolean
    columnField: string
    filterOptions: TCallSessionFollowFilterOptions
    filterState: TCallSessionFollowFilterOptions
    handleFilterChangeCallback: (
        filterValues: string[],
        columnField: string
    ) => Promise<void>
}

export const CallSessionHeaderFollowUp: React.FC<
    CallSessionTableHeaderProps
> = ({
    sx,
    headerName,
    columnField,
    isFilterEnabled,
    filterOptions,
    filterState,
    handleFilterChangeCallback,
}) => {
    const [openPopper, setOpenPopper] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const onTogglePopper = (currentAnchorEl: any) => {
        if (openPopper) {
            setOpenPopper(false)
            setAnchorEl(null)
        } else {
            setOpenPopper(true)
            setAnchorEl(currentAnchorEl)
        }
    }

    const handleFilterClick = useCallback(
        (event) => {
            onTogglePopper(event.currentTarget)
        },
        [onTogglePopper]
    )

    const handleClickAway = () => {
        setOpenPopper(false)
        setAnchorEl(null)
    }

    return (
        <>
            <Stack direction={'row'} alignItems="center" height="100%">
                <Typography fontFamily={'Inter'} fontSize={10} fontWeight={700}>
                    {headerName}
                </Typography>
                <>
                    {isFilterEnabled && (
                        <IconButton
                            aria-describedby={columnField}
                            onClick={handleFilterClick}
                            sx={{
                                padding: 0,
                                margin: 0,
                            }}
                        >
                            <FilterListIcon
                                style={{
                                    fontSize: '16px',
                                }}
                            />
                        </IconButton>
                    )}
                </>
            </Stack>
            <Popper
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 15],
                            },
                        },
                    ],
                }}
                sx={{
                    zIndex: 1000,
                    fontFamily: 'Inter !important',
                    padding: 1,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #e3e3e3',
                    borderRadius: '8px',
                    width: 'auto',
                    height: 'auto',
                }}
                id={columnField}
                open={openPopper}
                anchorEl={anchorEl}
                placement="bottom"
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                        <FollowUpFilter
                            handleFilterChangeCallback={
                                handleFilterChangeCallback
                            }
                            filterOptions={filterOptions}
                            filterState={filterState}
                            columnField={columnField}
                        />
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
