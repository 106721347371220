/**
 * @file DetailBox.tsx
 * @description This file contains the logic to render the detail box.
 */
import { Box, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'

type DetailBoxProps = {
    title: string
    detail: string | number | undefined
}

export const DetailBox: React.FC<DetailBoxProps> = ({ title, detail }) => {
    return (
        <Box>
            <Typography
                fontFamily="Inter"
                fontSize={11}
                fontWeight={500}
                color="#727492"
            >
                {title}
            </Typography>
            <Typography
                fontFamily="Inter"
                fontSize={12}
                fontWeight={600}
                color="#000021"
            >
                {detail}
            </Typography>
        </Box>
    )
}
