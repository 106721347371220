import React, { useState, useCallback, useEffect } from 'react'
import {
    SxProps,
    Stack,
    TextField,
    FormGroup,
    FormControlLabel,
    IconButton,
    Divider,
    Tooltip,
    InputAdornment,
    Checkbox,
} from '@mui/material'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import SearchIcon from '@mui/icons-material/Search'
import { makeStyles } from '@mui/styles'
import { toTitleCase } from 'utils/stringformatter'
import { TCallSessionFollowFilterOptions } from './FollowUpDateDataGrid'

type CallSessionFilterProps = {
    sx?: SxProps
    columnField: string
    filterOptions: TCallSessionFollowFilterOptions
    filterState: {
        [key: string]: string[]
    }
    handleFilterChangeCallback: (
        filterValues: string[],
        columnField: string
    ) => Promise<void>
}

const useStyles = makeStyles({
    formControlLabel: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 12,
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export const FollowUpFilter: React.FC<CallSessionFilterProps> = ({
    columnField,
    filterOptions,
    filterState,
    handleFilterChangeCallback,
}) => {
    const [filteredOptions, setFilteredOptions] = useState<string[]>(
        filterOptions[columnField]
    )

    useEffect(() => {
        setFilteredOptions(Array.from(new Set(filterOptions[columnField])))
    }, [filterOptions, columnField])

    const classes = useStyles()

    const handleTextInput = useCallback(
        (e) => {
            const searchText = e.target.value.toLowerCase()
            const newFilteredOptions = filterOptions[columnField].filter(
                (option) => option.toLowerCase().includes(searchText)
            )
            console.log('newFilteredOptions', newFilteredOptions)
            setFilteredOptions(newFilteredOptions)
        },
        [filterOptions, columnField]
    )

    const handleCheckboxChange = async (option: string) => {
        const newFilterState = [...filterState[columnField]]
        const index = newFilterState.indexOf(option)
        if (index > -1) {
            newFilterState.splice(index, 1)
        } else {
            newFilterState.push(option)
        }
        handleFilterChangeCallback(newFilterState, columnField)
    }

    const handleSelectAllChange = async () => {
        const allSelected =
            filteredOptions.length === filterState[columnField].length
        const newFilterState = allSelected ? [] : filteredOptions
        handleFilterChangeCallback(newFilterState, columnField)
    }

    const handleFilterClear = useCallback(async () => {
        handleFilterChangeCallback([], columnField)
    }, [handleFilterChangeCallback, columnField])

    return (
        <Stack direction={'column'} gap={1} maxWidth={'230px'}>
            <Stack
                direction={'row-reverse'}
                justifyContent={'space-between'}
                alignItems={'center'}
                spacing={1}
            >
                <Tooltip title="Clear Filter">
                    <IconButton color="primary" onClick={handleFilterClear}>
                        <FilterListOffIcon
                            style={{
                                fontSize: '16px',
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <TextField
                    autoComplete="false"
                    autoCorrect="false"
                    id="input_filter"
                    variant="outlined"
                    onChange={handleTextInput}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderWidth: '1px',
                            },
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                            </InputAdornment>
                        ),
                        sx: {
                            paddingLeft: '5px',
                        },
                    }}
                    size="small"
                />
            </Stack>
            <Stack
                sx={{
                    maxHeight: 300,
                    overflow: 'auto',
                }}
            >
                <FormGroup>
                    <FormControlLabel
                        classes={{
                            root: classes.formControlLabel,
                            label: classes.label,
                        }}
                        control={
                            <Checkbox
                                onChange={handleSelectAllChange}
                                size="small"
                                checked={
                                    filteredOptions.length > 0 &&
                                    filterState[columnField].length ===
                                        filteredOptions.length
                                }
                                sx={{
                                    color: '#774DFC',
                                    '&.Mui-checked': {
                                        color: '#774DFC',
                                    },
                                }}
                            />
                        }
                        label={'Select All'}
                    />
                    <Divider />
                    {filteredOptions.map((option: string, index: number) => {
                        return (
                            <FormControlLabel
                                key={index + 'sxzcdasd'}
                                classes={{
                                    root: classes.formControlLabel,
                                    label: classes.label,
                                }}
                                control={
                                    <Checkbox
                                        key={index + 'czxcaeqwewd'}
                                        onChange={() =>
                                            handleCheckboxChange(
                                                option.toString() ?? ''
                                            )
                                        }
                                        checked={filterState[
                                            columnField
                                        ].includes(option)}
                                        size="small"
                                        sx={{
                                            color: '#774DFC',
                                            '&.Mui-checked': {
                                                color: '#774DFC',
                                            },
                                        }}
                                    />
                                }
                                label={toTitleCase(option)}
                            />
                        )
                    })}
                </FormGroup>
            </Stack>
        </Stack>
    )
}

export default FollowUpFilter
