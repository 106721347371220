/**
 * @file CustomerSessionTopBar.tsx
 * @description This file contains the logic to render the top bar for the customer session.
 */
import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { useCallSession } from 'context/CallSessionProvider'
import { FiLayers, FiUsers, FiRefreshCcw, FiPower } from 'react-icons/fi'
import { toTitleCase } from 'utils/stringformatter'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import BusinessIcon from '@mui/icons-material/Business'
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback'
import HandshakeIcon from '@mui/icons-material/Handshake'

export const CustomerSessionTopBar: React.FC = () => {
    const { callSessionV2, account } = useCallSession()

    return (
        <Stack
            direction={'row'}
            gap={0.5}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                {callSessionV2?.lead &&
                    account?.filtered_leads?.find(
                        (item) => item.lead_id == callSessionV2.lead
                    )?.campaign_type == 'REACTIVATION' && (
                        <FiPower size={20}></FiPower>
                    )}
                {callSessionV2?.lead &&
                    account?.filtered_leads?.find(
                        (item) => item.lead_id == callSessionV2.lead
                    )?.campaign_type == 'RETENTION' && (
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <FiRefreshCcw size={20} />
                            <FiUsers
                                size={10}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                            />
                        </Box>
                    )}
                {callSessionV2?.lead &&
                    account?.filtered_leads?.find(
                        (item) => item.lead_id == callSessionV2.lead
                    )?.campaign_type == 'EXPANSION' && (
                        <FiLayers size={20}></FiLayers>
                    )}
                {callSessionV2?.lead &&
                    account?.filtered_leads?.find(
                        (item) => item.lead_id == callSessionV2.lead
                    )?.campaign_type == 'BIZ RULE' && (
                        <BusinessIcon
                            sx={{
                                fontSize: 20,
                            }}
                        ></BusinessIcon>
                    )}
                {callSessionV2?.lead &&
                    account?.filtered_leads?.find(
                        (item) => item.lead_id == callSessionV2.lead
                    )?.campaign_type == 'INBOUND' && (
                        <PhoneCallbackIcon
                            sx={{
                                fontSize: 20,
                            }}
                        ></PhoneCallbackIcon>
                    )}
                {callSessionV2?.lead &&
                    account?.filtered_leads?.find(
                        (item) => item.lead_id == callSessionV2.lead
                    )?.campaign_type == 'ACQUISITION' && (
                        <HandshakeIcon
                            sx={{
                                fontSize: 20,
                            }}
                        ></HandshakeIcon>
                    )}
                <Typography
                    flexDirection={'row'}
                    fontFamily={'Inter'}
                    fontWeight={500}
                    fontSize={17}
                    color={'#000021'}
                    paddingRight={1}
                >
                    {callSessionV2?.lead &&
                        toTitleCase(
                            account?.filtered_leads?.find(
                                (item) => item.lead_id == callSessionV2.lead
                            )?.campaign_type
                        )}
                </Typography>
                {account?.true_support_flag ? (
                    <Box
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            border: '1px dashed rgba(25, 122, 86, 1)',
                            padding: '0px 5px',
                            borderRadius: 12,
                        }}
                    >
                        <TaskAltIcon
                            fontSize="small"
                            sx={{
                                color: 'rgba(25, 122, 86, 1)',
                                marginRight: '4px',
                            }}
                        />
                        <Typography
                            sx={{
                                color: 'rgba(25, 122, 86, 1)',
                                fontSize: '11px',
                                fontWeight: '500',
                                fontFamily: '"Inter", sans-serif',
                            }}
                        >
                            TrueSupport
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            border: '1px dashed rgba(240, 111, 104, 1)',
                            padding: '0px 5px',
                            borderRadius: 12,
                        }}
                    >
                        <DoNotDisturbIcon
                            fontSize="small"
                            sx={{
                                color: 'rgba(240, 111, 104, 1)',
                                marginRight: '4px',
                            }}
                        />
                        <Typography
                            sx={{
                                color: 'rgba(240, 111, 104, 1)',
                                fontSize: '11px',
                                fontWeight: '500',
                                fontFamily: '"Inter", sans-serif',
                            }}
                        >
                            No TrueSupport
                        </Typography>
                    </Box>
                )}
            </Stack>
        </Stack>
    )
}
