import { ID } from 'buddy-api/types'
import ApiMixin from '../ApiMixin'
import { TransactionHistory } from 'buddy-api/types/TransactionHistory'
import ApiRequest from 'buddy-api/ApiRequest'
import { TransactionHistoryType } from 'components/callsession/CustomerTransactionHistoryDataGrid'

export type TransactionHistoryFilter = {
    item_manufacturer: string[]
    item_name: string[]
    item_sku: string[]
    document_number: string[]
    contact_billing: TContact_billing[]
    status: string[]
    item_category: string[]
}

export type TContact_billing = {
    contact: ID | null
    contact_id: ID | null
    full_name: string | null
}

export default class TransactionHistoryAPI extends ApiMixin<TransactionHistory> {
    public transaction_column_filters = async (
        account_id: ID,
        transactionHistoryType: TransactionHistoryType,
        signal?: AbortSignal | null
    ) => {
        const url = `/transaction-history/${account_id}/transaction_column_filters`
        const result = await ApiRequest.get<TransactionHistoryFilter>(
            url,
            signal,
            {
                type: transactionHistoryType,
            }
        )
        return result.body
    }
}
