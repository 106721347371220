/**
 * @file OpportunityTablePanel.tsx
 * @description This file contains the implementation of the OpportunityTablePanel component.
 * @description It provides a table of opportunities using the DataGridPro component from MUI.
 * @description It fetches the opportunities data from the OpportunitiesProvider context and provides various callbacks for handling sorting,
 *              filtering, pagination, and row click events.
 */

import { Paper, Checkbox, createTheme } from '@mui/material'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { CustomFooter, columnGroupingModel } from 'components/opportunity'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { DataGridSX } from 'utils/datagridsx'
import { useOpportunities } from 'context/OpportunitiesProvider'
import { ILeadList } from 'buddy-api'
import chroma from 'chroma-js'
import { TableColumns } from 'components/opportunity/TableColumns'

const DatagridTheme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeader: {
                    '&:focus': {
                        outline: 'none',
                    },
                    '&:focus-within': {
                        outline: 'none',
                    },
                },
                filterForm: {
                    minWidth: '650px',
                    width: '100%',
                    gap: 6,
                },
            },
        },
    },
    palette: {
        background: {
            default: '#f2f2f2',
        },
        text: {
            secondary: chroma('#272727').hex(),
            primary: chroma('#000000').hex(),
        },
        primary: {
            light: chroma('#774DFC').brighten(0.5).hex(),
            main: '#774DFC',
            dark: chroma('#774DFC').darken(0.5).hex(),
        },
        secondary: {
            light: chroma('#774DFC').brighten(0.5).hex(),
            main: '#774DFC',
            dark: chroma('#774DFC').darken(0.5).hex(),
        },
        warning: {
            main: '#FFC107',
        },
        error: {
            light: '#ED4A4A',
            main: '#ED4A4A',
        },
        grey: {
            300: '#E0E0E0',
            400: '#BDBDBD',
        },
    },
    typography: {
        fontFamily: 'Inter',
        h1: {
            fontWeight: 600,
            fontSize: '13px',
        },
        h2: {
            fontWeight: 600,
            fontSize: '12px',
        },
        h3: {
            fontWeight: 600,
            fontSize: '11px',
        },
        body1: {
            fontWeight: 400,
            fontSize: '13px',
        },
        body2: {
            fontWeight: 400,
            fontSize: '11px',
            lineHeight: '13px',
        },
        subtitle1: {
            fontWeight: 600,
            fontSize: '12px',
            opacity: 0.6,
        },
        subtitle2: {
            fontWeight: 400,
            fontSize: '12px',
            opacity: 0.6,
        },
    },
})

const OpportunityTablePanel: React.FC = () => {
    const {
        status,
        loading,
        page,
        pageSize,
        opportunities,
        rowCount,
        sortModel,
        sortOrder,
        filterModel,
        setToggleSortCallback,
        getRowIdCallback,
        onSortModelChangeCallback,
        handlePageSizeCallback,
        handlePageChangeCallback,
        onFilterModelChangeCallback,
        handleRowClickCallback,
    } = useOpportunities()

    const tableColumns: GridColDef<ILeadList>[] = TableColumns(
        status,
        sortOrder,
        setToggleSortCallback
    )

    return (
        <Paper
            sx={{
                height: '100%',
                borderRadius: '4px',
                boxShadow: 'none',
                '& .column-group-customer-info': {
                    backgroundColor: 'rgba(188, 188, 188, 0.25)',
                    borderBottom: '2px solid rgba(148, 109, 255, 1)',
                    borderRight: '1px solid rgba(188, 188, 188, 0.5)',
                },
                '& .column-group-lead-info': {
                    backgroundColor: 'rgba(188, 188, 188, 0.25)',
                    borderBottom: '2px solid rgba(66, 70, 106, 1)',
                    borderLeft: '1px solid rgba(188, 188, 188, 0.5)',
                },
                '& .custom-header-style': {
                    backgroundColor: 'rgba(244, 244, 244, 1)',
                },
                '& .custom-row-color': {
                    backgroundColor: 'rgba(241, 241, 241, 1)',
                },
            }}
        >
            <ThemeProvider theme={DatagridTheme}>
                <DataGridPro
                    sx={DataGridSX}
                    components={{
                        BaseSwitch: Checkbox,
                        Pagination: CustomFooter,
                    }}
                    sortingOrder={['asc', 'desc', null]}
                    loading={loading}
                    rowHeight={42}
                    headerHeight={42}
                    columns={tableColumns}
                    columnGroupingModel={columnGroupingModel}
                    rows={opportunities}
                    getRowClassName={(params) => {
                        const date =
                            params.row.last_interaction_date &&
                            params.row.last_interaction_date.split('T')[0]
                        if (
                            date === new Date().toISOString().split('T')[0] &&
                            status === 'today'
                        ) {
                            return 'custom-row-color'
                        }
                        return ''
                    }}
                    getRowId={getRowIdCallback}
                    onRowClick={handleRowClickCallback}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                    sortModel={sortModel}
                    filterModel={filterModel}
                    filterMode={status === 'today' ? 'client' : 'server'}
                    sortingMode={status === 'today' ? 'client' : 'server'}
                    paginationMode={status === 'today' ? 'client' : 'server'}
                    onPageChange={handlePageChangeCallback}
                    onPageSizeChange={handlePageSizeCallback}
                    onSortModelChange={onSortModelChangeCallback}
                    onFilterModelChange={onFilterModelChangeCallback}
                    experimentalFeatures={{ columnGrouping: true }}
                    disableSelectionOnClick
                    disableColumnMenu
                    disableDensitySelector
                />
            </ThemeProvider>
        </Paper>
    )
}

export default OpportunityTablePanel
