/**
 * @file InitiativesList.tsx
 * @description This file contains the logic to render Initiatives List.
 */
import { Box, Collapse, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { toTitleCase } from 'utils/stringformatter'

type InitiativesListProps = {
    title: string
    detail: string | number | undefined
}

export const InitiativesList: React.FC<InitiativesListProps> = ({
    title,
    detail,
}) => {
    const [showDetails, setShowDetails] = React.useState(false)
    return (
        <Box>
            <Typography
                onClick={() => setShowDetails(!showDetails)}
                sx={{
                    padding: 1,
                    background: showDetails
                        ? 'rgba(119, 77, 252, 1)'
                        : '#FFFFFF',
                    borderRadius: '3px',
                    color: showDetails ? '#FFFFFF' : '#000000',
                    fontFamily: 'Inter',
                    fontSize: 12,
                    fontWeight: 500,
                }}
            >
                {toTitleCase(title)}
            </Typography>
            <Collapse in={showDetails}>
                <Typography
                    sx={{
                        padding: 1.5,
                        fontFamily: 'Inter',
                        fontSize: 11,
                        fontWeight: 400,
                        color: 'rgba(114, 116, 146, 1)',
                    }}
                >
                    {detail}
                </Typography>
            </Collapse>
        </Box>
    )
}
