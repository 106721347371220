/**
 * @file CustomSingleSelect.tsx
 * @description This file contains the logic to render the single select dropdown for the opportunity form.
 */
import { FormControl, FormLabel, Select, MenuItem } from '@mui/material'
import { useState } from 'react'
import { idToDisplayNameMap } from './OpportunityForm'
import { NetSuiteObject } from 'buddy-api/api/NSDataAPI'
import { INsOppData } from 'buddy-api/types/callstatus'
import { Spec } from 'immutability-helper'
import { toTitleCase } from 'utils/stringformatter'

type CustomSingleSelectProps = {
    id: string
    options: NetSuiteObject[]
    updateNsOppForm: (updates: Spec<INsOppData, never>) => void
}

const CustomSingleSelect: React.FC<CustomSingleSelectProps> = ({
    id,
    options,
    updateNsOppForm,
}) => {
    const [selectedValue, setSelectedValue] = useState<string>('')
    const handleChange = (event: any) => {
        setSelectedValue(event.target.value)
        const mappedValue = options.find(
            (option) => option.name === event.target.value
        )
        updateNsOppForm({
            [id]: {
                $set: mappedValue?.value ?? null,
            },
        })
    }

    return (
        <FormControl
            required
            size="small"
            fullWidth
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 2,
                '& .MuiFormLabel-asterisk': {
                    color: 'red',
                },
            }}
        >
            <FormLabel
                htmlFor={id}
                sx={{
                    flex: 1,
                    fontSize: '13px',
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    color: 'black !important',
                }}
            >
                {toTitleCase(idToDisplayNameMap[id])}
            </FormLabel>
            <Select
                autoComplete="off" // Disable auto-fill
                displayEmpty
                renderValue={(selected) => {
                    if (!selected) {
                        return (
                            <span style={{ color: '#9e9e9e' }}>
                                {toTitleCase(
                                    `Select ${idToDisplayNameMap[id]}`
                                )}
                            </span>
                        )
                    }

                    return selected as string
                }}
                value={selectedValue}
                onChange={handleChange}
                placeholder={`Select ${idToDisplayNameMap[id]}`}
                id={id}
                sx={{
                    flex: 2,
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(119, 77, 252, 1)',
                    },
                    '& .Mui-selected': {
                        background: 'rgba(119, 77, 252, 0.2) !important',
                    },
                }}
            >
                <MenuItem disabled value="">
                    <span>
                        {toTitleCase(`Select ${idToDisplayNameMap[id]}`)}
                    </span>
                </MenuItem>
                {options &&
                    options.map((option) => {
                        return (
                            <MenuItem
                                key={`${idToDisplayNameMap[id]}+${option.value}`}
                                value={option.name}
                            >
                                {option.name}
                            </MenuItem>
                        )
                    })}
            </Select>
        </FormControl>
    )
}
export default CustomSingleSelect
