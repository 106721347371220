/**
 * @file CallSessionSection4.tsx
 * @description
 */
import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { CustomerTransactionHistoryDataGrid } from './CustomerTransactionHistoryDataGrid'
import { InteractionHistroyDataGrid } from './InteractionHistroyDataGrid'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

interface CallSessionSection4Props {}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <Box
            sx={{
                height: '625px',
                maxHeight: 'max-content',
            }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingY: 2, height: '100%' }}>{children}</Box>
            )}
        </Box>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const CallSessionSection4: React.FC<CallSessionSection4Props> = () => {
    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Box
            sx={{
                minHeight: '375px',

                width: '100%',
                paddingX: 2,
                background: 'rgba(255, 255, 255, 1)',
            }}
        >
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            >
                <Tabs
                    indicatorColor="primary"
                    value={value}
                    onChange={handleChange}
                    aria-label="histroy tabs panel"
                    sx={{
                        '& .MuiTab-root': {
                            color: 'black',
                        },
                        '& .Mui-selected': {
                            color: 'black !important',
                        },
                    }}
                >
                    <Tab
                        label="Customer Transaction History"
                        {...a11yProps(0)}
                    />
                    <Tab label="Interaction History" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <CustomerTransactionHistoryDataGrid />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <InteractionHistroyDataGrid />
            </CustomTabPanel>
        </Box>
    )
}

export default CallSessionSection4
