/**
 * @file DateTypeFilter.tsx
 * @description This file contains the logic to filter the opportunities based on the date type.
 */
import React from 'react'
import { DateTime } from 'luxon'
import { DateCalendar } from '@mui/x-date-pickers'
import { SxProps, Stack, IconButton, Divider, Tooltip } from '@mui/material'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import { TFilterValues, useOpportunities } from 'context/OpportunitiesProvider'

type TDateTypeFilterProps = {
    sx?: SxProps
    columnField: string
}

export const DateTypeFilter: React.FC<TDateTypeFilterProps> = ({
    columnField,
}) => {
    const {
        filterOptions,
        handleFilterStateChangeCallback,
        filterState,
        status,
    } = useOpportunities()

    const handleFilterChange = (value: DateTime) => {
        let dateString = ''
        if (columnField === 'follow_up_date') {
            const isoDate = value.set({ hour: 10 }).toISO({
                suppressMilliseconds: true,
                includeOffset: false,
            })
            console.log(isoDate)
            handleFilterStateChangeCallback(
                columnField as keyof TFilterValues,
                isoDate
            )
            return
        }
        if (status === 'today') {
            dateString = value.toLocaleString()
        } else {
            dateString = value.startOf('day').setZone('utc').toISODate()
        }
        handleFilterStateChangeCallback(
            columnField as keyof TFilterValues,
            dateString
        )
    }

    const handleClearFilter = () => {
        handleFilterStateChangeCallback(columnField as keyof TFilterValues, '')
    }

    return (
        <Stack direction={'column'} gap={1}>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                spacing={1}
            >
                <Tooltip title="Clear Filter">
                    <IconButton color="primary" onClick={handleClearFilter}>
                        <FilterListOffIcon
                            style={{
                                fontSize: '16px',
                            }}
                        ></FilterListOffIcon>
                    </IconButton>
                </Tooltip>
            </Stack>
            <Divider></Divider>
            <DateCalendar onChange={handleFilterChange} />
        </Stack>
    )
}
