import * as env from 'env-var'

export default {
    baseUrl: env.get('REACT_APP_API_BASE_URL').required().asUrlString(),
    baseWsUrl: env.get('REACT_APP_WS_BASE_URL').required().asUrlString(),
    defaultTenantSlug:
        env.get('REACT_APP_API_DEFAULT_TENANT_SLUG').asString() ?? '',

    appEnv: env.get('REACT_APP_ENV').required().asString(),
}
