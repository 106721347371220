/**
 * @file CustomText Component
 * @description This file contains the logic to render a custom text component.
 * @description The custom text component is used to render a text with a custom background color and text color.
 */
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { leadStatus } from './opportunity'

export type TCustomTextProps = {
    backgroundColor: string
    textColor: string
    displayText: string
}

const CustomText: React.FC<TCustomTextProps> = ({
    backgroundColor,
    textColor,
    displayText,
}) => {
    return (
        <Box
            sx={{
                bgcolor: backgroundColor,
                paddingX: '10px',
                paddingY: '4px',
                borderRadius: '4px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                fontWeight={700}
                fontFamily={'Inter'}
                fontSize={9}
                color={textColor}
                variant="button"
                sx={{
                    textTransform: 'uppercase',
                }}
            >
                {displayText.toUpperCase()}
            </Typography>
        </Box>
    )
}

export default CustomText
