/**
 * @file OpportunityTopPanel.tsx
 * @description This file contains the implementation of the OpportunityTopPanel component.
 * @description It provides a top panel for the Opportunity page, displays the user's name, a search component, and action buttons
 */

import { Paper, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import ActionButtons from 'components/opportunity/ActionButtons'
import { useAuthenticatedUser } from 'context/UserProvider'
import React from 'react'
import { LPSearchComponent } from 'components/opportunity/ListPageSearchComponent'

const OpportunityTopPanel: React.FC = () => {
    const { user } = useAuthenticatedUser()

    return (
        <Paper
            sx={{
                borderBottom: '1px solid #E5E5E5',
                boxShadow: 'none',
            }}
        >
            <Grid
                container
                sx={{
                    alignItems: 'center',
                    padding: '15px',
                    rowGap: '15px',
                }}
            >
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            fontWeight={600}
                            fontSize={18}
                            fontFamily={'Inter'}
                        >
                            Welcome, {`${user?.first_name} ${user?.last_name}`}
                        </Typography>
                        <LPSearchComponent></LPSearchComponent>
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                ></Grid>
                <Grid item xl={12} sm={12}>
                    <ActionButtons></ActionButtons>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default OpportunityTopPanel
