/**
 * @file BuddySideBar.tsx
 * @description This file contains the implementation of the CallSessionPage component.
 * @description This component displays the call session page with customer information, search functionality,
 *              and customer recommendation panel.
 */

import { Box, Grid, Link, SxProps, Typography } from '@mui/material'
import {
    CallSessionProvider,
    useCallSession,
} from 'context/CallSessionProvider'
import React, { useCallback, useEffect, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { CustomerAttributePanelV2 } from './CustomerAttributePanelV2'
import { CustomerRecommendationPanel } from './CustomerRecommendationPanel'
import { CustomerSessionPanel } from './CustomerSessionPanel'
import { IAPIParams, ILeadList } from 'buddy-api/types'
import { BuddyAPIClient } from 'api'
import { callSessionPageURL } from 'routes/routes'
import { ListResponseWrapper } from 'buddy-api/ApiMixin'
import { SearchComponentV2 } from 'components/SearchComponentV2'

/**
 * Props for the CallSessionPage component.
 */
interface CallSessionPageProps {
    sx?: SxProps
}

/**
 * Represents the inner component of the CallSessionPage.
 * @param {CallSessionPageProps} props - The props for the CallSessionPageInner component.
 * @returns {React.FC<CallSessionPageProps>} The CallSessionPageInner component.
 */
const CallSessionPageInner: React.FC<CallSessionPageProps> = ({ sx }) => {
    const [searchTerm, setSearchTerm] = React.useState<string>('')
    const [data, setData] = useState<ILeadList[]>([])
    const [reason, setReason] = useState<string | undefined>('')
    const { account, callSessionV2 } = useCallSession()
    const [searchResults, setSearchResults] = useState<
        ListResponseWrapper<ILeadList>
    >({
        count: 0,
        next: null,
        previous: null,
        results: [],
    })

    useEffect(() => {
        const lead = account?.filtered_leads?.find((lead) => {
            return lead.lead_id == callSessionV2.lead
        })
        setReason(lead?.explanation)
    }, [callSessionV2.lead])

    const handleButtonClickCallback = useCallback(
        (result: ILeadList, count: number) => {
            const encoded_id = encodeURIComponent(result.account_id ?? '')
            const url = callSessionPageURL(encoded_id)
            window.open(url)
        },
        []
    )

    const fetchSearchResultsCallback = useCallback(
        async (searchString: string) => {
            try {
                const queryParams: IAPIParams = {
                    search: searchString,
                }
                const _searchResults = await BuddyAPIClient.AccountDetails.list(
                    queryParams
                )
                setSearchResults(_searchResults)
            } catch (e) {
                console.error(e)
            }
        },
        []
    )

    useEffect(() => {
        const searchAccount = async () => {
            if (searchTerm === '' || searchTerm.length < 3) {
                return
            }
            try {
                const queryParams: IAPIParams = {
                    search: searchTerm,
                }
                const response = await BuddyAPIClient.AccountDetails.list(
                    queryParams
                )
                setData(response.results)
            } catch (e) {
                console.error(e)
            }
        }
        searchAccount()
    }, [searchTerm])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0,
                height: '100vh',
                bgcolor: '#F5F5F5',
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: '#F5F5F5',
                    gap: 1.5,
                    pb: 3,
                    pt: 2,
                    px: 2,
                    zIndex: 1050,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        zIndex: 1050,
                    }}
                >
                    <Link
                        href="/agent"
                        underline="none"
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <ArrowBackIosNewIcon
                            sx={{
                                color: '#000021',
                                fontSize: '0.7rem',
                            }}
                        ></ArrowBackIosNewIcon>
                        <Typography
                            fontFamily={'Inter'}
                            fontSize={12}
                            fontWeight={500}
                            color={'#000021'}
                        >
                            Back
                        </Typography>
                    </Link>
                    <SearchComponentV2
                        searchResults={searchResults}
                        handleButtonClickCallback={handleButtonClickCallback}
                        fetchSearchResultsCallback={fetchSearchResultsCallback}
                    ></SearchComponentV2>
                </Box>
                <CustomerAttributePanelV2
                    customerAttributes={account}
                ></CustomerAttributePanelV2>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    overflowY: 'auto',
                    px: 2,
                }}
            >
                <Grid
                    container
                    spacing={1}
                    sx={{ display: 'flex', flexWrap: 'nowrap' }}
                >
                    <Grid item xs={'auto'}>
                        <CustomerRecommendationPanel
                            opportunities={account?.filtered_leads ?? []}
                            contacts={account?.contacts ?? []}
                            reasons={reason ?? ''}
                        ></CustomerRecommendationPanel>
                    </Grid>
                    <Grid item xs={true}>
                        <CustomerSessionPanel></CustomerSessionPanel>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

/**
 * Represents the CallSessionPage component.
 * @returns {React.FC} The CallSessionPage component.
 */
const CallSessionPage: React.FC = () => {
    return (
        <CallSessionProvider>
            <CallSessionPageInner />
        </CallSessionProvider>
    )
}

export default CallSessionPage
