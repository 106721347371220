import ApiBase from './ApiBase'
import ApiRequest from './ApiRequest'
import { IAPIParams, ID } from './types'

export type ListResponseWrapper<T> = {
    count: number
    next: string | null
    previous: string | null
    results: T[]
}

export default class ApiMixin<
    ListResponse,
    CreateResponse = ListResponse,
    RetrieveResponse = ListResponse,
    UpdateResponse = ListResponse,
    DeleteResponse = null
> extends ApiBase {
    public async list(
        params?: IAPIParams,
        signal?: AbortSignal | null
    ): Promise<ListResponseWrapper<ListResponse>> {
        const result = await ApiRequest.get<ListResponseWrapper<ListResponse>>(
            `${this.endpoint}`,
            signal,
            params
        )
        return result.body
    }

    public async create(
        data: CreateResponse,
        signal?: AbortSignal | null
    ): Promise<CreateResponse> {
        const result = await ApiRequest.post<CreateResponse>(
            `${this.endpoint}`,
            signal,
            data
        )
        return result.body
    }

    public async retrieve(
        id: ID,
        signal?: AbortSignal | null
    ): Promise<RetrieveResponse> {
        const result = await ApiRequest.get<RetrieveResponse>(
            `${this.endpoint}/${id}`,
            signal
        )
        return result.body
    }

    public async update(
        id: ID,
        data: UpdateResponse,
        signal?: AbortSignal | null
    ) {
        const result = await ApiRequest.patch<UpdateResponse>(
            `${this.endpoint}/${id}`,
            signal,
            data
        )
        return {
            data: result.body,
        }
    }
    public async delete(id: ID, signal?: AbortSignal | null) {
        const result = await ApiRequest.delete<DeleteResponse>(
            `${this.endpoint}/${id}`,
            signal
        )
        return {
            data: result.body,
        }
    }
}
