/**
 * @file CallSessionUpdateDatePicker.tsx
 * @description This file contains the logic to render the date picker for the call session.
 */
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import 'react-datepicker/dist/react-datepicker.css'
import {
    ClickAwayListener,
    IconButton,
    Popper,
    Tooltip,
    Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import { DateCalendar } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import { AccountStatus, useCallSession } from 'context/CallSessionProvider'

type CallSessionUpdateDatePickerProps = {
    status: string
    contactId: string
    oldFollowUpDate: string
    UpdateFollowUpDate: (
        contactId: string,
        newFollowUpDate: string,
        oldFollowUpDate: string
    ) => void
}

export const CallSessionUpdateDatePicker: React.FC<
    CallSessionUpdateDatePickerProps
> = ({ status, contactId, oldFollowUpDate, UpdateFollowUpDate }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const id = open ? 'update-date-picker-popper' : undefined
    const [newDate, setNewDate] = React.useState<DateTime | null>(null)
    const handleClickAway = () => {
        setAnchorEl(null)
    }

    const { account } = useCallSession()
    return (
        <>
            <Tooltip title="Edit">
                <IconButton
                    disabled={
                        status == AccountStatus.OPPORTUNITY ||
                        status == AccountStatus.REJECTED ||
                        account?.status == AccountStatus.REJECTED ||
                        account?.status == AccountStatus.OPPORTUNITY
                    }
                    size="small"
                    color="primary"
                    aria-label="edit"
                    onClick={(event) =>
                        setAnchorEl(anchorEl ? null : event.currentTarget)
                    }
                >
                    <ModeEditOutlineIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Popper
                sx={{
                    zIndex: 1500,
                    fontFamily: 'Inter !important',
                    padding: 1,
                    paddingTop: 3,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #e3e3e3',
                    borderRadius: '4px',
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                        <Typography
                            fontFamily={'Inter'}
                            fontSize={14}
                            fontWeight={500}
                            textAlign={'center'}
                        >
                            Update Follow Up Date
                        </Typography>
                        <DateCalendar
                            sx={{
                                '.MuiCalendarPicker-root': {
                                    padding: '8px',
                                    paddingBottom: '0px',
                                },
                            }}
                            value={newDate}
                            onChange={(newValue) => setNewDate(newValue)}
                            minDate={DateTime.now()}
                        />
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                        >
                            <Button
                                sx={{
                                    borderRadius: '100px',
                                    '&:hover': {
                                        bgcolor: 'rgba(119,77,252,0.2)',
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#774DFC',
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: 12,
                                }}
                                aria-describedby={id}
                                onClick={() => {
                                    setNewDate(null)
                                    setAnchorEl(null)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{
                                    borderRadius: '100px',
                                    border: `1px solid #774DFC`,
                                    '&:hover': {
                                        border: `1px solid #774DFC`,
                                        bgcolor: 'transparent',
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: `#774DFC`,
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: 12,
                                }}
                                aria-describedby={id}
                                onClick={() => {
                                    UpdateFollowUpDate(
                                        contactId,
                                        newDate?.toISODate() || '',
                                        oldFollowUpDate
                                    )
                                    setAnchorEl(null)
                                }}
                            >
                                Update Follow Up Date
                            </Button>
                        </Stack>
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
