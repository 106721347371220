/**
 * @file CustomMultiSelect.tsx
 * @description This file contains the logic to render the multi select dropdown for the opportunity form.
 */
import {
    FormControl,
    FormLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@mui/material'
import { NetSuiteObject } from 'buddy-api/api/NSDataAPI'
import { INsOppData } from 'buddy-api/types/callstatus'
import { Spec } from 'immutability-helper'
import { useState } from 'react'
import { idToDisplayNameMap } from './OpportunityForm'
import { toTitleCase } from 'utils/stringformatter'

type CustomMultiSelectProps = {
    id: string
    options: NetSuiteObject[]
    updateNsOppForm: (updates: Spec<INsOppData, never>) => void
}

const CustomMultiSelect: React.FC<CustomMultiSelectProps> = ({
    id,
    options,
    updateNsOppForm,
}) => {
    const [selectedValues, setSelectedValues] = useState<string[]>([])

    const handleChange = (event: any) => {
        const value = event.target.value
        setSelectedValues(typeof value === 'string' ? value.split(',') : value)
        const mappedValue = value.map((val: string) => {
            return options.find((option) => option.name === val)?.value
        })
        updateNsOppForm({
            [id]: {
                $set: mappedValue ?? null,
            },
        })
    }

    return (
        <FormControl
            required
            size="small"
            fullWidth
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 2,
                '& .MuiFormLabel-asterisk': {
                    color: 'red',
                },
            }}
        >
            <FormLabel
                htmlFor={id}
                sx={{
                    flex: 1,
                    fontSize: '13px',
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    color: 'black !important',
                }}
            >
                {toTitleCase(idToDisplayNameMap[id])}
            </FormLabel>
            <Select
                multiple
                displayEmpty
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return (
                            <span style={{ color: '#9e9e9e' }}>
                                {toTitleCase(
                                    `Select ${idToDisplayNameMap[id]}`
                                )}
                            </span>
                        )
                    }

                    return selected.join(', ')
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                value={selectedValues}
                onChange={handleChange}
                placeholder={toTitleCase(`Select ${idToDisplayNameMap[id]}`)}
                id={id}
                sx={{
                    width: '300px', // Fixed width
                    flex: 2,
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(119, 77, 252, 1)',
                    },

                    '& .Mui-selected': {
                        background: 'rgba(119, 77, 252, 0.2) !important',
                    },
                }}
            >
                <MenuItem disabled value="" sx={{ padding: 1 }}>
                    <span>
                        {toTitleCase(`Select ${idToDisplayNameMap[id]}`)}
                    </span>
                </MenuItem>
                {options.map((option) => (
                    <MenuItem
                        key={option.name + option.value + id}
                        value={option.name}
                        sx={{ padding: 1 }} // Reduce padding for MenuItem
                    >
                        <Checkbox
                            sx={{
                                color: 'rgba(119, 77, 252, 1)',
                                '&.Mui-checked': {
                                    color: 'rgba(119, 77, 252, 1)',
                                },
                                padding: '4px', // Reduce padding for Checkbox
                            }}
                            checked={selectedValues.indexOf(option.name) > -1}
                        />
                        <ListItemText primary={option.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default CustomMultiSelect
