import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
`

export const DeepLogo = styled.img`
    position: absolute;
    width: 100px;
    top: 0;
    right: 0;
`

export const Header = styled.header`
    padding: 1.8rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`

export const OptimixerLogo = styled.img`
    width: 11rem;
    height: auto;
`

export const Main = styled.main`
    padding: 1.2rem 2.1rem;
`

export const Logo = styled.img`
    width: 6rem;
    height: auto;
    padding: 0.5rem;
    cursor: pointer;
`

export const OrWrapper = styled.div`
    text-align: center;
    margin: 1rem -1rem;
    position: relative;
    display: flex;

    &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: ${({ theme }) => theme.palette.grey[300]};
        height: 1px;
        width: 100%;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
`

export const Or = styled.div`
    padding: 0 4rem;
    margin: auto auto;
    background-color: ${({ theme }) => theme.palette.white};
    position: relative;
    display: inline-block;
    font: normal 14px/14px 'Mona Sans', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
    &:after {
        content: 'or sign in via';
    }
`

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
